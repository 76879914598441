import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Switch, Route, BrowserRouter } from 'react-router-dom'
import { useHistory, useLocation } from "react-router-dom";
import uniqueString from 'unique-string'

// pages
import Dashboard from './compoents/pages/Dashboard';

// resume-pages
import Experience from './compoents/pages/resume-pages/Experience'
import Education from './compoents/pages/resume-pages/Education';
import Header from './compoents/pages/resume-pages/Header';
import Skills from './compoents/pages/resume-pages/Skills';
import Summary from './compoents/pages/resume-pages/Summary';
import Finalize from './compoents/pages/resume-pages/Finalize';
// cover lettter pages
import ExperienceCover from './compoents/pages/cover-letter-pages/Experience';
import EducationCover from './compoents/pages/cover-letter-pages/Education';
import SkillsCover from './compoents/pages/cover-letter-pages/Skills';
import personalCover from './compoents/pages/cover-letter-pages/General';
import FinalizeCover from './compoents/pages/cover-letter-pages/Finalize';

/* Providers*/
import { ResumeProvider } from './contexts/ResumeContext';
import { StepProvider } from './contexts/StepContext';
import { TemplateProvider } from './contexts/TemplateContext';
import { SessionProvider } from './contexts/SessionContext';
import { MessageContextProvider } from './contexts/MessageContext'
import { CoverletterContext, CoverletterProvider } from './contexts/CoverLetterContext';
import { SpinLoderContextProvider } from './contexts/SpinLoderContext';

import { SessionContext } from './contexts/SessionContext';
import { TemplateContext } from './contexts/TemplateContext';
import { CoverletterBodyProvider } from './contexts/CoverLetterBodyContext';


function useQuery() {
  return new URLSearchParams(useLocation().search);
}


const ResumeLayout = () => {
  return (
    <>
      <StepProvider>
        <ResumeProvider>
          <Route path="/resume/header" component={Header}></Route>
          <Route path="/resume/experience" component={Experience}></Route>
          <Route path="/resume/education" component={Education}></Route>
          <Route path="/resume/skills" component={Skills}></Route>
          <Route path="/resume/summary" component={Summary}></Route>
          <Route path="/resume/finalize" component={Finalize}></Route>
        </ResumeProvider>
      </StepProvider>
    </>

  )
}



const CoverLetterLayout = () => {

  const { coverletter } = useContext(CoverletterContext);


  const history = useHistory();

  useEffect(() => {

    if (coverletter.step === 'experience') {
      history.push(`/cover-letter/experience`);
    } else if (coverletter.step === 'education') {
      history.push(`/cover-letter/education`);
    } else if (coverletter.step === 'skills') {
      history.push(`/cover-letter/skills`);
    } else if (coverletter.step === 'personal') {
      history.push(`/cover-letter/personal`);
    } else if (coverletter.step === 'finalize') {
      history.push(`/cover-letter/finalize`);
    } else {
      history.push(`/cover-letter/experience`);
    }
  }, []);

  return (
    <>

      <SpinLoderContextProvider>
        {coverletter.step === "experience" &&
          <Route path="/cover-letter/experience" component={ExperienceCover}></Route>
        }
        {coverletter.step === "education" &&
          <Route path="/cover-letter/education" component={EducationCover}></Route>
        }
        {coverletter.step === "skills" &&
          <Route path="/cover-letter/skills" component={SkillsCover}></Route>
        }
        {coverletter.step === "personal" &&
          <Route path="/cover-letter/personal" component={personalCover}></Route>
        }
        {coverletter.step === "finalize" &&
          <Route path="/cover-letter/finalize" component={FinalizeCover}></Route>
        }

      </SpinLoderContextProvider>

    </>
  )
}

const Layouts = () => {

  const { session, setSessionStatus } = useContext(SessionContext);
  const { template, setTemplateStatus } = useContext(TemplateContext);

  let query = useQuery();


  useEffect(() => {
    // url param res_temp cover_temp

    const res_temp = ['diamond', 'topaz', 'ruby', 'sapphire', 'opel'].includes(query.get("res_temp"));
    const cover_temp = ['diamond', 'topaz', 'ruby', 'sapphire', 'opel'].includes(query.get("cover_temp"));

    let resTemp = { "resumeTemplateName": "diamond" };
    if (res_temp) {
      resTemp = { "resumeTemplateName": query.get("res_temp") };
    }

    let coverLetterTem = { "coverLetterTemplateName": "diamond" };
    if (cover_temp) {
      coverLetterTem = { "coverLetterTemplateName": query.get("cover_temp") };
    }

    setTemplateStatus({ ...template, ...resTemp, ...coverLetterTem });

    if (session && session.id === "") {
      setSessionStatus({ 'id': uniqueString() })
    }

  }, []);

  return (
    <>
      <Switch>
        <Route path="/resume" component={ResumeLayout}></Route>
        <CoverletterProvider>
          <CoverletterBodyProvider>
            <Route path="/cover-letter" component={CoverLetterLayout}></Route>
          </CoverletterBodyProvider>
        </CoverletterProvider>
        <Route path="/" component={Dashboard} exact></Route>
      </Switch>
    </>
  )
}


const App = () => {

  return (
    <>
      <MessageContextProvider>
        <SessionProvider>
          <TemplateProvider>
            <BrowserRouter>
              <Layouts />
            </BrowserRouter>
          </TemplateProvider>
        </SessionProvider>
      </MessageContextProvider>
    </>
  );
}

export default App;
