import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Row, Col, Typography, Button, Form, Input, Image, Spin } from 'antd';
import { SpinLoderContext } from '../../../../contexts/SpinLoderContext';
import { CoverletterContext } from '../../../../contexts/CoverLetterContext';
import CoverletterBtn from '../../../actions/CoverletterBtn';

const { Title, Text } = Typography;

const Job = () => {

    const history = useHistory();

    const next = () => {
        history.push(`/cover-letter/education`);
    }


    const { spinloading, setSpinloading } = useContext(SpinLoderContext);
    const [form] = Form.useForm();
    const { coverletter, setCoverletterStatus } = useContext(CoverletterContext);
    const [job, setJob] = useState({ jobTitle: "", companyName: "" });


    useEffect(() => {

        setSpinloading(true);
        if (coverletter.job) {
            form.setFieldsValue(coverletter.job);
            setJob(coverletter.job);
        } else {
            form.setFieldsValue(job);
        }

        const timer = setTimeout(() => {
            setSpinloading(false);
        }, 300);

        return () => clearTimeout(timer);
    }, []);


    useEffect(() => {

        setSpinloading(true);
        setCoverletterStatus({ ...coverletter, job });
        const timer = setTimeout(() => {
            setSpinloading(false);
        }, 200);
        return () => clearTimeout(timer);

    }, [job]);

    const onValuesChange = (val, all) => {
        setJob(all)
        onFinish();
    }

    const onFinish = values => {
        console.log('Received values of form:', values);
    };

    return (
        <div>
            <Row>
                <Col xl={22} lg={24} md={24} sm={24} xs={24} offset={1} align="middle">
                    <div className="spin-loader" align="center">
                        {spinloading && <Spin loading={true} size="small"  />}
                    </div>
                </Col>
            </Row>
            <Row className="ant-pr-1 ant-pl-1">
                <Col xl={24} lg={24} md={24} sm={24} xs={24} align="middle">
                    <div className="form-mt"></div>
                    <Title level={2} className="ant-mb-0">What is your job?</Title>
                    <Text className="text-muted">(includes volunteer work, summer jobs, and unofficial jobs)</Text>
                </Col>
                <Col xl={18} lg={24} md={24} sm={24} xs={24} align="middle" offset={3}>
                    <div className="ant-mt-10 cover-step-wrapper">
                        <Form
                            onFinish={onFinish}
                            layout={"vertical"}
                            wrapperCol={{
                                span: 24
                            }}
                            size={"middle"}
                            form={form}
                            name="job"
                            onValuesChange={onValuesChange}

                        >
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                                <Col xl={16} lg={12} md={24} sm={24} xs={24} offset={4}>
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >

                                        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gutter-row ant-mr-auto ant-ml-auto" align="middle">
                                            <Form.Item
                                                label="Job Title"
                                                name="jobTitle"
                                                rules={[
                                                    {
                                                        type: "string",
                                                        required: true,
                                                        message: 'Please input your job title'
                                                    },
                                                    { max: 100, message: 'Job title must be maximum 100 characters.' },
                                                ]}
                                            >
                                                <Input
                                                    placeholder={"Web Developer"}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gutter-row ant-mr-auto ant-ml-auto" align="middle">
                                            <Form.Item
                                                label="Name of the company"
                                                name="lastCompany"
                                                rules={[
                                                    {
                                                        type: "string",
                                                        required: true,
                                                        message: 'Please input your company',
                                                    },
                                                    { max: 100, message: 'Company name must be maximum 100 characters.' },
                                                ]}
                                            >
                                                <Input
                                                    placeholder={"ABC Company"}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gutter-row ant-mr-auto ant-ml-auto" align="middle">
                                    <div className="cover-buttons-center">
                                        <CoverletterBtn action={"jobContinue"} text={"Continue"} />
                                    </div>
                                </Col>

                            </Row>
                        </Form>
                    </div>
                </Col>

            </Row>
        </div>
    )
}

export default Job;