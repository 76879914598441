import React from 'react';
import MainNav from '../../navigation/MainNav';
import { Row, Col } from 'antd';
import CoverPreview from './cover-preview/CoverPreview'
import Finalize from '../../cover-letter/cover-letter-form/finalize/Finalize';
import DownloadCoverLetterBtn from '../../actions/DownloadCoverLetterBtn';
import AppMessages from '../../actions/AppMessages';
import TemplateColorChange from '../../actions/TemplateColorChange';
import TemplateChange from '../../actions/TemplateChange';


const FinalizeCover = () => {

    return (
        <div>
            <MainNav />
            <AppMessages />
            
            <Row className="ant-x-5">
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Row className="ant-mb-5">
                        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="ant-mt-0 ">
                            <Finalize />
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="">
                        <Row>
                        <Col xl={12} lg={12} md={12} sm={24} xm={24} className="ant-ml-0 ant-mr-0">
                            <TemplateChange />
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={24} xm={24} className="ant-ml-0 ant-mr-0">
                            <TemplateColorChange />
                        </Col>
                    </Row>
                            <CoverPreview />
                            <DownloadCoverLetterBtn />
                        </Col>
                    </Row>
                </Col>
            </Row>
 

        </div>
    )
}

export default FinalizeCover;