import React from 'react'
import { useHistory } from "react-router-dom";
import MainNav from '../../navigation/MainNav';
import { Row, Col, Button } from 'antd';
// import ResumeFormProgress from '../../navigation/resume/ResumeFormProgress';
import ResumeProgress from '../../navigation/resume/ResumeProgress';
import ResumeProgressMobile from '../../navigation/resume/ResumeProgressMobile';
import ResumePreview from './resume-preview/ResumePreview';
import EducationForm from '../../resume/forms/EducationForm';
import { EducationTopic } from '../../resume/forms/form-headers/FormHeaders';
import {  CaretLeftOutlined, CaretRightFilled } from '@ant-design/icons';
import DownloadBtn from '../../actions/DownloadBtn';
import AppMessages from '../../actions/AppMessages';

const Education = () => {

    const history = useHistory();

    const Next = () => {
        history.push(`/resume/skills`);
    }

    const Previuos = () => {
        history.push(`/resume/experience`);
    }

    return (
        <div>
            <MainNav />
            <AppMessages />
            <Row className="resume-page">
                <Col xl={13} lg={13} md={24} sm={24} xm={24} className="form-section" align="center">
                    <ResumeProgress />
                    <ResumeProgressMobile/>
                    <EducationTopic />

                    <div className="forms">
                        <EducationForm />
                    </div>

                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col xl={24} lg={24} md={24} sm={24} xm={24} className="gutter-row">
                            <div className="footer">
                                <div className="footer-area">
                                    <Button className="resume-btn-nav  btn-distant" type="primary" size="large" onClick={Previuos}> <CaretLeftOutlined />Experience &nbsp;</Button>
                                    <Button className="resume-btn-nav" size="large" type="primary" onClick={Next}>&nbsp; Skills<CaretRightFilled /></Button>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Col>
                <Col xl={11} lg={11} md={24} sm={24} xm={24} className="preview-section">
                    {/* <ResumeFormProgress /> */}
                    <div >
                        <ResumePreview />
                    </div>
                    <DownloadBtn/>
                </Col>
            </Row>
        </div>
    )
}
export default Education;