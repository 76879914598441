import * as React from "react";

const ResumeDataHandler = () => {
    const [resume, setResume] = React.useState(
        localStorage.getItem('resume') ? JSON.parse(localStorage.getItem('resume')) :
            { personal: {}, summary: "", experiences: [], educations: [], hardskills: [], softskills :[], languages: [], certificates: [], references: [], others: [] }
    );

    const setResumeStatus = (resume) => {
        window.localStorage.setItem("resume", JSON.stringify(resume));
        setResume(resume);
    };

    const setUnsetResumeStatus = () => {
        window.localStorage.clear();
        setResume({ personal: {}, summary: "", experiences: [], educations: [], hardskills: [], softskills :[], languages: [], certificates: [], references: [], others: [] });
    };

    return {
        resume,
        setResumeStatus,
        setUnsetResumeStatus
    };
};

export default ResumeDataHandler;