import React, { useState, useContext } from 'react';
import { Button, message } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { saveAs } from 'file-saver';

import { ResumeContext } from '../../contexts/ResumeContext';
import { SessionContext } from '../../contexts/SessionContext';
import { MessageContext } from '../../contexts/MessageContext';
import { TemplateContext } from '../../contexts/TemplateContext';



/* API */

import { syncResumeData, getPDF } from '../../api';


const DownloadBtn = () => {


    const { resume } = useContext(ResumeContext);
    const { session } = useContext(SessionContext);
    const { template } = useContext(TemplateContext);
    const { setMessage } = useContext(MessageContext);

    const [loadings, setLoadings] = useState(false);

    const downloadResume = async () => {
        setLoadings(true);

        const resobj = { ...resume, session, template };

        try {
            const resResp = await syncResumeData(resobj);

            setMessage({ data: { message: "Processing...." }, status: 10001 });
            try {
                const pdfBlob = await getPDF(session);
                saveAs(pdfBlob, 'resume.pdf');
                setMessage({ data: { message: "Resume Downloaded" }, status: 10002 });
                setLoadings(false)
            } catch (error) {
                setMessage({ data: { errorMessage: "Download unavailable. Please try again later." }, status: 422 });
                setLoadings(false)
            }

        } catch (error) {
            setMessage(error.message);
            setLoadings(false)

        }


    };



    return (
        <>
            <Button loading={loadings} type="primary" icon={<DownloadOutlined />} onClick={downloadResume} size={"large"} className="resume-download-btn">
                Resume Download
            </Button>
        </>
    )
}

export default DownloadBtn;