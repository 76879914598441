import * as React from "react";

import { getStoredCoverletterBodyData } from '../utils/CoverletterBodyHelper';

const CoverletterBodyDataHandler = () => {
    const [coverletterBody, setSession] = React.useState(
        localStorage.getItem('coverletterBody') ? JSON.parse(localStorage.getItem('coverletterBody')) :
        getStoredCoverletterBodyData()
    );

    const setCoverletterBodyStatus = (coverletterBody) => {
        window.localStorage.setItem("coverletterBody", JSON.stringify(coverletterBody));
        setSession(coverletterBody);
    };

    const setUnsetCoverletterBodyStatus = () => {
        window.localStorage.clear();
        setSession({});
    };

    return {
        coverletterBody,
        setCoverletterBodyStatus,
        setUnsetCoverletterBodyStatus
    };
};

export default CoverletterBodyDataHandler;