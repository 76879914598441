import React, { useState, useContext, useEffect } from 'react'
import { Row, Col, Typography, Form, Input, Button, Spin } from 'antd';
import { CoverletterContext } from '../../../../contexts/CoverLetterContext';
import { SpinLoderContext } from '../../../../contexts/SpinLoderContext';
import CoverletterBtn from '../../../actions/CoverletterBtn';

const { Title } = Typography;



const Personal = () => {

    const [form] = Form.useForm();
    const { spinloading, setSpinloading } = useContext(SpinLoderContext);
    const { coverletter, setCoverletterStatus } = useContext(CoverletterContext);
    const [personalInformation, setPersonalInformation] = useState({ firstName: '', lastName: '', profession: '', address: '', phone: '', email: '' });


    useEffect(() => {

        if (coverletter.higherEducation) {
            setPersonalInformation(coverletter.personalInformation);
            form.setFieldsValue(coverletter.personalInformation);
        } else {
            form.setFieldsValue(personalInformation);
        }
        const timer = setTimeout(() => {
            setSpinloading(false);
        }, 300);
        return () => clearTimeout(timer);
    }, []);


    useEffect(() => {

        setSpinloading(true)
        form.setFieldsValue(personalInformation)
        setCoverletterStatus({ ...coverletter, personalInformation });
        const timer = setTimeout(() => {
            setSpinloading(false)
        }, 200);

        return () => clearTimeout(timer);
    }, [personalInformation]);

    const onValuesChange = (val, all) => {
        setPersonalInformation(all);
        onFinish();
    }

    const onFinish = values => {

    };

    return (
        <div className="ant-mb-6">
            <Row>
                <Col xl={{ span: 22, offset: 1 }} lg={24} md={24} sm={24} xs={24} align="middle">
                    <div className="ant-mt-5"></div>
                    <div className="spin-loader" align="center">
                        {spinloading && <Spin loading={true} size="small"  />}
                    </div>
                </Col>
            </Row>
            <Row>

                <Col xl={14} lg={24} md={24} sm={24} xs={24} className="" align="middle" offset={5}>

                    <Title level={2} className="ant-mb-0">Your Personal Details</Title>
                </Col>
                <Col xl={18} lg={24} md={24} sm={24} xs={24} align="middle" offset={3}>
                    <div className="ant-mt-10 finalize-cover-page">
                        <Form
                            onFinish={onFinish}
                            layout={"vertical"}
                            wrapperCol={{
                                span: 24
                            }}
                            size={"middle"}
                            form={form}
                            name="personal"
                            onValuesChange={onValuesChange}
                        >
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                                <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gutter-row ant-mr-auto ant-ml-auto" align="middle">
                                    <Form.Item
                                        label="First Name"
                                        name="firstName"
                                        rules={[
                                            {
                                                type: "string",
                                                required: true,
                                                message: 'Please input your first name',
                                            },{ max: 100, message: 'First name must be maximum 100 characters.' },
                                        ]}
                                    >
                                        <Input
                                            placeholder={"Jane"}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gutter-row ant-mr-auto ant-ml-auto" align="middle">
                                    <Form.Item
                                        label="Last Name"
                                        name="lastName"
                                        rules={[
                                            {
                                                type: "string",
                                                required: true,
                                                message: 'Please input your last name',
                                            },{ max: 100, message: 'Last name must be maximum 100 characters.' },
                                        ]}
                                    >
                                        <Input
                                            placeholder={"Doe"}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gutter-row ant-mr-auto ant-ml-auto" align="middle">
                                    <Form.Item
                                        label="Phone"
                                        name="phone"
                                        rules={[
                                            {
                                                type: "string",
                                                message: 'Please input your phone number',
                                            },{ max: 15, message: 'Phone must be maximum 15 characters.' },
                                        ]}
                                    >
                                        <Input
                                            placeholder={"xx xxxx xxxx"}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gutter-row ant-mr-auto ant-ml-auto" align="middle">
                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        rules={[
                                            {
                                                type: "email",
                                                required: "true",
                                                message: 'Please input your email',
                                            },{ max: 100, message: 'Email must be maximum 100 characters.' },
                                        ]}
                                    >
                                        <Input
                                            placeholder={"example@sample.com"}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gutter-row ant-mr-auto ant-ml-auto" align="middle">
                                    <Form.Item
                                        label="Profession"
                                        name="profession"
                                        rules={[
                                            {
                                                type: "string",
                                                required: true,
                                                message: 'Please input your profession',
                                            },{ max: 100, message: 'Profession must be maximum 100 characters.' },
                                        ]}
                                    >
                                        <Input
                                            placeholder={"Web Developer"}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gutter-row ant-mr-auto ant-ml-auto" align="middle">
                                    <Form.Item
                                        label="Address"
                                        name="address"
                                        rules={[
                                            {
                                                type: "string",
                                                required: true,
                                                message: 'Please input your address',
                                            },
                                            { max: 100, message: 'Address must be maximum 100 characters.' },
                                        ]}
                                    >
                                        <Input
                                            placeholder={"No.XX,Main Street,Looks Junction,NY"}
                                        />
                                    </Form.Item>
                                </Col>


                                <Col xl={{ span: 16, offset: 4 }} lg={{ span: 16, offset: 4 }} md={{ span: 16, offset: 4 }} sm={24} xs={24} className="gutter-row" align="middle">
                                    <div className="cover-buttons-center">
                                        <CoverletterBtn action={"setPersonal"} text={"Continue"}></CoverletterBtn>
                                    </div>
                                </Col>
                            </Row>
                        </Form>


                    </div>
                </Col>

            </Row>
        </div >
    )
}

export default Personal;