import React, { useContext, useState, useEffect, useCallback } from 'react'
import { Form, Input, Row, Col, Divider, Skeleton, Spin } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import debounce from 'lodash.debounce';

import { ResumeContext } from '../../../contexts/ResumeContext';


const SummaryForm = () => {

    const { resume, setResumeStatus } = useContext(ResumeContext);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [loadings, setLoadings] = useState(false);

    const [summary, setSummary] = useState({
        summary: ''
    });


    useEffect(() => {
        if (resume.summary !== "") {
            setSummary({ summary: resume.summary });
            form.setFieldsValue(resume.summary);
        } else {
            form.setFieldsValue(summary);
        }
        const timer = setTimeout(() => {
            setLoading(false);
        }, 300);
        return () => clearTimeout(timer);
    }, []);


    useEffect(() => {

        setLoadings(true)
        form.setFieldsValue(summary)
        setResumeStatus({ ...resume, ...summary });
        const timer = setTimeout(() => {
            setLoadings(false)
        }, 100);

    }, [summary]);

    const debounceSave = useCallback(
        debounce((nextvalue) => setSummary(nextvalue), 300)
        , []
    );


    const onValuesChange = (val, all) => {
        debounceSave(all);
        onFinish();
    }


    const onFinish = values => {
        console.log('Received values of form:', values);
    };


    return (
        <>
            <Skeleton active={true} loading={loading}>

                <Form
                    onFinish={onFinish}
                    layout={"vertical"}
                    wrapperCol={{
                        span: 24
                    }}
                    size={"middle"}
                    form={form}
                    name="header"
                    onValuesChange={onValuesChange}
                   
                >
                    <div className="spin-loader" align="center">
                        {loadings && <Spin size="small" />}
                    </div>

                    <Row className="ant-mt-2">
                        <Col xl={24} lg={24} md={24} sm={24} xs={24} align="left">
                            <Form.Item
                                name="summary"
                                rules={[{type:"string"},
                                { max: 5000, message: 'Summary must be maximum 5000 characters.' }]}
                            >

                                <ReactQuill
                                    theme="snow"
                                    
                                    modules={{
                                        toolbar: [
                                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                            [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                            { 'indent': '-1' }, { 'indent': '+1' }],
                                            ['link'],
                                            ['clean']
                                        ]
                                    }}
                                    placeholder={"Description"}
                                >

                                </ReactQuill>

                            </Form.Item>
                        </Col>
                    </Row>
                </Form>


            </Skeleton>
        </>
    )
}

export default SummaryForm;