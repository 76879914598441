import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Row, Col, Form, Input, Button, Skeleton, Tabs, Rate, AutoComplete, Spin, Select } from 'antd';
import { DeleteTwoTone, DragOutlined, PlusOutlined, StarTwoTone } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import shortid from 'shortid';
import debounce from 'lodash.debounce';

import { ResumeContext } from '../../../contexts/ResumeContext';


const LanguageForm = () => {

    const [loading, setLoading] = useState(true);
    const [loadings, setLoadings] = useState(false);
    const { resume, setResumeStatus } = useContext(ResumeContext);
    const [form] = Form.useForm();

    const [languages, setLanguages] = useState({
        languages: [
            { language: "", proficiencyLevel: "", objid: shortid.generate() }
        ]
    });

    const debounceSave = useCallback(
        debounce((nextvalue) => setLanguages(nextvalue), 300)
        , []
    );


    const onFinish = values => {

    };

    const onValuesChange = (val, all) => {

        debounceSave(all);

    };

    useEffect(() => {

        if (resume.languages && resume.languages.length > 0) {
            setLanguages({ languages: resume.languages });
            form.setFieldsValue(languages);
        } else {
            form.setFieldsValue(languages);
        }
        const timer = setTimeout(() => {
            setLoading(false);
        }, 300);
        return () => clearTimeout(timer);

    }, []);

    useEffect(() => {
        setLoadings(true)
        const items = languages.languages.filter(item => {
            if (item.language !== undefined)
                return item;
        })
        languages['languages'] = items;

        form.setFieldsValue(languages)
        setResumeStatus({ ...resume, ...languages });


        const timer = setTimeout(() => {
            setLoadings(false)
        }, 100);

    }, [languages]);



    const onDragEnd = (result) => {

        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const items = reorder(
            languages.languages,
            result.source.index,
            result.destination.index
        );

        languages['languages'] = items;
        setLanguages({ ...languages })

    }

    const reorder = (list, startIndex, endIndex) => {

        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    return (
        <div className="small-forms">
            <Form
                onFinish={onFinish}
                onValuesChange={onValuesChange}
                form={form}
                layout={"vertical"}
                wrapperCol={{
                    span: 24
                }}
                size={"middle"}
            >

                <div className="spin-loader" align="center">
                    {loadings && <Spin size="small" />}
                </div>

                <Form.List name="languages">
                    {(fields, { add, remove }) => (
                        <>

                            <DragDropContext onDragEnd={onDragEnd}>

                                <Droppable droppableId="item" >
                                    {
                                        (provided) => (
                                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                                {fields.map((field, index, arr) => (

                                                    <>
                                                        <Draggable key={languages.languages[index] ? languages.languages[index].objid : 'temp'} draggableId={languages.languages[index] ? languages.languages[index].objid : 'temp'} index={index}>

                                                            {provided => (
                                                                <div ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}>


                                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="skill-section">
                                                                        <Col xl={10} lg={10} md={10} sm={24} xs={24} className="gutter-row ant-pl-5" >
                                                                            <Form.Item
                                                                                {...field}

                                                                                name={[field.name, 'language']}
                                                                                fieldKey={[field.fieldKey, 'language']}
                                                                                rules={[
                                                                                    {
                                                                                        type: "string",
                                                                                        message: 'Please input Language',
                                                                                    },
                                                                                    { max: 100, message: 'Language must be maximum 100 characters.' }
                                                                                ]}
                                                                            >
                                                                                <AutoComplete placeholder="English">
                                                                                    <Input />
                                                                                </AutoComplete>
                                                                            </Form.Item>

                                                                        </Col>
                                                                        <Col xl={10} lg={10} md={10} sm={24} xs={24} className="gutter-row">

                                                                            <Form.Item
                                                                                {...field}
                                                                                name={[field.name, 'proficiencyLevel']}
                                                                                fieldKey={[field.fieldKey, 'proficiencyLevel']}
                                                                                rules={[
                                                                                    {
                                                                                        type: "string",
                                                                                        message: 'Please select level',
                                                                                    },
                                                                                    { max: 100, message: 'Level must be maximum 100 characters.' }

                                                                                ]}
                                                                            >
                                                                                <Select>
                                                                                    <Select.Option value="Native">Native</Select.Option>
                                                                                    <Select.Option value="Full Professional Proficiency">Full Professional Proficiency</Select.Option>
                                                                                    <Select.Option value="Full Working Proficiency">Full Working Proficiency</Select.Option>
                                                                                    <Select.Option value="Limited Working Proficiency">Limited Working Proficiency</Select.Option>
                                                                                    <Select.Option value="Elementary Proficiency">Elementary Proficiency</Select.Option>
                                                                                    <Select.Option value="No Proficiency">No Proficiency</Select.Option>
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </Col>

                                                                        <Col xl={2} lg={2} md={2} sm={12} xs={12} className="gutter-row">
                                                                            {loadings === false && <DeleteTwoTone
                                                                                className="remove-btn"
                                                                                twoToneColor="#f5222d"
                                                                                onClick={() => remove(field.name)}
                                                                            />}
                                                                        </Col>
                                                                        <Col xl={2} lg={2} md={2} sm={12} xs={12} className="gutter-row">
                                                                            <DragOutlined className="drag-btn" />
                                                                        </Col>
                                                                    </Row>

                                                                </div>

                                                            )}

                                                        </Draggable>
                                                    </>


                                                ))}
                                                <div className="spin-loader" align="center">
                                                    {loadings && <Spin size="small" />}
                                                </div>
                                                {provided.placeholder}
                                            </div>
                                        )
                                    }
                                </Droppable>
                                <Form.Item className=" add-btn add-btn-align-skill">
                                    <Button type="primary" loading={loadings} onClick={() => add({ language: "", proficiencyLevel: "", objid: shortid.generate() })} icon={<PlusOutlined />}>
                                        Add Language
                                                </Button>
                                </Form.Item>
                            </DragDropContext>


                        </>
                    )}
                </Form.List>

            </Form>
        </div>
    )

}

export default LanguageForm;