import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Row, Col, Form, Input, Button, Checkbox, Space, AutoComplete, DatePicker, Skeleton, Collapse, Switch, Spin } from 'antd';
import { DeleteTwoTone, DragOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import shortid from 'shortid';
import moment from 'moment';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import debounce from 'lodash.debounce';

import { ResumeContext } from '../../../contexts/ResumeContext';


const { Panel } = Collapse;

const ReferencesForm = () => {

    const [value, setValue] = useState('');
    const { resume, setResumeStatus } = useContext(ResumeContext);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [loadings, setLoadings] = useState(false);
    const [expand, setExpand] = useState([0, 1]);


    //experiences
    const [references, setReferences] = useState({
        references: [
            { fullName: '', occupation: '', company: "", email: "", phone: "", objid: shortid.generate() }
        ]
    });

    const debounceSave = useCallback(
        debounce((nextvalue) => setReferences(nextvalue), 300)
        , []
    );


    const debounceSaveDescription = useCallback(
        debounce((index, nextvalue) => setContext(index, nextvalue), 300)
        , []
    );

    const setContext = (index, nextvalue) => {
        if (references.references[index] && references.references[index].summary !== undefined) {
            references.references[index].references = nextvalue;
            setResumeStatus({ ...resume, ...references });
        }

    }


    useEffect(() => {
        if (resume.references && resume.references.length > 0) {
            let i = 0
            i = resume.references.length;
            if (i > 3) {
                setExpand([0]);
            }
            setReferences({ references: resume.references });
            form.setFieldsValue(references);

        } else {
            form.setFieldsValue(references);
        }


        const timer = setTimeout(() => {
            setLoading(false);
        }, 300);

        setLoadings(false)

        return () => clearTimeout(timer);

    }, []);


    useEffect(() => {
        setLoadings(true)
        const items = references.references.filter(item => {
            if (item.fullName !== undefined)
                return item;
        })
        references['references'] = items;

        form.setFieldsValue(references)
        setResumeStatus({ ...resume, ...references });


        const timer = setTimeout(() => {
            setLoadings(false)
        }, 100);

    }, [references]);



    const onValuesChange = (val, all) => {

        setLoadings(true)
        onFinish();

        console.log(references.references.length, all.references.length)
        if (references.references.length != all.references.length) {

            setReferences(all);
            if (!expand.includes(all.references.length)) {
                expand.push(all.references.length)
            }
            if (!expand.includes(references.references.length)) {
                expand.push(references.references.length)
            }

        } else {
            debounceSave(all);
        }



    }

    const onDragEnd = (result) => {

        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const items = reorder(
            references.references,
            result.source.index,
            result.destination.index
        );

        references['references'] = items;
        setReferences({ ...references })

    }

    const reorder = (list, startIndex, endIndex) => {

        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };



    const editorOnChnage = (val, index) => {

        if (references.references[index].summary !== undefined) {
            debounceSaveDescription(index, val);
        }


    }

    const onFinish = values => {
        console.log('Received values of form:', values);
    };


    const genExtra = () => (
        <div>
            <DragOutlined
                className="drag-btn"
                onClick={event => {
                    // If you don't want click extra trigger collapse, you can prevent this:
                    event.stopPropagation();
                }}
            />

        </div>
    );

    return (
        <>

            <Skeleton active={true} loading={loading}>
                <Form
                    onFinish={onFinish}
                    layout={"vertical"}
                    wrapperCol={{
                        span: 24
                    }}
                    size={"middle"}
                    form={form}
                    name="header"
                    onValuesChange={onValuesChange}
                >

                    <div className="spin-loader" align="center">
                        {loadings && <Spin size="small" />}
                    </div>

                    <Row className="ant-mt-2" >
                        <Col xl={24} lg={24} md={24} sm={24} xs={24} align="left" >

                            <Form.List name="references">
                                {(fields, { add, remove }) => (
                                    <>

                                        <DragDropContext onDragEnd={onDragEnd}>

                                            <Droppable droppableId="item" >
                                                {
                                                    (provided) => (
                                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                                            {fields.map((field, index, arr) => (

                                                                <>
                                                                    <Draggable key={references.references[index] ? references.references[index].objid : 'temp'} draggableId={references.references[index] ? references.references[index].objid : 'temp'} index={index}>

                                                                        {provided => (
                                                                            <div ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}>


                                                                                <Collapse defaultActiveKey={expand}
                                                                                    className="form-panel"
                                                                                    expandIconPosition={"right"}
                                                                                >
                                                                                    <Panel

                                                                                        header={(references.references[index].fullName ? references.references[index].fullName : 'Reference Details')} key={index} extra={
                                                                                            <div className="section-top-btn">


                                                                                                <DragOutlined
                                                                                                    className="drag-btn ant-ml-2 ant-mr-1"

                                                                                                    onClick={event => {
                                                                                                        event.stopPropagation();
                                                                                                    }}
                                                                                                />
                                                                                                <div>
                                                                                                    {loadings === false && <DeleteTwoTone
                                                                                                        className="remove-btn ant-mr-1"
                                                                                                        twoToneColor="#f5222d"

                                                                                                        onClick={() => remove(field.name)} />}
                                                                                                </div>
                                                                                                <div className="ant-mr-5 spin-loader"> {loadings && <Spin size="small" />}</div>
                                                                                            </div>


                                                                                        }


                                                                                        className="ant-mb-5 ">
                                                                                        <div >
                                                                                            {
                                                                                                <div key={field.key} >

                                                                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>

                                                                                                        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gutter-row">
                                                                                                            <Form.Item
                                                                                                                {...field}
                                                                                                                label="Full Name"
                                                                                                                name={[field.name, 'fullName']}
                                                                                                                fieldKey={[field.fieldKey, 'fullName']}
                                                                                                                rules={[{ type: "string",  message: 'Full Name' },
                                                                                                                { max: 100, message: 'Full Name  must be maximum 100 characters.' }]}
                                                                                                            >
                                                                                                                <Input placeholder="Jane Smith" />
                                                                                                            </Form.Item>
                                                                                                        </Col>



                                                                                                    </Row>

                                                                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>


                                                                                                        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gutter-row">
                                                                                                            <Form.Item
                                                                                                                {...field}
                                                                                                                label="Occupation"
                                                                                                                name={[field.name, 'occupation']}
                                                                                                                fieldKey={[field.fieldKey, 'occupation']}
                                                                                                                rules={[{ type: "string",  message: 'Occupation ' },
                                                                                                                { max: 100, message: 'Occupation  must be maximum 100 characters.' }]}
                                                                                                            >
                                                                                                                <Input placeholder="Project Manager" />
                                                                                                            </Form.Item>
                                                                                                        </Col>


                                                                                                        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gutter-row">
                                                                                                            <Form.Item
                                                                                                                {...field}
                                                                                                                label="Company"
                                                                                                                name={[field.name, 'company']}
                                                                                                                fieldKey={[field.fieldKey, 'company']}
                                                                                                                rules={[{ type: "string",  message: 'Company ' },
                                                                                                                { max: 100, message: 'Company  must be maximum 100 characters.' }]}
                                                                                                            >
                                                                                                                <Input placeholder="ABC Company" />
                                                                                                            </Form.Item>
                                                                                                        </Col>

                                                                                                    </Row>


                                                                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>

                                                                                                        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gutter-row">
                                                                                                            <Form.Item
                                                                                                                {...field}
                                                                                                                label="Email"
                                                                                                                name={[field.name, 'email']}
                                                                                                                fieldKey={[field.fieldKey, 'email']}
                                                                                                                rules={[
                                                                                                                    {
                                                                                                                        type: 'email',
                                                                                                                        message: 'Please input valid Email',
                                                                                                                    },
                                                                                                                    { max: 150, message: 'Email must be maximum 150 characters.' },
                                                                                                                ]}
                                                                                                            >
                                                                                                                <Input placeholder="janesmith@example.com" />
                                                                                                            </Form.Item>
                                                                                                        </Col>


                                                                                                        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gutter-row">
                                                                                                            <Form.Item
                                                                                                                {...field}
                                                                                                                label="Phone"
                                                                                                                name={[field.name, 'phone']}
                                                                                                                fieldKey={[field.fieldKey, 'phone']}
                                                                                                                rules={[
                                                                                                                    { type: "string", message: 'Please input your Phone!' },
                                                                                                                    { min: 9, message: 'Phone must be minimum 9 characters.' },
                                                                                                                    { max: 15, message: 'Phone must be maximum 15 characters.' },
                                                                                                                ]}
                                                                                                            >
                                                                                                                <Input placeholder="2356 26559 5526" />
                                                                                                            </Form.Item>
                                                                                                        </Col>

                                                                                                    </Row>







                                                                                                </div>

                                                                                            }
                                                                                        </div>
                                                                                    </Panel>
                                                                                </Collapse>
                                                                            </div>

                                                                        )}
                                                                    </Draggable>

                                                                </>


                                                            ))}
                                                            <div className="spin-loader" align="center">
                                                                {loadings && <Spin size="small" />}
                                                            </div>
                                                            {provided.placeholder}
                                                        </div>
                                                    )
                                                }
                                            </Droppable>
                                            <Form.Item className="add-btn-align add-btn">
                                                <Button type="primary" loading={loadings} onClick={() => add({ fullName: '', occupation: '', company: "", email: "", phone: "", objid: shortid.generate() })} icon={<PlusOutlined />}>
                                                    Add Reference
                                                </Button>
                                            </Form.Item>
                                        </DragDropContext>


                                    </>
                                )}
                            </Form.List>
                        </Col>
                    </Row>


                    <Row>
                        {/* <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Experience
                            </Button>
                            </Form.Item> */}
                    </Row>

                </Form>
            </Skeleton>

        </>
    );
}

export default ReferencesForm;