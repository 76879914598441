import React, { useContext, useState, useEffect } from 'react'
import cx from 'classnames'
import bootstrap from '../../../../css/bootstrap.module.css'
import { CoverletterContext } from '../../../../contexts/CoverLetterContext'
import { TemplateContext } from '../../../../contexts/TemplateContext';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import style from './diamond.module.css'
const styles = { ...bootstrap, ...style }

const Diamond = () => {
    const [comAddress, setComAddress] = useState("");

    const CoverLetter = useContext(CoverletterContext);
    const CoverData = CoverLetter.coverletter;

    const TemplateCol = useContext(TemplateContext);
    const Color = (TemplateCol.template.coverLetterColor);


    useEffect(() => {
        if (CoverData.applying.companyAddress && CoverData.applying.companyAddress !== null) {
            const arrAddress = CoverData.applying.companyAddress.split(",").join(",</br>");
            setComAddress(arrAddress);
        }
        // console.log(comAddress, "address")
    }, []);

    return (
        <div className={cx(styles['diamond'], styles[Color])}>
            <div id="header">
                <div className={cx(styles['header'])}>
                    <div className={cx(styles['d-flex'], styles['justify-content-between'])}>
                        <div className={cx(styles['name-profession'])}>
                            <h1 className={cx(styles['full-name'], styles['mb-0'])}>{CoverData.personalInformation && CoverData.personalInformation.firstName && CoverData.personalInformation.firstName.length !== 0 ? <>{CoverData.personalInformation.firstName}</> : ""} {CoverData.personalInformation && CoverData.personalInformation.lastName && CoverData.personalInformation.lastName.length !== 0 ? <>{CoverData.personalInformation.lastName}</> : ""}</h1>
                            <p className={cx(styles['profession'], styles['mb-2'], styles['font-weight-normal'], styles['text-muted'])}>{CoverData.personalInformation && CoverData.personalInformation && CoverData.personalInformation.profession.length !== 0 ? <>{CoverData.personalInformation.profession}</> : ""}</p>

                        </div>
                        <div className={cx(styles['contact'], styles['d-flex'], styles['justify-content-end'])}>
                            <div className={cx(styles['text-right'])}>
                                <p className={cx(styles['w-100'], styles['mb-1'], styles['pl-1'])}>{CoverData.personalInformation && CoverData.personalInformation.address && CoverData.personalInformation.address.length !== 0 ? <>{CoverData.personalInformation.address}</> : ""}</p>
                                <p className={cx(styles['w-100'], styles['mb-1'], styles['pl-1'])}>{CoverData.personalInformation && CoverData.personalInformation.phone && CoverData.personalInformation.phone.length !== 0 ? <>{CoverData.personalInformation.phone}</> : ""}</p>
                                <p className={cx(styles['w-100'], styles['mb-1'], styles['pl-1'])}>
                                    {CoverData.personalInformation && CoverData.personalInformation.email && CoverData.personalInformation.email.length !== 0 ? <>{CoverData.personalInformation.email}</> : ""}</p>
                            </div>
                        </div>
                    </div>

                </div>
                <div className={cx(styles['space'])}></div>
            </div>

            <div className={cx(styles['letter-content'])}>
                {CoverData.letterBody && CoverData.letterBody.date !== null ?
                    <div>
                        <p>{moment(CoverData.letterBody.date).format(CoverData.letterBody.dateFormat)}</p>
                        <div className={cx(styles['clear'])}></div>
                        <div className={cx(styles['space'])}></div>
                    </div>
                    : ""
                }

                <div className={cx(styles['name-possition'])}>
                    <div className={cx(styles['name'])}>
                        <p className={cx(styles['com-p-mb'])}>{CoverData.applying && CoverData.applying.title !== "" ? <>{CoverData.applying.title}.</> : ""} {CoverData.applying && CoverData.applying.firstName.length !== 0 ? <>{CoverData.applying.firstName}</> : ""} {CoverData.applying && CoverData.applying.lastName.length !== 0 ? <>{CoverData.applying.lastName},</> : ""}</p>
                    </div>
                    <div className={cx(styles['possition'])}>
                        <p className={cx(styles['com-p-mb'])}>{CoverData.applying && CoverData.applying.position !== "" ? <>{CoverData.applying.position},</> : ""}</p>
                    </div>
                    <div className={cx(styles['company'], styles['mb-0'])}>
                        <p className={cx(styles['com-p-mb'])}>{CoverData.applying && CoverData.applying.companyName !== "" ? <>{CoverData.applying.companyName},</> : ""}</p>
                    </div>
                </div>
                <div className={cx(styles['address'])}>
                    <p className={cx(styles['com-p-mb'])}>
                        {CoverData.applying.companyAddress && CoverData.applying.companyAddress !== "" ? <>
                            {/* {CoverData.applying.companyAddress} */}
                            {ReactHtmlParser(comAddress)}
                        </> : ""}
                    </p>
                </div>
                <div className={cx(styles['clear'])}></div>
                <div className={cx(styles['space'])}></div>

                {CoverData.letterBody && CoverData.letterBody.applyingJob.length !== 0 ?
                    <div>
                        <strong><p>RE: {CoverData.letterBody.applyingJob}</p></strong>
                        <div className={cx(styles['clear'])}></div>
                        <div className={cx(styles['space'])}></div>
                    </div> : ""
                }

                <div className={cx(styles['letter-body'])}>
                    <div className={cx(styles['dear'])}>
                        <p>{CoverData.applying.title ? <> {CoverData.applying.title}.</> : ""} {CoverData.applying && CoverData.applying.firstName.length !== 0 ? <>{CoverData.applying.firstName},</> : ""}</p>
                    </div>
                    <div className={cx(styles['space-end'])}></div>
                    <div className={cx(styles['letter'])} >
                        {CoverData.letterBody &&
                            <div>
                                {ReactHtmlParser(CoverData.letterBody.body)}
                            </div>
                        }

                        <div className={cx(styles['p-space'], styles['mt-3'])}></div>

                        <p>Sincerely,<br />
                            <span>{CoverData.personalInformation && CoverData.personalInformation.firstName && CoverData.personalInformation.firstName ? <>{CoverData.personalInformation.firstName}</> : ""}</span> </p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Diamond;