import React, { useContext, useEffect } from 'react'

import { Scale } from 'react-scaling';
import Diamond from '../../../resume/templates/diamond/Diamond';
// import Pearl from '../../../resume/templates/pearl/Pearl';
import Sapphire from '../../../resume/templates/sapphire/Sapphire';
import Jasper from '../../../resume/templates/jasper/Jasper';
import { TemplateContext } from '../../../../contexts/TemplateContext';




const ResumePreview = () => {

    const { template } = useContext(TemplateContext);

    return (
        <div className="ant-mt-4 ant-mb-4">
            <Scale scaleTo={2100}>
                {template.resumeTemplateName === "diamond" && <Diamond></Diamond>}
                {template.resumeTemplateName === "sapphire" && <Sapphire></Sapphire>}
                {template.resumeTemplateName == "jasper" && <Jasper></Jasper>}
            </Scale>

        </div>
    )
}

export default ResumePreview;