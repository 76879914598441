import React from 'react'
import { useHistory } from "react-router-dom";
import MainNav from '../../navigation/MainNav';
import { Row, Col, Menu } from 'antd';
import ResumeFormProgress from '../../navigation/resume/ResumeFormProgress';
import ResumeProgress from '../../navigation/resume/ResumeProgress';
import ResumePreview from './resume-preview/ResumePreview';
import FinalizeForm from '../../resume/forms/FinalizeForm';
import DownloadBtn from '../../actions/DownloadBtn';
import AppMessages from '../../actions/AppMessages';
import TemplateChange from '../../actions/TemplateChange';
import TemplateColorChange from '../../actions/TemplateColorChange';

const Finalize = () => {

    const history = useHistory();

    const Previuos = () => {
        history.push(`/resume/header`);
    }
    return (
        <div>
            <MainNav />
            <AppMessages />
            <Row className="resume-page">
                <Col xl={13} lg={13} md={24} sm={24} xs={24} className="form-section" align="left">
                    <ResumeProgress />
                    <FinalizeForm />
                </Col>
                <Col xl={11} lg={11} md={24} sm={24} xm={24} className="preview-section">
                    {/* <ResumeFormProgress /> */}
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={24} xm={24} className="ant-ml-0 ant-mr-0">
                            <TemplateChange />
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={24} xm={24} className="ant-ml-0 ant-mr-0">
                            <TemplateColorChange />
                        </Col>
                    </Row>
                    <div>
                        <ResumePreview />
                    </div>
                    <DownloadBtn />
                </Col>
            </Row>
        </div>
    )
}
export default Finalize;