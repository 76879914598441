import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Form, Input, Button, Checkbox, Space, AutoComplete, Row, Col, Divider, Skeleton, Spin } from 'antd';
import { DeleteTwoTone, DragOutlined, PlusOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import shortid from 'shortid';
import debounce from 'lodash.debounce';

import { ResumeContext } from '../../../contexts/ResumeContext';


const HeaderForm = () => {

    const { resume, setResumeStatus } = useContext(ResumeContext);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [loadings, setLoadings] = useState(false);

    const [personal, setPersonal] = useState({
        firstName: "",
        lastName: "",
        address: "",
        email: "",
        phone: "",
        profession: "",
        profileLinks: [
            { website: '', websiteLink: '', objid: shortid.generate() },
        ]
    })

    useEffect(() => {
        if (resume.personal?.email) {
            setPersonal(resume.personal);
            form.setFieldsValue(resume.personal);
        } else {
            form.setFieldsValue(personal);
        }
        const timer = setTimeout(() => {
            setLoading(false);
        }, 300);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {

        setLoadings(true)
        const items = personal.profileLinks.filter(item => {
            if (item.website !== undefined && item.websiteLink !== undefined)
                return item;
        })
        personal['profileLinks'] = items;
        form.setFieldsValue(personal)
        setResumeStatus({ ...resume, personal });
        const timer = setTimeout(() => {
            setLoadings(false)
        }, 100);
        
        return () => clearTimeout(timer);
    }, [personal]);


    const debounceSave = useCallback(
        debounce((nextvalue) => setPersonal(nextvalue), 300)
        , []
    );

    const onValuesChange = (val, all) => {
        onFinish();
        debounceSave(all);
    }

    const onDragEnd = (result) => {

        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const items = reorder(
            personal.profileLinks,
            result.source.index,
            result.destination.index
        );
        personal['profileLinks'] = items;
        setPersonal({ ...personal })

    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onFinish = values => {
        console.log('Received values of form:', values);
    };

    return (
        <>
            <Skeleton active={true} loading={loading}>
                <Form
                    onFinish={onFinish}
                    layout={"vertical"}
                    wrapperCol={{
                        span: 24
                    }}
                    size={"middle"}
                    form={form}
                    name="header"
                    onValuesChange={onValuesChange}
                >
                    <div className="spin-loader" align="center">
                        {loadings && <Spin size="small" />}
                    </div>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gutter-row">
                            <Form.Item
                                label="First Name"
                                name="firstName"
                                rules={[
                                    {
                                        type: "string",
                                        message: 'Please input your First Name!',
                                    },
                                    { max: 100, message: 'First Name must be maximum 100 characters.' },
                                ]}
                            >
                                <Input
                                    placeholder={"John"}
                                />

                            </Form.Item>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gutter-row">
                            <Form.Item
                                label="Last Name"
                                name="lastName"
                                rules={[
                                    {
                                        type: "string",
                                        message: 'Please input your Last Name!',
                                    },
                                    { max: 100, message: 'Last Name must be maximum 100 characters.' },
                                ]}
                            >
                                <Input
                                    placeholder={"Doe"}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>

                        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gutter-row">
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    {
                                        type: 'email',
                                        required: true,
                                        message: 'Please input valid Email',
                                    },
                                    { max: 150, message: 'Email must be maximum 150 characters.' },
                                ]}
                            >
                                <Input
                                    placeholder={"johndoe@gmail.com"}
                                />
                            </Form.Item>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gutter-row">
                            <Form.Item
                                label="Phone"
                                name="phone"
                                rules={[
                                    { type: "string", message: 'Please input your Phone!' },
                                    { min: 9, message: 'Phone must be minimum 9 characters.' },
                                    { max: 15, message: 'Phone must be maximum 15 characters.' },
                                ]}
                            >
                                <Input
                                    placeholder={"xxx xxxx xxx"}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>

                            <Form.Item
                                label="Profession"
                                name="profession"
                                rules={[
                                    {
                                        type: "string",
                                        message: 'Please input your Profession!',
                                    },
                                    { max: 100, message: 'Profession must be maximum 100 characters.' },
                                ]}
                            >

                                <AutoComplete
                                    dataSource={[]}
                                    placeholder="Web Developer"
                                />

                            </Form.Item>
                        </Col>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="Address"
                                name="address"
                                rules={[
                                    {
                                        type: "string",
                                        message: 'Please input your Address',
                                    },
                                    { max: 255, message: 'Address must be maximum 255 characters.' },
                                ]}
                            >
                                <Input
                                    placeholder="No.xx,Garden Road,NY"
                                />
                            </Form.Item>
                        </Col>
                    </Row>



                    <Divider orientation={"center"} className="ant-mt-5">Social Links</Divider>
                    <Row className="ant-mt-6">
                        <Col xl={24} lg={24} md={24} sm={24} xs={24} align="center">

                            <Form.List name="profileLinks"

                            >
                                {(fields, { add, remove }) => (
                                    <>
                                        <DragDropContext onDragEnd={onDragEnd}>


                                            <Droppable droppableId="item" >

                                                {
                                                    (provided) => (
                                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                                            {fields.map((field, index, arr) => (

                                                                <>

                                                                    <Draggable key={personal.profileLinks[index] ? personal.profileLinks[index].objid : 'temp'} draggableId={personal.profileLinks[index] ? personal.profileLinks[index].objid : 'temp'} index={index}>
                                                                        {provided => (
                                                                            <div ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}>
                                                                                <div >
                                                                                    {
                                                                                        <Row key={field.key} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="social-section personal-social">
                                                                                            <Col xl={10} lg={10} md={10} sm={24} xs={24} className="gutter-row" align="left">
                                                                                                <Form.Item
                                                                                                    {...field}
                                                                                                    name={[field.name, 'website']}
                                                                                                    fieldKey={[field.fieldKey, 'website']}
                                                                                                    rules={[
                                                                                                        { type: "string", required: true, message: 'Web Site Name Required' },
                                                                                                        { max: 100, message: 'Name must be maximum 100 characters.' },
                                                                                                    ]}
                                                                                                >

                                                                                                    <AutoComplete
                                                                                                        dataSource={["Web", "LinkedIn", "GitHub", "Behance", "Twitter", "Facebook", "Dribbble"]}
                                                                                                        placeholder="Web Site"
                                                                                                    />
                                                                                                </Form.Item>

                                                                                            </Col>
                                                                                            <Col xl={10} lg={10} md={10} sm={24} xs={24} className="gutter-row" >
                                                                                                <Form.Item
                                                                                                    {...field}
                                                                                                    name={[field.name, 'websiteLink']}
                                                                                                    fieldKey={[field.fieldKey, 'websiteLink']}
                                                                                                    rules={[
                                                                                                        { type: "string", required: true, message: 'Website Link Required' },
                                                                                                        { max: 255, message: 'url must be maximum 255 characters.' },
                                                                                                    ]}
                                                                                                >
                                                                                                    <Input placeholder="Website Link" />
                                                                                                </Form.Item>
                                                                                            </Col>
                                                                                            <Col xl={2} lg={2} md={2} sm={12} xs={12} className="gutter-row" >
                                                                                                {loadings === false && <DeleteTwoTone
                                                                                                    className="remove-btn ant-mt-1"
                                                                                                    twoToneColor="#f5222d"
                                                                                                    onClick={() => remove(field.name)} />}
                                                                                            </Col>
                                                                                            <Col xl={2} lg={2} md={2} sm={12} xs={12} className="gutter-row">
                                                                                                <DragOutlined className="drag-btn ant-mt-1"  />
                                                                                            </Col>
                                                                                        </Row>

                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </Draggable>

                                                                </>


                                                            ))}
                                                            { provided.placeholder}
                                                        </div>
                                                    )
                                                }
                                            </Droppable>
                                            <div className="spin-loader" align="center">
                                                {loadings && <Spin size="small" />}
                                            </div>
                                            <Form.Item className="add-btn" >
                                                <Button type="primary" onClick={() => add({ website: '', websiteLink: '', objid: shortid.generate() })} icon={<PlusOutlined />} className="ant-mt-2">
                                                    Add Links (linkedIn, Github, etc..)
                                                </Button>
                                            </Form.Item>
                                        </DragDropContext>
                                    </>
                                )}
                            </Form.List>
                        </Col>
                    </Row>
                </Form>
            </Skeleton>
        </>
    );
}

export default HeaderForm;