import React, { useContext, useEffect, useState } from 'react'
import { Form, Radio, Col, Row } from 'antd';
import { TemplateContext } from '../../contexts/TemplateContext';
import { useLocation } from 'react-router-dom';

const TemplateColorChange = () => {
    const location = useLocation();
    const { template, setTemplateStatus } = useContext(TemplateContext);
    const [builder, setBuilder] = useState("");

    const handleChange = (e) => {
        if (builder == "resume") {
            let resumeColor = e.target.value
            setTemplateStatus({ ...template, resumeColor });
        } else if (builder == "cover-letter") {
            let coverLetterColor = e.target.value
            setTemplateStatus({ ...template, coverLetterColor });
        }
    }

    const tempCol = () => {
        if (location.pathname == "/resume/finalize") {
            setBuilder("resume");
        } else if (location.pathname == "/cover-letter/finalize") {
            setBuilder("cover-letter")
        }
    }

    useEffect(() => {
        tempCol();
    }, [])

    return (
        <div >
            <Row className="ant-mt-3" align="middle" justify="center">
                <Col xl={9} lg={9} md={12} sm={24} xs={24}>
                    <span><strong>Change Color</strong></span>
                </Col>
                <Col xl={15} lg={15} md={12} sm={24} xs={24} onChange={handleChange}>
                    <Radio.Group
                        buttonStyle="solid"
                        className={builder == "resume" ? template.resumeTemplateName : builder == "cover-letter" ? template.coverLetterTemplateName : ""}
                        defaultValue={(location.pathname == "/resume/finalize" && template.resumeColor) || (location.pathname == "/cover-letter/finalize" && template.coverLetterColor)}
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        <Radio.Button value="default" className={"radio-all radio-" + ((location.pathname == "/resume/finalize" && template.resumeTemplateName) || (location.pathname == "/cover-letter/finalize" && template.coverLetterTemplateName))} >default</Radio.Button>
                        <Radio.Button value="grey" className="radio-all radio-grey">grey</Radio.Button>
                        <Radio.Button value="red" className="radio-all radio-red">red</Radio.Button>
                        <Radio.Button value="orange" className="radio-all radio-orange">orange</Radio.Button>
                        <Radio.Button value="yellow" className="radio-all radio-yellow">yellow</Radio.Button>
                        <Radio.Button value="green" className="radio-all radio-green">green</Radio.Button>
                        <Radio.Button value="blue" className="radio-all radio-blue">blue</Radio.Button>
                        <Radio.Button value="magenta" className="radio-all radio-magenta">magenta</Radio.Button>
                    </Radio.Group>
                </Col>
            </Row>
        </div>
    )
}

export default TemplateColorChange;
