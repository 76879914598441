import React, { useContext, useState, useEffect } from 'react'
import { Link, useHistory } from "react-router-dom";
import { Menu, Row, Col, Image } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { ResumeContext } from '../../contexts/ResumeContext';

const { SubMenu } = Menu;

const MainNav = () => {
    const [name, setName] = useState("")
    const Resume = useContext(ResumeContext);
    const history = useHistory();

    const userName = () => {
        if (Resume == undefined) {
            setName("")
        } else {
            setName(Resume.resume.personal.firstName);
        }
    }
   
    const handleClick = (e) => {
        if (e.key === 'dashboard') {
            history.push(`/`);
        }
        // else {
        //     const navid = document.getElementById(e.key);
        //     navid.scrollIntoView();
        // }

    }
    useEffect(() => {
        userName();
    })

    return (
        <Row flex="auto" className="main-menu-parent">
            <Col className="logo-col ant-pl-4 ant-pt-2" xl={8} lg={15} md={20} sm={17} xs={20} >
                <a href="/" className="main-logo">
                    <Image
                        width={150}
                        src="/assets/img/logo/logo.png"
                    />
                </a>
            </Col>
            <Col xl={16} lg={9} md={4} sm={7} xs={4} className="menu-items-col" >
                <Menu
                    style={{ display: "flex", alignItems: "center" }}
                    mode="horizontal"
                    className="main-menu ant-pt-5 ant-pb-5"
                    onClick={handleClick}
                >
                    <Menu.Item key="dashboard" className="main-menu-item">
                        Dashboard
                    </Menu.Item>

                    <Menu.Item className="main-menu-item">
                        <a href="https://resumoz.com/" target="_blank" rel="noopener noreferrer">Home</a>
                    </Menu.Item>

                    <SubMenu key="Resume" title={<div>Resumes<DownOutlined className="text-muted main-collpase-icon" /></div>} className="main-menu-item">
                        <Menu.ItemGroup >

                        <Menu.Item key="resume:0" style={{ paddingLeft: "0" }}>
                                <a href="https://resumoz.com/resume-templates" target="_blank" rel="noopener noreferrer">
                                    <strong>Resume Templates</strong>
                                </a>
                            </Menu.Item>

                            <Menu.Item key="resume:1" style={{ paddingLeft: "0" }}>
                                <a href="https://resumoz.com/how-to-write-a-resume" target="_blank" rel="noopener noreferrer">
                                    <strong>How to write a resume</strong>
                                </a>
                            </Menu.Item>

                            <Menu.Item key="resume:2" style={{ paddingLeft: "0" }}>
                                <a href="https://resumoz.com/resume/resume-header" target="_blank" rel="noopener noreferrer">
                                    <strong>Resume Header</strong>
                                </a>
                            </Menu.Item>

                            <Menu.Item key="resume:3" style={{ paddingLeft: "0" }}>
                                <a href="https://resumoz.com/resume/resume-summary" target="_blank" rel="noopener noreferrer">
                                    <strong>Resume Summary</strong>
                                </a>
                            </Menu.Item>

                            <Menu.Item key="resume:4" style={{ paddingLeft: "0" }}>
                                <a href="https://resumoz.com/resume/work-experience" target="_blank" rel="noopener noreferrer">
                                    <strong>Resume Work-experience</strong>
                                </a>
                            </Menu.Item>

                            <Menu.Item key="resume:5" style={{ paddingLeft: "0" }}>
                                <a href="https://resumoz.com/resume/resume-education" target="_blank" rel="noopener noreferrer">
                                    <strong>Resume Education</strong>
                                </a>
                            </Menu.Item>

                            <Menu.Item key="resume:6" style={{ paddingLeft: "0" }}>
                                <a href="https://resumoz.com/resume/resume-skills" target="_blank" rel="noopener noreferrer">
                                    <strong>Resume Skills</strong>
                                </a>
                            </Menu.Item>

                            <Menu.Item key="resume:3" style={{ paddingLeft: "0" }}>
                                <a href="https://resumoz.com/what-is-an-ats-resume/" target="_blank" rel="noopener noreferrer">
                                    <strong>ATS Friendly Resume</strong>
                                </a>
                            </Menu.Item>
                        </Menu.ItemGroup>
                    </SubMenu>

                    <SubMenu key="coverLetter" title={<div>Cover Letters<DownOutlined className="text-muted main-collpase-icon" /></div>} className="main-menu-item">
                        <Menu.ItemGroup >
                        <Menu.Item key="cover:0" style={{ paddingLeft: "0" }}>
                                <a href="https://resumoz.com/cover-letter-templates" target="_blank" rel="noopener noreferrer">
                                    <strong>Cover Letter Templates</strong>
                                </a>
                            </Menu.Item>
                            <Menu.Item key="cover:1" style={{ paddingLeft: "0" }}>
                                <a href="https://resumoz.com/cover-letter-builder" target="_blank" rel="noopener noreferrer">
                                    <strong>Cover Letter Guide</strong>
                                </a>
                            </Menu.Item>
                        </Menu.ItemGroup>
                    </SubMenu>

                    <Menu.Item className="main-menu-item">
                        <a href="https://resumoz.com/blog/" target="_blank" rel="noopener noreferrer">
                            Blog
                        </a>
                    </Menu.Item >

                    <Menu.Item className="main-menu-item">
                        <a href="https://resumoz.com/about/" target="_blank" rel="noopener noreferrer">
                            About
                        </a>
                    </Menu.Item>


                    <Menu.Item className="main-menu-item">
                        Howdy{name === "" ? "" : ","} {name}
                    </Menu.Item>
                    {/* <SubMenu key="user" title={<div>Howdy{name == "" ? "" : ","} {name}<DownOutlined className="text-muted main-collpase-icon" /></div>} className="main-menu-item">
                    <Menu.ItemGroup >
                            <Menu.Item key="user:1">Log In</Menu.Item>
                            <Menu.Item key="user:2">Log Out</Menu.Item>
                        </Menu.ItemGroup>
                </SubMenu> */}

                </Menu>
            </Col>
        </Row >
    )
}

export default MainNav;