import React, { useContext } from 'react'
import { Scale } from 'react-scaling';
import { Row, Col } from 'antd';
import Diamond from '../../../cover-letter/templates/diamond/Diamond';
import Sapphire from '../../../cover-letter/templates/sapphire/Sapphire';
import Jasper from '../../../cover-letter/templates/jasper/Jasper'
import { TemplateContext } from '../../../../contexts/TemplateContext';

const CoverPreview = () => {
    const { template } = useContext(TemplateContext);
    return (
        <div className="ant-mt-4 ant-mb-4 cover-preview">
            <Row>
                <Col span={24}>
                    <Scale scaleTo={1900}>
                        {template.coverLetterTemplateName === "diamond" && <Diamond></Diamond>}
                        {template.coverLetterTemplateName === "sapphire" && <Sapphire></Sapphire>}
                        {template.coverLetterTemplateName == "jasper" && <Jasper></Jasper>}
                    </Scale>
                </Col>
            </Row>

        </div>
    )
}

export default CoverPreview;