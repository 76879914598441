import * as React from "react";

import templateDataHandler from "../handler/TemplateDataHandler";



export const TemplateContext = React.createContext();


export const TemplateProvider = props => {


    const { template, setTemplateStatus, setUnsetTemplateStatus } = templateDataHandler();

    return (
        <TemplateContext.Provider value={{ template, setTemplateStatus, setUnsetTemplateStatus }}>
             {props.children}
        </TemplateContext.Provider>
    )
}