import * as React from "react";

const StepDataHandler = () => {
    const [step, setStep] = React.useState(
        localStorage.getItem('step') ? JSON.parse(localStorage.getItem('step')) :
            { current: 0 }
    );

    const setStepStatus = (step) => {
        window.localStorage.setItem("step", JSON.stringify(step));
        setStep(step);
    };

    const setUnsetStepStatus = () => {
        window.localStorage.clear();
        setStep({ current: 0 });
    };

    return {
        step,
        setStepStatus,
        setUnsetStepStatus
    };
};

export default StepDataHandler;