import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Input, Button, Skeleton, Tabs, Rate, AutoComplete } from 'antd';
import { DeleteTwoTone, DragOutlined, PlusOutlined, StarTwoTone } from '@ant-design/icons';

import SoftSkills from './SoftSkills';
import HardSkills from './HardSkills';

const { TabPane } = Tabs

const SkillsSkeleton = () => {

    const [loading, setLoading] = useState(true);

    const [act, setAct] = useState({
        activeTab: "1"
    });

    const handleChange = () => {
        setAct({ ...act, activeTab: act.activeTab });
    }

    useEffect(() => {
       
        const timer = setTimeout(() => {
            setLoading(false);
        }, 300);
        return () => clearTimeout(timer);

    }, []);


    return (
        <div>

            <Skeleton active={true} loading={loading}>
                <Row className="ant-mt-10 skills-tab">
                    <Col xl={24} lg={24} md={24} sm={24} xs={24} align="left">
                        <Tabs
                            type="card"
                            defaultActiveKey="1"
                            centered="true"
                            animated="true"
                            onChange={handleChange}
                            keyboard="true"
                        >

                            <TabPane
                                tab={"Soft Skills"}
                                key="1" >

                                <div className="">
                                    <SoftSkills />
                                </div>

                            </TabPane>

                            <TabPane
                                tab={"Hard Skills"}
                                key="2"
                                activeKey={act.activeTab}
                            >


                                <div className="">
                                    <HardSkills />
                                </div>

                            </TabPane>

                        </Tabs>
                    </Col>
                </Row>
            </Skeleton>
        </div>
    )
}

export default SkillsSkeleton;