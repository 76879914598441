import * as React from "react";

const SessionDataHandler = () => {
    const [session, setSession] = React.useState(
        localStorage.getItem('session') ? JSON.parse(localStorage.getItem('session')) :
            { id: ""}
    );

    const setSessionStatus = (session) => {
        window.localStorage.setItem("session", JSON.stringify(session));
        setSession(session);
    };

    const setUnsetSessionStatus = () => {
        window.localStorage.clear();
        setSession({ id: "" });
    };

    return {
        session,
        setSessionStatus,
        setUnsetSessionStatus
    };
};

export default SessionDataHandler;