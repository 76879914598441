import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Typography, Progress } from 'antd'

const {Title } = Typography;

const ResumeProgressMobile = () => {
    const location = useLocation();
    // console.log(location, 'current location');
    const [step, setStep] = useState("1/6");

    const getCurrentStep = (path) => {

        switch (path) {
            case '/resume/header':
                return "1/6";
            case '/resume/experience':
                return "2/6";
            case '/resume/education':
                return "3/6";
            case '/resume/skills':
                return "4/6";
            case '/resume/summary':
                return "5/6";
            case '/resume/finalize':
                return "6/6";

            default:
                return "1/6";
        }

    }

    useEffect(() => {
        setStep(getCurrentStep(location.pathname));
    }, [])


    return (

        <div
            className="resume-progress-mob cover-progress-mob ant-pt-3 ant-pb-3  ant-mb-7 ">
            <Progress
                className="ant-pl-5"
                type="circle"
                strokeColor={{
                    '0%': '#40a9ff',
                    '100%': '#002766',
                }}
                percent={100}
                format={() => (step)}
            />
            <div className="ant-pt-7 ant-pl-10" style={{ textAlign: "right" }}>
                <Title level={3} className="ant-mb-0 ant-pt-2 " style={{ textTransform: "capitalize" }}>{location.pathname.substring('/resume/'.length)}</Title>
            </div>

        </div>
    )
}

export default ResumeProgressMobile;