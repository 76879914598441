import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {Typography, Progress } from 'antd'

const {Title } = Typography;

const CoverProgressMobile = () => {
    const location = useLocation();
    // console.log(location, 'current location');
    const [step, setStep] = useState("1/5");

    const getCurrentStep = (path) => {

        switch (path) {
            case '/cover-letter/experience':
                return "1/5";
            case '/cover-letter/education':
                return "2/5";
            case '/cover-letter/skills':
                return "3/5";
            case '/cover-letter/general':
                return "4/5";
            case '/cover-letter/finalize':
                return "5/5";

            default:
                return "1/5";
        }

    }

    useEffect(() => {
        setStep(getCurrentStep(location.pathname));
    }, [])

    return (

        <div
            className="resume-progress-mob cover-progress-mob ant-pt-3 ant-pb-3  ant-mb-7 ">
            <Progress
                className="ant-pl-5"
                type="circle"
                strokeColor={{
                    '0%': '#40a9ff',
                    '100%': '#002766',
                }}
                percent={100}
                format={() => (step)}
            />
            <div className="ant-pt-7 ant-pl-10" style={{ textAlign: "right" }}>
                <Title level={3} className="ant-mb-0 ant-pt-2 " style={{ textTransform: "capitalize" }}>{location.pathname.substring('/cover-letter/'.length)}</Title>
            </div>

        </div>
    )
}

export default CoverProgressMobile;