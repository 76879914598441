import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Row, Col, Typography, Button, Form, Select, Spin, Input, AutoComplete, Divider } from 'antd';
import shortid from 'shortid';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DeleteTwoTone, DragOutlined, PlusOutlined } from '@ant-design/icons';
import { CoverletterContext } from '../../../../contexts/CoverLetterContext';
import CoverletterBtn from '../../../actions/CoverletterBtn';
import { JobTitles } from '../../../../utils/JobTitles';

const { Title, Text } = Typography;
const { Option } = Select;

const ProfessionalSkills = () => {


    const { coverletter, setCoverletterStatus } = useContext(CoverletterContext);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [loadings, setLoadings] = useState(false);

    const [jobs, setJobs] = useState(JobTitles);
    const [skills, setSkills] = useState([
        'A/B testing',
        'Academic writing',
        'Acrobat',
        'AdWords',
        'Agile software',
        'Aritic PinPoint',
        'Automated Billing Systems',
        'Basic writing',
        'Budgeting',
        'Business knowledge',
        'Business writing reports',
        'C#',
        'C++',
        'CAD',
        'Color theory',
        'Content management',
        'Copywriting',
        'Corel Draw',
        'Creative writing',
        'Creativity',
        'CRM Platforms',
        'CRO',
        'CSS',
        'Data analysis',
        'Data and metrics interpreting',
        'Data engineering',
        'Data mining',
        'Data presentation',
        'Data visualization',
        'Database management',
        'Diagnostics',
        'Editing',
        'Email',
        'Email marketing and automation',
        'Email writing',
        'Enterprise systems',
        'Facebook Paid Ads',
        'Finance',
        'Financial modeling',
        'Forecasting',
        'Free Hand',
        'Funnel management',
        'Google Analytics',
        'Google Drive',
        'Google Search Console',
        'Graphic design',
        'Graphics',
        'Hiring',
        'HTML',
        'HubSpot',
        'Illustrator',
        'InDesign',
        'Infusionsoft',
        'iOS/Swift',
        'Java',
        'Javascript',
        'Journalism',
        'Kanban',
        'Layout',
        'Lean manufacturing',
        'Letter writing',
        'Linear regression',
        'Logistics',
        'MS Office',
        'Multivariate analysis',
        'MySQL',
        'Negotiating',
        'New business development',
        'Note-taking',
        'Node js',
        'Office management skills',
        'ONTRAPORT',
        'paid social media advertising',
        'Payment processing',
        'Performance tracking',
        'Persuasion',
        'Photoshop',
        'PHP',
        'Planning',
        'PPC',
        'Presentation writing',
        'Presentations/Slideshows',
        'Press releases',
        'Print design',
        'Project lifecycle management',
        'Project management',
        'Project scheduling',
        'Proposal writing',
        'Prototyping',
        'Python',
        'Quickbooks',
        'Reporting',
        'Research',
        'Resource management',
        'Ruby on Rails',
        'React js',
        'Scrum management',
        'SEO',
        'SEM',
        'Sketching',
        'Slideshows',
        'Social media',
        'Social media marketing',
        'Spreadsheets',
        'SQL',
        'STEM skills',
        'Storytelling',
        'Strategic planning',
        'Theorizing',
        'Troubleshooting',
        'Typing',
        'TypeScript',
        'Typography',
        'UX Design',
        'UX research',
        'UX/UI design',
        'Visual communication',
        'Web',
        'Workflow development',
        'WPM',
        'Writing'
    ]);

    const [professionalSkills, setProfessionalSkills] = useState({

        jobIndustry: "",
        proSkills: [
            { skill: '', objid: shortid.generate() },
            { skill: '', objid: shortid.generate() },
            { skill: '', objid: shortid.generate() },
        ]
    })


    useEffect(() => {


        if (coverletter.professionalSkills) {
            setProfessionalSkills(coverletter.professionalSkills);
            form.setFieldsValue(coverletter.professionalSkills);
        } else {
            form.setFieldsValue(professionalSkills);
        }
        const timer = setTimeout(() => {
            setLoading(false);
        }, 300);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {

        setLoadings(true)
        const items = professionalSkills.proSkills.filter(item => {
            if (item.skill !== undefined)
                return item;
        })
        professionalSkills['proSkills'] = items;
        form.setFieldsValue(professionalSkills)
        setCoverletterStatus({ ...coverletter, professionalSkills });
        const timer = setTimeout(() => {
            setLoadings(false)
        }, 200);

        return () => clearTimeout(timer);
    }, [professionalSkills]);


    const onDragEnd = (result) => {

        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const items = reorder(
            professionalSkills.proSkills,
            result.source.index,
            result.destination.index
        );
        professionalSkills['proSkills'] = items;
        setProfessionalSkills({ ...professionalSkills })

    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onValuesChange = (val, all) => {
        setProfessionalSkills(all);
        onFinish();

    }

    const onFinish = values => {
        console.log('Received values of form:', values);
    };



    return (
        <div>
            <Row className="ant-pr-1 ant-pl-1 ant-mb-5">
                <Col xl={14} lg={24} md={24} sm={24} xs={24} className="" align="middle" offset={5}>
                    <div className="ant-mt-2"></div>
                    <Title level={2} className=" ant-mb-0">What are your professional skills?</Title>
                    <Text className="">(Includes hard skills / technical skills related to your profession)</Text>
                    <div className="ant-mt-2 cover-step-wrapper">

                        <Form
                            onFinish={onFinish}
                            layout={"vertical"}
                            wrapperCol={{
                                span: 24
                            }}
                            size={"middle"}
                            form={form}
                            name="skills"
                            onValuesChange={onValuesChange}
                        >
                            <div className="spin-loader" align="center">
                                {loadings && <Spin size="small" />}
                            </div>

                            {/* <Row>
                                <Col xl={24} lg={24} md={24} sm={24} xs={24} align={"left"}>

                                    <Form.Item
                                        label="Profession"
                                        name="profession"
                                        rules={[
                                            {
                                                type: "string",
                                                message: 'Please input your Profession!',
                                            },
                                            { max: 100, message: 'Profession must be maximum 100 characters.' },
                                        ]}
                                    >

                                        <AutoComplete
                                            dataSource={jobs}
                                            placeholder="Web Developer"
                                            filterOption={(inputValue, option) =>
                                                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                            }
                                        />

                                    </Form.Item>
                                </Col>
                            </Row> */}



                            <Divider orientation={"center"} className="ant-mt-1">Skils</Divider>
                            <Row className="ant-mt-3">
                                <Col xl={24} lg={24} md={24} sm={24} xs={24} align="center">

                                    <Form.List name="proSkills"

                                    >
                                        {(fields, { add, remove }) => (
                                            <>
                                                <DragDropContext onDragEnd={onDragEnd}>


                                                    <Droppable droppableId="item" >

                                                        {
                                                            (provided) => (
                                                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                                                    {fields.map((field, index, arr) => (

                                                                        <>

                                                                            <Draggable key={professionalSkills.proSkills[index] ? professionalSkills.proSkills[index].objid : 'temp'} draggableId={professionalSkills.proSkills[index] ? professionalSkills.proSkills[index].objid : 'temp'} index={index}>
                                                                                {provided => (
                                                                                    <div ref={provided.innerRef}
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}>
                                                                                        <div >
                                                                                            {
                                                                                                <Row key={field.key} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="">
                                                                                                    <Col xl={20} lg={20} md={20} sm={24} xs={24} className="" align="left">
                                                                                                        <Form.Item
                                                                                                            {...field}
                                                                                                            name={[field.name, 'skill']}
                                                                                                            fieldKey={[field.fieldKey, 'skill']}
                                                                                                            rules={[
                                                                                                                { type: "string", required: true, message: 'Skill required' },
                                                                                                                { max: 100, message: 'Skill must be maximum 100 characters.' },
                                                                                                            ]}
                                                                                                        >

                                                                                                            <AutoComplete
                                                                                                                dataSource={skills}
                                                                                                                placeholder="Skill"
                                                                                                                filterOption={(inputValue, option) =>
                                                                                                                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                                                                                }
                                                                                                                dropdownMatchSelectWidth={false}
                                                                                                            />
                                                                                                        </Form.Item>

                                                                                                    </Col>

                                                                                                    {/* <Col xl={2} lg={2} md={2} sm={12} xs={12} className="gutter-row" >
                                                                                                        {loadings === false && <DeleteTwoTone
                                                                                                            className="remove-btn ant-pt-1"
                                                                                                            twoToneColor="#f5222d"
                                                                                                            onClick={() => remove(field.name)} />}
                                                                                                    </Col> */}
                                                                                                    <Col xl={2} lg={2} md={2} sm={12} xs={12} className="gutter-row">
                                                                                                        <DragOutlined className="drag-btn ant-pt-1" />
                                                                                                    </Col>
                                                                                                </Row>

                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </Draggable>

                                                                        </>


                                                                    ))}
                                                                    { provided.placeholder}
                                                                </div>
                                                            )
                                                        }
                                                    </Droppable>
                                                    <div className="spin-loader" align="center">
                                                        {loadings && <Spin size="small" />}
                                                    </div>
                                                    <Form.Item className="add-btn" >
                                                        {/* <Button type="primary" onClick={() => add({ skill: '', objid: shortid.generate() })} icon={<PlusOutlined />} className="ant-mt-2">
                                                            Add Skill
                                                </Button> */}
                                                    </Form.Item>
                                                </DragDropContext>
                                            </>
                                        )}
                                    </Form.List>


                                </Col>
                                <Col xl={24} lg={24} md={24} sm={24} xs={24} className="" align="middle">
                                    <div className="cover-buttons-center ant-mt-3">
                                        <CoverletterBtn action={"setSkills"} text={"Continue"} />
                                    </div>
                                </Col>

                            </Row>
                        </Form>




                    </div>
                </Col>

            </Row>
        </div>
    )
}

export default ProfessionalSkills;