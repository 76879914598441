import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Row, Col, Typography, Button, Form, InputNumber, Checkbox, Spin } from 'antd';
import { CoverletterContext } from '../../../../contexts/CoverLetterContext';
import { SpinLoderContext } from '../../../../contexts/SpinLoderContext';
import CoverletterBtn from '../../../actions/CoverletterBtn';
const { Title, Text } = Typography;

const SkillSet = () => {

    const [form] = Form.useForm();
    const { spinloading, setSpinloading } = useContext(SpinLoderContext);
    const [skills, setSkills] = useState({ skills: [] });
    const [checkStatus, setCheckStatus] = useState(false);
    const { coverletter, setCoverletterStatus } = useContext(CoverletterContext);



    useEffect(() => {

        if (coverletter.skills) {
            setSkills(coverletter.skills);
            form.setFieldsValue(coverletter.skills);
        } else {
            form.setFieldsValue(skills);
        }
        const timer = setTimeout(() => {
            setSpinloading(false);
        }, 300);
        return () => clearTimeout(timer);
    }, []);


    useEffect(() => {
        if (skills.skills.length > 2) {
            setCheckStatus(true);
        }

        setSpinloading(true)
        form.setFieldsValue(skills)
        setCoverletterStatus({ ...coverletter, skills });
        const timer = setTimeout(() => {
            setSpinloading(false)
        }, 200);

        return () => clearTimeout(timer);
    }, [skills]);

    const options = [
        { value: "analytical", label: "Analytical" },
        { value: "languages", label: "Languages" },
        { value: "management", label: "Management" },
        { value: "persuasion", label: "Persuasion" },
        { value: "service", label: "Service" },
        { value: "organizational", label: "Organizational" },
        { value: "interpersonal", label: "Interpersonal" },
        { value: "technical", label: "Technical" },
        { value: "planning", label: "Planning" },
        { value: "leadership", label: "Leadership" },
        { value: "communication", label: "Communication" },
        { value: "research", label: "Research" },
        { value: "multitasking", label: "Multitasking" },
        { value: "critical thinking", label: "Critical thinking" },
        { value: "teamwork", label: "Teamwork" },
        { value: "creative thinking", label: "Creative thinking" },
        { value: "problem-Solving", label: "Problem-Solving" },
        { value: "writing", label: "Writing" }
    ]

    const onValuesChange = (val, all) => {

        setSkills(all);
        onFinish();
    }

    const onFinish = values => {

    };

    const ClearSkills = () => {
        setSkills({ skills: [] });
        setCheckStatus(false);
    }

    return (
        <div>
            <Row>
                <Col xl={22} lg={24} md={24} sm={24} xs={24} offset={1} align="middle">
                    <div className="spin-loader" align="center">
                        {spinloading && <Spin loading={true} size="small"  />}
                    </div>
                </Col>
            </Row>¸
            <Row className="ant-pr-1 ant-pl-1">
                <Col xl={24} lg={24} md={24} sm={24} xs={24} align="middle">
                    <div className="form-mt"></div>
                    <Title level={2} className="ant-mb-0">Your skill set</Title>
                    <Text className="text-muted">Choose two or three skills you have demonstrated in the past</Text>
                </Col>
                <Col xl={18} lg={24} md={24} sm={24} xs={24} align="middle" offset={3}>
                    <div className="ant-mt-10 cover-step-wrapper">
                        <Form
                            onFinish={onFinish}
                            layout={"vertical"}
                            wrapperCol={{
                                span: 24
                            }}
                            size={"middle"}
                            form={form}
                            name="education"
                            onValuesChange={onValuesChange}
                        >
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                                <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gutter-row ant-mr-auto ant-ml-auto skill-set cover-check" align="left">
                                    <Button onClick={ClearSkills} type="primary" size="small" className="ant-mb-3">Clear</Button>
                                    <Form.Item name="skills" >
                                        <Checkbox.Group options={options} disabled={checkStatus}>
                                        </Checkbox.Group>
                                    </Form.Item>


                                </Col>

                                <Col xl={14} lg={14} md={14} sm={24} xs={24} className="gutter-row ant-mr-auto ant-ml-auto" align="middle">
                                    <div className="cover-buttons-center">

                                        <CoverletterBtn action={"setSoftSkills"} text={"Continue"}></CoverletterBtn>
                                    </div>
                                </Col>

                            </Row>
                        </Form>


                    </div>
                </Col>

            </Row>
        </div >
    )
}

export default SkillSet;