import * as React from "react";
import stepDataHandler from "../handler/StepDataHandler";
import { getStoredStepData } from '../utils/StepsHelper';

export const StepContext = React.createContext();

export const StepProvider = props => {

    const { step, setStepStatus, setUnsetStepStatus } = stepDataHandler(
        getStoredStepData()
    );

    return (
        <StepContext.Provider value={{ step, setStepStatus, setUnsetStepStatus }}>
            {props.children}
        </StepContext.Provider>
    )

}