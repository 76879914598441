import * as React from "react";

import { getStoredTemplateData } from '../utils/TemplateHelper';

const TemplateDataHandler = () => {
    const [template, setSession] = React.useState(
        localStorage.getItem('template') ? JSON.parse(localStorage.getItem('template')) :
        getStoredTemplateData()
    );

    const setTemplateStatus = (template) => {
        window.localStorage.setItem("template", JSON.stringify(template));
        setSession(template);
    };

    const setUnsetTemplateStatus = () => {
        window.localStorage.clear();
        setSession({});
    };

    return {
        template,
        setTemplateStatus,
        setUnsetTemplateStatus
    };
};

export default TemplateDataHandler;