import React, { useContext, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import MainNav from '../../navigation/MainNav';
import { Row, Col, Button } from 'antd';
import CoverProgress from '../../navigation/cover-letter/CoverProgress';
import CoverProgressMobile from '../../navigation/cover-letter/CoverProgressMobile';
import WorkExperience from '../../cover-letter/cover-letter-form/experience/WorkExperience';
import Job from '../../cover-letter/cover-letter-form/experience/Job';
import ProfessionalSkills from '../../cover-letter/cover-letter-form/experience/ProfessionalSkills';
import WorkGaps from '../../cover-letter/cover-letter-form/experience/WorkGaps';
import AppMessages from '../../actions/AppMessages';
import { CoverletterContext } from '../../../contexts/CoverLetterContext';
import Experience from '../../cover-letter/cover-letter-form/experience/Experience';



const ExperienceCover = () => {

    const { coverletter } = useContext(CoverletterContext);


    return (
        <div>
            <MainNav />
            <AppMessages></AppMessages>
            <Row>
                <Col xl={6} lg={6} md={24} sm={24} xs={24} >
                    <CoverProgressMobile />
                    <CoverProgress />
                </Col>
                <Col xl={18} lg={18} md={24} sm={24} xs={24} className="ant-pt-10">

                    {coverletter.progress === "experience" &&
                        <Experience />
                    }
                    {coverletter.progress === "work-experience" &&
                        <WorkExperience />
                    }
                    {coverletter.progress === "job" &&
                        <Job />
                    }
                    {coverletter.progress === "skills" &&
                        <ProfessionalSkills />
                    }
                    {coverletter.progress === "work-gaps" &&
                        <WorkGaps />
                    }

                </Col>
            </Row>
        </div>
    )
}

export default ExperienceCover;