import React, { useContext } from 'react';
import moment from 'moment';
import cx from 'classnames'
import { ResumeContext } from '../../../../contexts/ResumeContext';
import { TemplateContext } from '../../../../contexts/TemplateContext';
import bootstrap from '../../../../css/bootstrap.module.css'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faAd, faBriefcase, faCertificate, faCubes, faShapes, faUser, faUserGraduate, faUserTie, faVectorSquare } from '@fortawesome/free-solid-svg-icons'
import style from './sapphire.module.css'
import ReactHtmlParser from 'react-html-parser';
const styles = { ...bootstrap, ...style }


const Sapphire = () => {

    const Resume = useContext(ResumeContext);
    const ResumeData = (Resume.resume);

    const TemplateCol = useContext(TemplateContext);
    const Color = (TemplateCol.template.resumeColor);

    console.log(ResumeData.personal.profileLinks,"personal")
    return (
        <div>
            <div className={cx(styles['sapphire'], styles[Color])}>
                {ResumeData.personal ?
                    <>

                        <div id="header">
                            <div className={styles.header}>
                                <div className={cx(styles['name-profession'])}>
                                    <h1 className={cx(styles['full-name'], styles['mb-0'])}>
                                        {ResumeData.personal.firstName} {ResumeData.personal.lastName}
                                    </h1>
                                    <h3 className={cx(styles['profession'])}>
                                        {ResumeData.personal.profession}
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className={cx(styles['header-space'])}></div>
                        <div className={styles.clear}></div>
                    </>
                    : ""}



                {(ResumeData.summary == `<p>&nbsp;</p>` || ResumeData.summary == `<p><br></p>` || ResumeData.summary.length === 0) ? <></> :
                    <div id="summary">
                        <div className={cx(styles["summary"], styles["description"])}>
                            {ReactHtmlParser(ResumeData.summary)}
                        </div>
                    </div>
                }

                <div className={cx(styles['d-flex'], styles['mt-4'], styles['pt-2'])}>
                    <div className={cx(styles["w-small"])}>
                        {ResumeData.personal.address || ResumeData.personal.phone || ResumeData.personal.email ?
                            <div id="contact">
                                <div className={cx(styles["contact"])}>
                                    <div className={cx(styles['d-flex'], styles['mb-2'], styles['heading-border'])}>
                                        <div className={cx(styles["icon"])}>
                                            {/* <FontAwesomeIcon icon={faUser} /> */}
                                        </div>
                                        <div>
                                            <h4 className={cx(styles['font-weight-bold'], styles['heading'])}>Personal Info</h4>
                                        </div>
                                    </div>
                                    {ResumeData.personal.address ?
                                        <>
                                            <p className={cx(styles['font-weight-bold'], styles['mb-0'])}>Address</p>
                                            <p className={cx(styles['mb-3'])}>{ResumeData.personal.address}</p>
                                        </> : ""
                                    }

                                    {ResumeData.personal.phone ?
                                        <>
                                            <p className={cx(styles['font-weight-bold'], styles['mb-0'])}>Phone</p>
                                            <p className={cx(styles['mb-3'])}>{ResumeData.personal.phone}</p>
                                        </> : ""
                                    }
                                    {ResumeData.personal.email ?
                                        <>
                                            <p className={cx(styles['font-weight-bold'], styles['mb-0'])}>Email</p>
                                            <p className={cx(styles['mb-3'])}>{ResumeData.personal.email}</p>
                                        </> : ""
                                    }
                                </div>

                                {(ResumeData.personal.profileLinks && ResumeData.personal.profileLinks.length) ?
                                    <div className={cx(styles["social"])}>
                                        {ResumeData.personal.profileLinks.map((ResumeData, index) => (
                                            <div key={index}>
                                                <p className={cx(styles['font-weight-bold'], styles['mb-0'])} key={'website' + ResumeData.website}>{ResumeData.website}</p>
                                                <a href={ResumeData.websiteLink} rel="noopener noreferrer" target="_blank">
                                                    <p className={cx(styles["mb-1"])} key={'websiteLink' + ResumeData.websiteLink}>{ResumeData.websiteLink}</p>
                                                
                                                </a>
                                            </div>
                                        ))}
                                    </div> : ""
                                }
                                <div className={styles.space}></div>
                                <div className={styles.clear}></div>
                            </div> : ""
                        }

                        {(ResumeData.hardskills && ResumeData.hardskills.length && ResumeData.hardskills[0].skill.length) ?
                            <div id="skills">
                                <div className={cx(styles["skills"])}>
                                    <div className={cx(styles['d-flex'], styles['mb-2'], styles['heading-border'])}>
                                        <div className={cx(styles["icon"])}>
                                            {/* <FontAwesomeIcon icon={faShapes} /> */}
                                        </div>
                                        <div>
                                            <h4 className={cx(styles['font-weight-bold'], styles['heading'])}>Hard Skills</h4>
                                        </div>
                                    </div>
                                </div>
                                {ResumeData.hardskills.map((ResumeData, index) => (
                                    <div className={cx(styles["mb-2"])} key={index}>
                                        <p className={cx(styles["mb-0"])} key={'skill' + ResumeData.skill}>{ResumeData.skill}</p>

                                        <div key={'rating' + ResumeData.rating}>
                                            {[...Array(ResumeData.rating)].map((e, index) => (
                                                <div className={cx(styles["dot"], styles['mr-2'])} key={index}></div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                                <div className={styles.space}></div>
                                <div className={styles.clear}></div>
                            </div>
                            : ""
                        }

                        {(ResumeData.softskills && ResumeData.softskills.length && ResumeData.softskills[0].skill.length) ?
                            <div id="skills">
                                <div className={cx(styles["skills"])}>
                                    <div className={cx(styles['d-flex'], styles['mb-2'], styles['heading-border'])}>
                                        <div className={cx(styles["icon"])}>
                                            {/* <FontAwesomeIcon icon={faCubes} /> */}
                                        </div>
                                        <div>
                                            <h4 className={cx(styles['font-weight-bold'], styles['heading'])}>Soft Skills</h4>
                                        </div>
                                    </div>
                                </div>
                                {ResumeData.softskills.map((ResumeData, index) => (
                                    <div className={cx(styles["mb-2"])} key={index}>
                                        <p className={cx(styles["mb-0"])} key={'skill' + ResumeData.skill}>{ResumeData.skill}</p>
                                    </div>
                                ))}
                                <div className={styles.space}></div>
                                <div className={styles.clear}></div>
                            </div>
                            : ""
                        }


                        {(ResumeData.languages && ResumeData.languages.length && ResumeData.languages[0].language.length) ?
                            <div id="languages">
                                <div className={cx(styles["languages"])}>
                                    <div className={cx(styles['d-flex'], styles['mb-2'], styles['heading-border'])}>
                                        <div className={cx(styles["icon"])}>
                                            {/* <FontAwesomeIcon icon={faAd} /> */}
                                        </div>
                                        <div>
                                            <h4 className={cx(styles['font-weight-bold'], styles['heading'])}>Languages</h4>
                                        </div>
                                    </div>
                                    {ResumeData.languages.map((ResumeData, index) => (
                                        <div key={index} className={cx(styles['mb-2'])}>
                                            <p className={cx(styles['mb-0'], styles['font-weight-normal'])} key={'language' + ResumeData.language}>{ResumeData.language}</p>
                                            <p key={'language' + ResumeData.proficiencyLevel}>{ResumeData.proficiencyLevel}</p>
                                        </div>
                                    ))}
                                </div>
                                <div className={styles.space}></div>
                                <div className={styles.clear}></div>
                            </div> : ""
                        }

                    </div>


                    <div className={cx(styles["w-large"], styles['pl-5'])}>

                        {(ResumeData.experiences && ResumeData.experiences.length && (ResumeData.experiences[0].jobTitle || ResumeData.experiences[0].employer || ResumeData.experiences[0].city)) ?
                            <div id="experience">
                                <div className={cx(styles["experience"])}>
                                    <div className={cx(styles['d-flex'], styles['mb-2'], styles['heading-border'])}>
                                        <div className={cx(styles["icon"])}>
                                            {/* <FontAwesomeIcon icon={faBriefcase} /> */}
                                        </div>
                                        <div>
                                            <h4 className={cx(styles['font-weight-bold'], styles['heading'])}>Experience</h4>
                                        </div>
                                    </div>
                                    {ResumeData.experiences.map((ResumeData, index) => (
                                        <div className={cx(styles['section'])} key={index}>
                                            <div className={cx(styles['w-100'])}>
                                                <p className={cx(styles['font-weight-normal'], styles['text-muted'], styles['mb-1'])}>
                                                    {ResumeData.startDate === null ? "" : <span key={'startDate' + ResumeData.startDate}>{moment(ResumeData.startDate).format('MMM YYYY')}</span>}
                                                    {ResumeData.startDate && (ResumeData.endDate || ResumeData.currentlyWorking === true) ? <span className={cx(styles['mx-1'])}>-</span> : ""}
                                                    {ResumeData.currentlyWorking !== true ? (ResumeData.endDate === null ? "" : <span key={'endDate' + ResumeData.endDate}>{moment(ResumeData.endDate).format('MMM YYYY')}</span>) : "Present"}
                                                </p>
                                                <p className={cx(styles['font-weight-bold'], styles['mb-0'])} key={'jobTitle' + ResumeData.jobTitle}>{ResumeData.jobTitle}</p>
                                                <p className={cx(styles['mb-1'])}>
                                                    <a href={ResumeData.website} rel="noopener noreferrer" target="_blank">
                                                        <span key={'employer' + ResumeData.employer}>{ResumeData.employer}</span>
                                                    </a>
                                                    {ResumeData.city && ResumeData.employer ? <span className={cx(styles['mx-1'])}>,</span> : ""}
                                                    <span key={'city' + ResumeData.city}>{ResumeData.city}</span>
                                                </p>

                                                {(ResumeData.summary == `<p>&nbsp;</p>` || ResumeData.summary == `<p><br></p>`) ? <></> :
                                                    <div className={cx(styles['description'], styles['mt-2'])} key={'summaryHtml' + ResumeData.summary}>
                                                        {ReactHtmlParser(ResumeData.summary)}
                                                    </div>
                                                }
                                            </div>
                                            <div className={cx(styles['space-end'])}></div>
                                            <div className={cx(styles['clear'])}></div>
                                        </div>
                                    ))}
                                    <div className={cx(styles['space-end'])}></div>
                                </div>
                            </div> : ""
                        }

                        {(ResumeData.educations && ResumeData.educations.length && (ResumeData.educations[0].qualification || ResumeData.educations[0].field || ResumeData.educations[0].city)) ?
                            <div id="education">
                                <div className={cx(styles["education"])}>
                                    <div className={cx(styles['d-flex'], styles['mb-2'], styles['heading-border'])}>
                                        <div className={cx(styles["icon"])}>
                                            {/* <FontAwesomeIcon icon={faUserGraduate} /> */}
                                        </div>
                                        <div>
                                            <h4 className={cx(styles['font-weight-bold'], styles['heading'])}>Education</h4>
                                        </div>
                                    </div>
                                    {ResumeData.educations.map((ResumeData, index) => (
                                        <div className={cx(styles['section'])} key={index}>
                                            <div className={cx(styles['w-100'])}>
                                                <p className={cx(styles['font-weight-normal'], styles['text-muted'], styles['mb-1'])}>
                                                    {ResumeData.startDate === null ? "" : <span key={'startDate' + ResumeData.startDate}>{moment(ResumeData.startDate).format('MMM YYYY')}</span>}
                                                    {ResumeData.startDate && (ResumeData.endDate || ResumeData.stillStudying === true) ? <span className={cx(styles['mx-1'])}>-</span> : ""}
                                                    {ResumeData.stillStudying !== true ? (ResumeData.endDate === null ? "" : <span key={'endDate' + ResumeData.endDate}>{moment(ResumeData.endDate).format('MMM YYYY')}</span>) : "Present"}
                                                </p>
                                                <p className={cx(styles['font-weight-bold'], styles['mb-0'])}>
                                                    <span>{ResumeData.qualification}</span>
                                                    {ResumeData.qualification && ResumeData.field ? <span className={cx(styles['mx-1'])}>-</span> : ""}
                                                    <span>{ResumeData.field}</span>
                                                </p>
                                                <p className={cx(styles['mb-1'])}>
                                                    <a href={ResumeData.website} rel="noopener noreferrer" target="_blank">
                                                        <span>{ResumeData.institute}</span>
                                                    </a>
                                                    {ResumeData.institute && ResumeData.city ? <span className={cx(styles['mx-1'])}>,</span> : ""}
                                                    <span>{ResumeData.city}</span></p>
                                                {(ResumeData.summary == `<p>&nbsp;</p>` || ResumeData.summary == `<p><br></p>`) ? <></> :
                                                    <div className={cx(styles['description'], styles['mt-2'])} key={'summaryHtml' + ResumeData.summary}>
                                                        {ReactHtmlParser(ResumeData.summary)}
                                                    </div>
                                                }
                                            </div>
                                            <div className={cx(styles['space-end'])}></div>
                                            <div className={cx(styles['clear'])}></div>
                                        </div>
                                    ))}
                                    <div className={cx(styles['space-end'])}></div>
                                </div>
                            </div> : ""
                        }


                        {(ResumeData.certificates && ResumeData.certificates.length && ResumeData.certificates[0].certificateName.length) ?
                            <div id="certification">

                                <div className={cx(styles['certification'])}>
                                    <div className={cx(styles['d-flex'], styles['mb-2'], styles['heading-border'])}>
                                        <div className={cx(styles["icon"])}>
                                            {/* <FontAwesomeIcon icon={faCertificate} /> */}
                                        </div>
                                        <div>
                                            <h4 className={cx(styles['font-weight-bold'], styles['heading'])}>Certification</h4>
                                        </div>
                                    </div>
                                    {ResumeData.certificates.map((ResumeData, index) => (
                                        <div className={cx(styles['section'])}>
                                            <div className={cx(styles['w-100'])}>
                                                {ResumeData.issueDate != null ? <p className={cx(styles['font-weight-normal'], styles['text-muted'], styles['mb-1'])}>
                                                    <span key={'issueDate' + ResumeData.issueDate}>{moment(ResumeData.issueDate).format('DD MMM YYYY')}</span>
                                                </p> : ""}
                                                <p className={cx(styles['mb-0'], styles['font-weight-bold'])} key={'certificateName' + ResumeData.certificateName}>{ResumeData.certificateName}</p>
                                                <p className={cx(styles['mb-0'])} key={'organization' + ResumeData.organization}> {ResumeData.organization}</p>
                                                <p className={cx(styles['mb-0'])} key={'certificateNo' + ResumeData.certificateNo}>{ResumeData.certificateNo}</p>
                                                <a href={ResumeData.url} rel="noopener noreferrer" target="_blank">
                                                    <p className={cx(styles['mb-0'], styles['text-muted'])} key={'url' + ResumeData.url}>{ResumeData.url}</p>
                                                </a>

                                            </div>
                                            <div className={cx(styles['space-end'])}></div>
                                            <div className={cx(styles['clear'])}></div>
                                        </div>
                                    ))}
                                </div>
                                <div className={cx(styles['space-end'])}></div>
                            </div>
                            : ""}

                        {(ResumeData.customs && ResumeData.customs.length && ResumeData.customs[0].sectionTitle.length) ?
                            <div id="custom-section">
                                {ResumeData.customs.map((ResumeData, index) => (
                                    <div className={cx(styles['custom-section'])}>
                                        <div className={cx(styles['d-flex'], styles['mb-2'], styles['heading-border'])}>
                                            <div className={cx(styles["icon"])}>
                                                {/* <FontAwesomeIcon icon={faVectorSquare} /> */}
                                            </div>
                                            <div>
                                                <h4 className={cx(styles['font-weight-bold'], styles['heading'])} key={'sectionTitle' + ResumeData.sectionTitle}>{ResumeData.sectionTitle}</h4>
                                            </div>
                                        </div>

                                        <div className={cx(styles['section'])}>
                                            <div className={cx(styles['w-100'])}>
                                                <div>
                                                    {(ResumeData.summary == `<p>&nbsp;</p>` || ResumeData.summary == `<p><br></p>`) ? <></> :
                                                        <div className={cx(styles['description'], styles['mt-2'])} key={'summaryHtml' + ResumeData.summary}>
                                                            {ReactHtmlParser(ResumeData.summary)}
                                                        </div>}
                                                </div>
                                            </div>
                                            <div className={cx(styles['space-end'])}></div>
                                            <div className={cx(styles['clear'])}></div>
                                        </div>
                                        <div className={cx(styles['space-end'])}></div>
                                    </div>

                                ))}
                            </div>
                            : ""
                        }

                        {(ResumeData.references && ResumeData.references.length && ResumeData.references[0].fullName.length) ?
                            <div id="references">
                                <div className={cx(styles['references'])}>
                                    <div className={cx(styles['d-flex'], styles['mb-2'], styles['heading-border'])}>
                                        <div className={cx(styles["icon"])}>
                                            {/* <FontAwesomeIcon icon={faUserTie} /> */}
                                        </div>
                                        <div>
                                            <h4 className={cx(styles['font-weight-bold'], styles['heading'])}>References</h4>
                                        </div>
                                    </div>
                                    <div className={cx(styles['section'])}>
                                        <div className={cx(styles['w-100'])}>
                                            <div className={cx(styles["d-flex"])}>
                                                {ResumeData.references.map((ResumeData, index) => (
                                                    <div className={cx(styles['section'], styles['w-50'])}>
                                                        <p className={cx(styles['mb-0'], styles['font-weight-bold'])} key={"fullName" + ResumeData.fullName}>{ResumeData.fullName}</p>
                                                        <p key={"position" + ResumeData.occupation} className={cx(styles['mb-0'])}>{ResumeData.occupation}</p>
                                                        <p key={"company" + ResumeData.company} className={cx(styles['mb-0'])}>{ResumeData.company}</p>
                                                        <p key={"email" + ResumeData.email} className={cx(styles['mb-0'])}>{ResumeData.email}</p>
                                                        <p key={"phone" + ResumeData.phone} className={cx(styles['mb-0'])}>{ResumeData.phone}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            : ""
                        }

                    </div>
                </div>
            </div>
        </div >

    )
}

export default Sapphire;