import * as React from "react";
import CoverletterBodyDataHandler from "../handler/CoverletterBodyDataHandler";

export const CoverletterBodyContext = React.createContext();

export const CoverletterBodyProvider = props => {
  
    const { coverletterBody, setCoverletterBodyStatus, setUnsetCoverletterBodyStatus } = CoverletterBodyDataHandler();

    return (
        <CoverletterBodyContext.Provider value={{ coverletterBody, setCoverletterBodyStatus, setUnsetCoverletterBodyStatus  }}>
             {props.children}
        </CoverletterBodyContext.Provider>
    )

}