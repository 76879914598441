import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Steps } from 'antd';
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

const { Step } = Steps;

const ResumeProgress = () => {

    const location = useLocation();
    const [step, setStep] = useState(0)

    useEffect(() => {
        setStep(getCurrentStep(location.pathname));

    }, [])


    const getCurrentStep = (path) => {

        switch (path) {
            case '/resume/header':
                return 0;
            case '/resume/experience':
                return 1;
            case '/resume/education':
                return 2;
            case '/resume/skills':
                return 3;
            case '/resume/summary':
                return 4;
            case '/resume/finalize':
                return 5;

            default:
                return 0;
        }

    }
    // const location = useLocation();
    return (
        <>
            { step !== 5 &&
                <div className="resume-progress">
                    <Row justify="center">
                        <Col span={24} className="resume-progress-steps ant-mr-3" align="middle">

                            <div className="ant-mb-3 ant-pt-3">
                                <Steps
                                    progressDot
                                    direction="horizontal"
                                    current={step}
                                    size="small"
                                    className="resume-progress-step"
                                >
                                    <Step title={<Link to="/resume/header">Header</Link>} className="ant-pb-1" />
                                    <Step title={<Link to="/resume/experience" >Experience</Link>} className="ant-pb-1 " />
                                    <Step title={<Link to="/resume/education" >Education</Link>} className="ant-pb-1" />
                                    <Step title={<Link to="/resume/skills" >Skills</Link>} className="ant-pb-1" />
                                    <Step title={<Link to="/resume/summary" >Summary</Link>} className="ant-pb-1" />
                                    <Step title={<Link to="/resume/finalize" >Finalize</Link>} className="ant-pb-1" />
                                </Steps>
                            </div>
                        </Col>
                    </Row>
                </div>
            }
        </>
    )
}

export default ResumeProgress;