import * as React from "react";

import { getStoredCoverletterData } from '../utils/CoverletterHelper';

const CoverletterDataHandler = () => {
    const [coverletter, setSession] = React.useState(
        localStorage.getItem('coverletter') ? JSON.parse(localStorage.getItem('coverletter')) :
        getStoredCoverletterData()
    );

    const setCoverletterStatus = (coverletter) => {
        window.localStorage.setItem("coverletter", JSON.stringify(coverletter));
        setSession(coverletter);
    };

    const setUnsetCoverletterStatus = () => {
        window.localStorage.clear();
        setSession({});
    };

    return {
        coverletter,
        setCoverletterStatus,
        setUnsetCoverletterStatus
    };
};

export default CoverletterDataHandler;