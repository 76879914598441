import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Row, Col, Form, Input, Button, Skeleton, Tabs, Rate, AutoComplete, Spin } from 'antd';
import { DeleteTwoTone, DragOutlined, PlusOutlined, StarTwoTone } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import shortid from 'shortid';
import debounce from 'lodash.debounce';

import { ResumeContext } from '../../../contexts/ResumeContext';


const HardSkills = () => {

    const [loading, setLoading] = useState(true);
    const [loadings, setLoadings] = useState(false);
    const { resume, setResumeStatus } = useContext(ResumeContext);
    const [form] = Form.useForm();

    const [hardskills, setHardskills] = useState({
        hardskills: [
            { skill: "", rating: 0, objid: shortid.generate() }
        ]
    });


    const [skills, setSkills] = useState([
        'A/B testing',
        'Academic writing',
        'Acrobat',
        'AdWords',
        'Agile software',
        'Aritic PinPoint',
        'Automated Billing Systems',
        'Basic writing',
        'Budgeting',
        'Business knowledge',
        'Business writing reports',
        'C#',
        'C++',
        'CAD',
        'Color theory',
        'Content management',
        'Copywriting',
        'Corel Draw',
        'Creative writing',
        'Creativity',
        'CRM Platforms',
        'CRO',
        'CSS',
        'Data analysis',
        'Data and metrics interpreting',
        'Data engineering',
        'Data mining',
        'Data presentation',
        'Data visualization',
        'Database management',
        'Diagnostics',
        'Editing',
        'Email',
        'Email marketing and automation',
        'Email writing',
        'Enterprise systems',
        'Facebook Paid Ads',
        'Finance',
        'Financial modeling',
        'Forecasting',
        'Free Hand',
        'Funnel management',
        'Google Analytics',
        'Google Drive',
        'Google Search Console',
        'Graphic design',
        'Graphics',
        'Hiring',
        'HTML',
        'HubSpot',
        'Illustrator',
        'InDesign',
        'Infusionsoft',
        'iOS/Swift',
        'Java',
        'Javascript',
        'Journalism',
        'Kanban',
        'Layout',
        'Lean manufacturing',
        'Letter writing',
        'Linear regression',
        'Logistics',
        'MS Office',
        'Multivariate analysis',
        'MySQL',
        'Negotiating',
        'New business development',
        'Note-taking',
        'Office management skills',
        'ONTRAPORT',
        'paid social media advertising',
        'Payment processing',
        'Performance tracking',
        'Persuasion',
        'Photoshop',
        'PHP',
        'Planning',
        'PPC',
        'Presentation writing',
        'Presentations/Slideshows',
        'Press releases',
        'Print design',
        'Project lifecycle management',
        'Project management',
        'Project scheduling',
        'Proposal writing',
        'Prototyping',
        'Python',
        'Quickbooks',
        'Reporting',
        'Research',
        'Resource management',
        'Ruby on Rails',
        'Scrum management',
        'SEO',
        'SEM',
        'Sketching',
        'Slideshows',
        'Social media',
        'Social media marketing',
        'Spreadsheets',
        'SQL',
        'STEM skills',
        'Storytelling',
        'Strategic planning',
        'Theorizing',
        'Troubleshooting',
        'Typing',
        'Typography',
        'UX Design',
        'UX research',
        'UX/UI design',
        'Visual communication',
        'Web',
        'Workflow development',
        'WPM',
        'Writing'
    ]);


    const onFinish = values => {
        console.log('Received values of form:', values);
    };

    const onValuesChange = (val, all) => {

        setHardskills(all);

    };

    useEffect(() => {

        if (resume.hardskills && resume.hardskills.length > 0) {
            setHardskills({ hardskills: resume.hardskills });
            form.setFieldsValue(hardskills);
        } else {
            form.setFieldsValue(hardskills);
        }
        const timer = setTimeout(() => {
            setLoading(false);
        }, 300);
        return () => clearTimeout(timer);

    }, []);

    useEffect(() => {
        setLoadings(true)
        const items = hardskills.hardskills.filter(item => {
            if (item.skill !== undefined)
                return item;
        })
        hardskills['hardskills'] = items;

        form.setFieldsValue(hardskills)
        setResumeStatus({ ...resume, ...hardskills });


        const timer = setTimeout(() => {
            setLoadings(false)
        }, 200);

    }, [hardskills]);



    const onDragEnd = (result) => {

        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const items = reorder(
            hardskills.hardskills,
            result.source.index,
            result.destination.index
        );

        hardskills['hardskills'] = items;
        setHardskills({ ...hardskills })

    }

    const reorder = (list, startIndex, endIndex) => {

        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    return (
        <div className="small-forms">
            <Form
                onFinish={onFinish}
                onValuesChange={onValuesChange}
                form={form}
                layout={"vertical"}
                wrapperCol={{
                    span: 24
                }}
                size={"middle"}
            >
                <div className="spin-loader" align="center">
                    {loadings && <Spin size="small" />}
                </div>

                <Form.List name="hardskills">
                    {(fields, { add, remove }) => (
                        <>

                            <DragDropContext onDragEnd={onDragEnd}>

                                <Droppable droppableId="item" >
                                    {
                                        (provided) => (
                                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                                {fields.map((field, index, arr) => (

                                                    <>
                                                        <Draggable key={hardskills.hardskills[index] ? hardskills.hardskills[index].objid : 'temp'} draggableId={hardskills.hardskills[index] ? hardskills.hardskills[index].objid : 'temp'} index={index}>

                                                            {provided => (
                                                                <div ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}>


                                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="skill-section">
                                                                        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gutter-row ">
                                                                            <Form.Item
                                                                                {...field}

                                                                                name={[field.name, 'skill']}
                                                                                fieldKey={[field.fieldKey, 'skill']}
                                                                                rules={[
                                                                                    {
                                                                                        type: "string",
                                                                                        message: 'Please input Skill!',
                                                                                    },
                                                                                    { max: 100, message: 'Skill must be maximum 100 characters.' }
                                                                                ]}
                                                                            >
                                                                                <AutoComplete placeholder="MS Office" dataSource={skills}
                                                                                    filterOption={(inputValue, option) =>
                                                                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                                                    }
                                                                                >

                                                                                </AutoComplete>
                                                                            </Form.Item>

                                                                        </Col>
                                                                        <Col xl={8} lg={8} md={8} sm={24} xs={24} className="gutter-row">

                                                                            <Form.Item
                                                                                {...field}
                                                                                name={[field.name, 'rating']}
                                                                                fieldKey={[field.fieldKey, 'rating']}
                                                                            >
                                                                                <Rate
                                                                                    defaultValue={0}
                                                                                    autoFocus={"true"}
                                                                                    count="5"

                                                                                />
                                                                            </Form.Item>
                                                                        </Col>

                                                                        <Col xl={2} lg={2} md={2} sm={12} xs={12} className="gutter-row ant-pr-0" align="right">
                                                                            {loadings === false && <DeleteTwoTone
                                                                                className="remove-btn ant-mt-1"
                                                                                twoToneColor="#f5222d"
                                                                                onClick={() => remove(field.name)}
                                                                            />}
                                                                        </Col>
                                                                        <Col xl={2} lg={2} md={2} sm={12} xs={12} className="gutter-row" align="left">
                                                                            <DragOutlined className="drag-btn ant-mt-1" />
                                                                        </Col>
                                                                    </Row>

                                                                </div>

                                                            )}

                                                        </Draggable>
                                                    </>


                                                ))}
                                                <div className="spin-loader" align="center">
                                                    {loadings && <Spin size="small" />}
                                                </div>
                                                {provided.placeholder}
                                            </div>
                                        )
                                    }
                                </Droppable>
                                <Form.Item className=" add-btn add-btn-align-skill">
                                    <Button type="primary" loading={loadings} onClick={() => add({ skill: "", rating: 0, objid: shortid.generate() })} icon={<PlusOutlined />}>
                                        Add Skill
                                                </Button>
                                </Form.Item>
                            </DragDropContext>


                        </>
                    )}
                </Form.List>

            </Form>
        </div>
    )

}

export default HardSkills;