import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Typography, Button, Form, Input, Select, Spin, InputNumber } from 'antd';
import CoverletterBtn from '../../../actions/CoverletterBtn';
import { SpinLoderContext } from '../../../../contexts/SpinLoderContext';
import { CoverletterContext } from '../../../../contexts/CoverLetterContext';

const { Title } = Typography;

const { Option } = Select;

const EduInfo = () => {

    const { spinloading, setSpinloading } = useContext(SpinLoderContext);
    const [form] = Form.useForm();
    const { coverletter, setCoverletterStatus } = useContext(CoverletterContext);
    const [higherEducation, setHigherEducation] = useState({ 'type': '', 'institute': "", 'field': '', 'gpa': '' });


    useEffect(() => {

        if (coverletter.higherEducation) {
            setHigherEducation(coverletter.higherEducation);
            form.setFieldsValue(coverletter.higherEducation);
        } else {
            form.setFieldsValue(higherEducation);
        }
        const timer = setTimeout(() => {
            setSpinloading(false);
        }, 300);
        return () => clearTimeout(timer);
    }, []);


    useEffect(() => {

        setSpinloading(true)
        form.setFieldsValue(higherEducation)
        setCoverletterStatus({ ...coverletter, higherEducation });
        const timer = setTimeout(() => {
            setSpinloading(false)
        }, 200);

        return () => clearTimeout(timer);
    }, [higherEducation]);


    const onValuesChange = (val, all) => {
        setHigherEducation(all);
        onFinish();
    }

    const onFinish = values => {

    };

    return (
        <div>
            <Row>
                <Col xl={22} lg={24} md={24} sm={24} xs={24} offset={1} align="middle">
                    <div className="spin-loader" align="center">
                        {spinloading && <Spin loading={true} size="small"  />}
                    </div>
                </Col>
            </Row>
            <Row className="ant-pr-1 ant-pl-1">
                <Col xl={24} lg={24} md={24} sm={24} xs={24} align="middle">
                    <div className="ant-mt-5"></div>
                    <Title level={2} className="ant-mb-0">Education Information</Title>
                </Col>
                <Col xl={18} lg={24} md={24} sm={24} xs={24} align="middle" offset={3}>
                    <div className="ant-mt-10 cover-step-wrapper">
                        <Form
                            onFinish={onFinish}
                            layout={"vertical"}
                            wrapperCol={{
                                span: 24
                            }}
                            size={"middle"}
                            form={form}
                            name="education"
                            onValuesChange={onValuesChange}
                        >
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >

                                <Col xl={8} lg={8} md={8} sm={24} xs={24} className="gutter-row ant-mr-auto ant-ml-auto" align="left">
                                    <Form.Item
                                        label="Institution Type"
                                        name={'type'}
                                        rules={[
                                            {
                                                type: "string",
                                                required: true,
                                                message: 'Please select Institution type',
                                            },
                                            { max: 100, message: 'Institution must be maximum 100 characters.' },
                                        ]}
                                    >
                                        <Select
                                            defaultValue={higherEducation.type}
                                            style={{ width: "100%" }}
                                            placeholder="Select Reason"
                                        >


                                            <Option value="college">College</Option>
                                            <Option value="university">University</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xl={16} lg={16} md={16} sm={24} xs={24} className="gutter-row ant-mr-auto ant-ml-auto" align="left">
                                    <Form.Item
                                        label="Name"
                                        name="institute"
                                        rules={[
                                            {
                                                type: "string",
                                                required: true,
                                                message: 'Please input your college or university',
                                            }, { max: 100, message: 'College or University must be maximum 100 characters.' },
                                        ]}
                                    >
                                        <Input
                                            placeholder={"Harvard Unversity"}
                                        />
                                    </Form.Item>
                                </Col>

                            </Row>


                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >

                                <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gutter-row ant-mr-auto ant-ml-auto" align="left">
                                    <Form.Item
                                        label="Field of study"
                                        name="field"
                                        rules={[
                                            {
                                                type: "string",
                                                message: 'Please input your field',
                                            }, { max: 100, message: 'Field must be maximum 100 characters.' },
                                        ]}
                                    >
                                        <Input
                                            placeholder={"Computer Science"}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            {coverletter.education === 'yes' &&
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >


                                    <Col xl={18} lg={18} md={18} sm={24} xs={24} className="gutter-row " align="left">
                                        <Form.Item
                                            label="Grade point average"
                                            name="gpa"
                                            rules={[
                                                {
                                                    type: "number",
                                                    message: 'Insert valid GPA value',
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                step={0.1}
                                                min={0} max={10}
                                                placeholder={"3.0"}
                                            />
                                        </Form.Item>
                                        <span className="ant-typography text-muted">Only write your GPA if it's above 3.0</span>
                                    </Col>

                                </Row>
                            }

                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                                <Col xl={22} lg={24} md={24} sm={24} xs={24} className="main-m-top" align="middle" offset={1}>
                                    <div className="cover-buttons">
                                        <CoverletterBtn action={"eduInfo"} text={"Continue"}></CoverletterBtn>

                                    </div>
                                </Col>

                            </Row>

                        </Form>


                    </div>
                </Col>

            </Row>
        </div>
    )
}

export default EduInfo;