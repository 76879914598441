import React, { useContext } from 'react';
import { useHistory } from "react-router-dom"
import { AppstoreAddOutlined, BulbOutlined, CloseCircleOutlined, ContactsOutlined, ContainerOutlined, FileProtectOutlined, FontColorsOutlined, ReadOutlined, ShoppingOutlined, TeamOutlined } from '@ant-design/icons';
import { Col, Divider, Menu, Row, Popconfirm, message } from 'antd';
import CertificatesForm from './CertificatesForm';
import CustomsForm from './CustomsForm';
import EducationForm from './EducationForm';
import ExperienceForm from './ExperienceForm';
import HeaderForm from './HeaderForm';
import LanguageForm from './LanguageForm';
import ReferencesForm from './ReferencesForm';
import SkillsSkeleton from './SkillsSkeleton';
import SummaryForm from './SummaryForm';
import { ResumeContext } from '../../../contexts/ResumeContext';

const FinalizeForm = () => {
    const { setUnsetResumeStatus } = useContext(ResumeContext);
    const history = useHistory();

    const handleClick = (e) => {
        const navid = document.getElementById(e.key);
        navid.scrollIntoView();

    }

    const confirm = () => {
        setUnsetResumeStatus();
        history.push(`/resume/header`);
        message.success('Resume Cleared!');
    }

    return (
        <div>
            <Row>
                <Col xl={1} lg={1} md={1} sm={0} xs={0} className="finalize" align="middle">
                    <div className="side-navigation">
                        <Menu
                            defaultSelectedKeys={['0']}
                            theme="light"
                            inlineCollapsed={true}
                            style={{ minHeight: "90vh", textAlign: "center" }}
                            onClick={handleClick}

                        >

                            <Menu.Item
                                key="personal"
                                icon={<ContactsOutlined className="side-nav-icon ant-mb-0" />}
                                className="ant-mt-6 ant-mb-0"
                                style={{ height: "50px" }}
                            >
                            </Menu.Item>
                            <li className="ant-mb-2 side-nav-title"><strong><small>Personal</small></strong></li>

                            <Menu.Item
                                key="summary"
                                icon={<ContainerOutlined className="side-nav-icon ant-mb-0" />}
                                className="ant-mb-0"
                                style={{ height: "50px" }}
                            >
                            </Menu.Item>
                            <li className="ant-mb-2 side-nav-title"><strong><small>Summary</small></strong></li>

                            <Menu.Item
                                key="experience"
                                icon={<ShoppingOutlined className="side-nav-icon ant-mb-0" />}
                                className="ant-mb-0"
                                style={{ height: "50px" }}
                            >
                            </Menu.Item>
                            <li className="ant-mb-2 side-nav-title"><strong><small>Experience</small></strong></li>

                            <Menu.Item
                                key="education"
                                icon={<ReadOutlined className="side-nav-icon ant-mb-0" />}
                                className="ant-mb-0"
                                style={{ height: "50px" }}
                            >
                            </Menu.Item>
                            <li className="ant-mb-2 side-nav-title"><strong><small>Education</small></strong></li>

                            <Menu.Item
                                key="skills"
                                icon={<BulbOutlined className="side-nav-icon ant-mb-0" />}
                                className="ant-mb-0"
                                style={{ height: "50px" }}
                            >
                            </Menu.Item>
                            <li className="ant-mb-2 side-nav-title"><strong><small>Skills</small></strong></li>

                            <Menu.Item
                                key="languages"
                                icon={<FontColorsOutlined className="side-nav-icon ant-mb-0" />}
                                className="ant-mb-0"
                                style={{ height: "50px" }}
                            >
                            </Menu.Item>
                            <li className="ant-mb-2 side-nav-title"><strong><small>Languages</small></strong></li>

                            <Menu.Item
                                key="certificates"
                                icon={<FileProtectOutlined className="side-nav-icon ant-mb-0" />}
                                className="ant-mb-0"
                                style={{ height: "50px" }}
                            >
                            </Menu.Item>
                            <li className="ant-mb-2 side-nav-title"><strong><small>Certificates</small></strong></li>

                            <Menu.Item
                                key="references"
                                icon={<TeamOutlined className="side-nav-icon ant-mb-0" />}
                                className="ant-mb-0"
                                style={{ height: "50px" }}
                            >
                            </Menu.Item>
                            <li className="ant-mb-2 side-nav-title"><strong><small>References</small></strong></li>

                            <Menu.Item
                                key="custom"
                                icon={<AppstoreAddOutlined className="side-nav-icon ant-mb-0" />}
                                className="ant-mb-0"
                                style={{ height: "50px" }}
                            >
                            </Menu.Item>
                            <li className="ant-mb-6 side-nav-title"><strong><small>Custom</small></strong></li>

                            <Menu.Item
                                key="personal"
                                icon={<Popconfirm
                                    title="Are you sure you want to clear all?"
                                    onConfirm={confirm}
                                    cancelText="Keep"
                                    okText="Clear All"
                                    okType="danger"
                                    placement="right"

                                >
                                    <CloseCircleOutlined className="side-nav-icon ant-mb-0" /></Popconfirm>}
                                className="ant-mb-0"
                                style={{ height: "50px" }}
                            >
                                {/* Clear All */}
                            </Menu.Item>
                            <li className="ant-mb-6 side-nav-title"><strong><small>Clear All</small></strong></li>
                        </Menu>
                    </div>
                </Col>
                <Col xl={23} lg={23} md={23} sm={24} xs={24} className="ant-mb-10 ant-pb-4">
                    <div className="ant-mt-8 finalize-form">

                        <div className="ant-mb-7 scroll-padding" id="personal">
                            <Divider><h5 className="title-resz scroll-padding-top">Personal Details </h5></Divider>
                            <HeaderForm />


                        </div>
                        <div className="ant-mb-7 finalize scroll-padding" id="summary">

                            <Divider><h5 className="title-resz">Summary</h5></Divider>
                            <SummaryForm />


                        </div>

                        <div className="ant-mb-7 scroll-padding" id="experience">

                            <Divider><h5 className="title-resz">Work Experience</h5></Divider>
                            <ExperienceForm />

                        </div>

                        <div className="ant-mb-7 scroll-padding" id="education">
                            <Divider><h5 className="title-resz">Education</h5></Divider>
                            <EducationForm />

                        </div>

                        <div className="ant-mb-7 scroll-padding" id="skills">
                            <Divider><h5 className="title-resz">Skills</h5></Divider>
                            <SkillsSkeleton />
                        </div>

                        <div className="ant-mb-7 scroll-padding" id="languages">
                            <Divider><h5 className="title-resz">Languages</h5></Divider>
                            <LanguageForm />

                        </div>

                        <div className="ant-mb-7 scroll-padding" id="certificates">
                            <Divider><h5 className="title-resz">Certificates</h5></Divider>
                            <CertificatesForm />
                        </div>

                        <div className="ant-mb-7 scroll-padding" id="references">
                            <Divider><h5 className="title-resz">References</h5></Divider>
                            <ReferencesForm />
                        </div>

                        <div className="ant-mb-7 scroll-padding" id="custom">
                            <Divider><h5 className="title-resz">Custom Section</h5></Divider>
                            <CustomsForm />
                        </div>

                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default FinalizeForm;