import React, { useState, useContext } from 'react';
import { Button, message } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { saveAs } from 'file-saver';

import { CoverletterContext } from '../../contexts/CoverLetterContext';
import { SessionContext } from '../../contexts/SessionContext';
import { MessageContext } from '../../contexts/MessageContext';
import { TemplateContext } from '../../contexts/TemplateContext';



/* API */

import { getPDcoverLetter, syncLetterData } from '../../api';



const DownloadCoverLetterBtn = () => {


    const { coverletter } = useContext(CoverletterContext);
    const { session } = useContext(SessionContext);
    const { template } = useContext(TemplateContext);
    const { setMessage } = useContext(MessageContext);

    

    const [loadings, setLoadings] = useState(false);

    const downloadResume = async () => {
        setLoadings(true);

        const resobj = { ...coverletter, session, template };

        try {
            
            await syncLetterData(resobj);
            setMessage({ data: { message: "Processing...." }, status: 10001 });
            try {
                const pdfBlob = await getPDcoverLetter(session);
                saveAs(pdfBlob, 'cover-letter.pdf');
                setMessage({ data: { message: "Cover Letter Downloaded" }, status: 10002 });
                setLoadings(false)
            } catch (error) {
                setMessage({ data: { errorMessage: "Download unavailable. Please try again later." }, status: 422 });
                setLoadings(false)
            }

        } catch (error) {
            setMessage(error.message);
            setLoadings(false)

        }


    };



    return (
        <>
            <Button loading={loadings} type="primary" icon={<DownloadOutlined />} onClick={downloadResume} size={"large"} className="resume-download-btn">
                Cover Letter Download
            </Button>
        </>
    )
}

export default DownloadCoverLetterBtn;