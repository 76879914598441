import * as React from "react";
import CoverletterDataHandler from "../handler/CoverletterDataHandler";

export const CoverletterContext = React.createContext();

export const CoverletterProvider = props => {
  
    const { coverletter, setCoverletterStatus, setUnsetCoverletteStatus } = CoverletterDataHandler();

    return (
        <CoverletterContext.Provider value={{ coverletter, setCoverletterStatus, setUnsetCoverletteStatus  }}>
             {props.children}
        </CoverletterContext.Provider>
    )

}