import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Typography, Button, Image, Input, InputNumber, Form, Select, Spin } from 'antd';
import CoverletterBtn from '../../../actions/CoverletterBtn';
import { SpinLoderContext } from '../../../../contexts/SpinLoderContext';
import { CoverletterContext } from '../../../../contexts/CoverLetterContext';


const { Title, Text } = Typography;
const { Option } = Select;
const WorkExperience = () => {

    const { spinloading, setSpinloading } = useContext(SpinLoderContext);
    const [form] = Form.useForm();
    const { coverletter, setCoverletterStatus } = useContext(CoverletterContext);
    const [workExeperience, setWorkExeperience] = useState({ durationBy: "", duration: 0 });


    useEffect(() => {

        setSpinloading(true);
        if (coverletter.workExeperience) {
            form.setFieldsValue(coverletter.workExeperience);
            setWorkExeperience(coverletter.workExeperience);
        } else {
            form.setFieldsValue(workExeperience);
        }

        const timer = setTimeout(() => {
            setSpinloading(false);
        }, 300);

        return () => clearTimeout(timer);
    }, []);


    useEffect(() => {
        setSpinloading(true);

        setCoverletterStatus({ ...coverletter, workExeperience });
        const timer = setTimeout(() => {
            setSpinloading(false);
        }, 200);
        return () => clearTimeout(timer);
    }, [workExeperience]);

    const onValuesChange = (val, all) => {
        setWorkExeperience(all)
        onFinish();
    }

    const onFinish = values => {
        console.log('Received values of form:', values);
    };


    return (
        <>

           
            {coverletter.progress === "work-experience" &&
                <>
                    <Row>
                        <Col xl={22} lg={24} md={24} sm={24} xs={24} offset={1} align="middle">
                            <div className="spin-loader" align="center">
                                {spinloading && <Spin loading={true} size="small"  />}
                            </div>
                        </Col>
                    </Row>
                    <Row className="ant-pr-1 ant-pl-1">
                        <Col xl={22} lg={24} md={24} sm={24} xs={24} align="middle" offset={1}>
                            <div className="form-mt"></div>
                            <Title level={2} className="ant-mb-0">How much work experience do you have?</Title>
                            <Text className="text-muted">(You can round your working experience up or down)</Text>
                        </Col>
                        <Col xl={18} lg={24} md={24} sm={24} xs={24} align="middle" offset={3}>
                            <div className="ant-mt-10 cover-step-wrapper">
                                <Form

                                    onFinish={onFinish}
                                    layout={"vertical"}
                                    wrapperCol={{
                                        span: 24
                                    }}
                                    size={"middle"}
                                    form={form}
                                    name="workExperience"
                                    onValuesChange={onValuesChange}

                                >

                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >


                                        <Col xl={16} lg={12} md={24} sm={24} xs={24} offset={4}>
                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                                                <Col xl={12} lg={12} md={14} sm={24} xs={24} className="gutter-row ant-mr-auto ant-ml-auto" align="left">
                                                    <Form.Item
                                                        label="Year / Month"
                                                        name="durationBy"
                                                        rules={[
                                                            {
                                                                type: "string",
                                                                required: true,
                                                                message: 'Please input your period',
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            style={{ width: "100%" }}
                                                            placeholder="Years / Month"
                                                        >
                                                            <Option value="year">Year(s)</Option>
                                                            <Option value="month">Month(s)</Option>

                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col xl={12} lg={12} md={10} sm={24} xs={24} className="gutter-row ant-mr-auto ant-ml-auto" align="middle" offset={4}>
                                                    <Form.Item
                                                        label="Duration"
                                                        name="duration"
                                                        rules={[
                                                            {
                                                                type: "number",
                                                                required: "true",
                                                                message: 'Please insert duration',
                                                            },
                                                        ]}
                                                    >
                                                        <InputNumber min={0} defaultValue={0} style={{ width: '100%' }} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>


                                        <Col xl={22} lg={24} md={24} sm={24} xs={24} className="gutter-row ant-mr-auto ant-ml-auto" align="middle" offset={1}>
                                            <div className="cover-buttons-center">
                                                <CoverletterBtn action={"expContinue"} text={"Continue"}></CoverletterBtn>
                                            </div>
                                        </Col>

                                    </Row>
                                </Form>


                            </div>
                        </Col>

                    </Row>
                </>
            }
        </>
    )
}

export default WorkExperience;