import React, { useState, useEffect } from 'react';
import { Row, Col, Steps } from 'antd';
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
const { Step } = Steps;

const CoverProgress = () => {
    const location = useLocation();
    // console.log(location, 'current location');
    const [step, setStep] = useState(0);

    const getCurrentStep = (path) => {

        switch (path) {
            case '/cover-letter/experience':
                return 0;
            case '/cover-letter/education':
                return 1;
            case '/cover-letter/skills':
                return 2;
            case '/cover-letter/personal':
                return 3;
            case '/cover-letter/finalize':
                return 4;

            default:
                return 0;
        }

    }

    useEffect(() => {
        setStep(getCurrentStep(location.pathname));
    }, [])
    return (
        <div className="cover-progress">
            <Row>
                <Col span={24} className="main-m-top cover-progress-steps">
                    <div className="">
                        <Steps direction="vertical" current={step} type>
                            <Step title={<Link to="/cover-letter/experience">Experience</Link>} description="&nbsp;" />
                            <Step title={<Link to="/cover-letter/education">Education</Link>} description="&nbsp;" />
                            <Step title={<Link to="/cover-letter/skills">Skills</Link>} description="&nbsp;" />
                            <Step title={<Link to="/cover-letter/personal">Personal</Link>} description="&nbsp;" />
                            <Step title={<Link to="/cover-letter/finalize">Finalize</Link>} description="&nbsp;" />
                        </Steps>
                    </div>
                </Col>
            </Row>

        </div>
    )
}

export default CoverProgress;