import React from 'react'
import { useHistory } from "react-router-dom";
import MainNav from '../../navigation/MainNav';
import { Row, Col, Typography, Button } from 'antd';
// import ResumeFormProgress from '../../navigation/resume/ResumeFormProgress';
import ResumeProgress from '../../navigation/resume/ResumeProgress';
import ResumePreview from './resume-preview/ResumePreview';
import { SummaryTopic } from '../../resume/forms/form-headers/FormHeaders';
import SummaryForm from '../../resume/forms/SummaryForm';
import {CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';

import DownloadBtn from '../../actions/DownloadBtn';

import ResumeProgressMobile from '../../navigation/resume/ResumeProgressMobile';
import AppMessages from '../../actions/AppMessages';



const Summary = () => {

    const history = useHistory();

    const Next = () => {
        history.push(`/resume/finalize`);
    }

    const Previuos = () => {
        history.push(`/resume/skills`);
    }

    return (
        <div>
            <MainNav />
            <AppMessages />
            <Row className="resume-page">
                <Col xl={13} lg={13} md={24} sm={24} xm={24} className="form-section" align="center">
                    <ResumeProgress />
                    <ResumeProgressMobile />
                    <SummaryTopic />
                    <div className="forms summary">
                        <SummaryForm />
                    </div>

                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col xl={24} lg={24} md={24} sm={24} xm={24} className="gutter-row">
                            <div className="footer">
                                <div className="footer-area">
                                    <Button className="resume-btn-nav  btn-distant" type="primary" size="large" onClick={Previuos}> <CaretLeftOutlined />Skills &nbsp;</Button>
                                    <Button className="resume-btn-nav" size="large" type="primary" onClick={Next}>&nbsp; Finalize<CaretRightOutlined /></Button>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Col>

                <Col xl={11} lg={11} md={24} sm={24} xm={24} className="preview-section">
                    {/* <ResumeFormProgress /> */}
                    <div>
                        <ResumePreview />
                    </div>
                    <DownloadBtn />
                </Col>
            </Row>
        </div>
    )
}
export default Summary;