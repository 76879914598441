import React, { useContext, useState, useEffect } from 'react'
import { Row, Col, Form, Select, Input, Spin, Typography } from 'antd';
// import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { SpinLoderContext } from '../../../../contexts/SpinLoderContext';
import { CoverletterContext } from '../../../../contexts/CoverLetterContext';
import CoverletterBtn from '../../../actions/CoverletterBtn';
const { Title, Text } = Typography;

const { Option } = Select;


const PossitionApply = () => {

    const { spinloading, setSpinloading } = useContext(SpinLoderContext);
    const [form] = Form.useForm();
    const { coverletter, setCoverletterStatus } = useContext(CoverletterContext);
    const [applying, setApplying] = useState({ companyName: '', title: "", firstName: '', lastName: '', position: '', companyAddress: '' });

    useEffect(() => {

        if (coverletter.applying) {
            setApplying(coverletter.applying);
            form.setFieldsValue(coverletter.applying);
        } else {
            form.setFieldsValue(applying);
        }
        const timer = setTimeout(() => {
            setSpinloading(false);
        }, 300);
        return () => clearTimeout(timer);
    }, []);


    useEffect(() => {
        setSpinloading(true)
        form.setFieldsValue(applying)
        setCoverletterStatus({ ...coverletter, applying });
        const timer = setTimeout(() => {
            setSpinloading(false)
        }, 200);

        return () => clearTimeout(timer);
    }, [applying]);

    const onValuesChange = (val, all) => {
        setApplying(all);
        onFinish();
    }

    const onFinish = values => {

    };


    return (
        <>
            <Row>
                <Col xl={{ span: 22, offset: 1 }} lg={24} md={24} sm={24} xs={24}align="middle">
                    <div className="spin-loader" align="center">
                        {spinloading && <Spin loading={true} size="small"  />}
                    </div>
                </Col>
                <Col xl={24} lg={24} md={24} sm={24} xs={24} align="middle" className="ant-mt-10">
                    <Title level={2} className=" ant-mb-0">Company Details</Title>
                </Col>
            </Row>

            <Form
                onFinish={onFinish}
                layout={"vertical"}
                wrapperCol={{
                    span: 24
                }}
                size={"middle"}
                form={form}
                name="final"
                onValuesChange={onValuesChange}
            >
                <Row>
                    <Col xl={{ span: 14, offset: 5 }} lg={{ span: 14, offset: 5 }} md={{ span: 14, offset: 5 }} sm={24} xs={24} className="gutter-row ant-mt-10" align="middle">

                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col xl={8} lg={8} md={8} sm={24} xs={24} className="gutter-row" align="left">
                                <Form.Item
                                    label="Title"
                                    name="title"
                                >

                                    <Select
                                        style={{ width: "100%" }}
                                        placeholder="Title"
                                    >
                                        <Option value="Mr">Mr</Option>
                                        <Option value="Mrs">Mrs</Option>
                                        <Option value="Miss">Miss</Option>
                                        <Option value="Ms">Ms</Option>
                                        <Option value="Dr">Dr</Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xl={8} lg={8} md={8} sm={24} xs={24} className="gutter-row ant-mb-2" align="middle">
                                <Form.Item
                                    label="First Name"
                                    name="firstName"
                                    rules={[
                                        {
                                            type: "string",
                                            message: 'Please input first name',
                                        },{ max: 100, message: 'First name must be maximum 100 characters.' },
                                    ]}
                                >
                                    <Input
                                        placeholder={"Jane"}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xl={8} lg={8} md={8} sm={24} xs={24} className="gutter-row ant-mb-2" align="middle">
                                <Form.Item
                                    label="Last Name"
                                    name="lastName"
                                    rules={[
                                        {
                                            type: "string",
                                            message: 'Please input last name',
                                        },{ max: 100, message: 'Last name must be maximum 100 characters.' },
                                    ]}
                                >
                                    <Input
                                        placeholder={"Doe"}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xl={12} lg={12} md={24} sm={24} xs={24} className="gutter-row ant-mb-2" align="middle">

                                <Form.Item
                                    label="Company Name"
                                    name="companyName"
                                    rules={[
                                        {
                                            type: "string",
                                            required: "true",
                                            message: 'Please input your company name',
                                        },{ max: 100, message: 'Company name must be maximum 100 characters.' },
                                    ]}
                                >
                                    <Input
                                        placeholder={"XYZ Company"}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xl={12} lg={12} md={24} sm={24} xs={24} className="gutter-row ant-mb-2" align="middle">
                                <Form.Item
                                    label="Position"
                                    name="position"
                                    rules={[
                                        {
                                            type: "string",
                                            message: 'Please input position',
                                        },{ max: 100, message: 'Position name must be maximum 100 characters.' },
                                    ]}
                                >
                                    <Input
                                        placeholder={"Manager"}
                                    />
                                </Form.Item>

                            </Col>
                            <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gutter-row ant-mb-2" align="middle">
                                <Form.Item
                                    label="Address"
                                    name="companyAddress"
                                    rules={[
                                        {
                                            type: "string",
                                            message: 'Please input address',
                                        },{ max: 100, message: 'Address name must be maximum 100 characters.' },
                                    ]}
                                >
                                    <Input
                                        placeholder={"No.XX,Main Street,Looks Junction,NY"}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gutter-row" align="middle">
                                <div className="cover-buttons-end">
                                    <CoverletterBtn action={"setDescribe"} text={"Back"}></CoverletterBtn>
                                    <CoverletterBtn action={"setApply"} text={"Continue"}></CoverletterBtn>
                                </div>
                            </Col>

                        </Row>
                    </Col>
                </Row>




            </Form>
        </>);

}

export default PossitionApply;