import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Row, Col, Form, Input, Button, Skeleton, Tabs, Rate, AutoComplete, Spin } from 'antd';
import { DeleteTwoTone, DragOutlined, PlusOutlined, StarTwoTone } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import shortid from 'shortid';
import debounce from 'lodash.debounce';

import { ResumeContext } from '../../../contexts/ResumeContext';


const SoftSkills = () => {

    const [loading, setLoading] = useState(true);
    const [loadings, setLoadings] = useState(false);
    const { resume, setResumeStatus } = useContext(ResumeContext);
    const [form] = Form.useForm();

    const [skills, setSkills] = useState([
        'Adaptability',
        'Analytical Thinking',
        'Attention to Detail',
        'Brainstorming',
        'Collaboration',
        'Communication',
        'Conflict Management',
        'Creativity',
        'Customer Service',
        'Delegation',
        'Emotional Intelligence',
        'Flexibility',
        'Independence',
        'Initiative',
        'Innovation',
        'Interpersonal Skills',
        'Leadership',
        'Management',
        'Mediation',
        'Negotiation',
        'Organization',
        'Problem Solving',
        'Research',
        'Stress Management',
        'Teamwork',
        'Thinking outside the box',
        'Time Management',
        'Troubleshooting',
        'Work Ethic',
        'Writing'
    ]);

    const [softskills, setSoftskills] = useState({
        softskills: [
            { skill: "", objid: shortid.generate() }
        ]
    });

    const debounceSave = useCallback(
        debounce((nextvalue) => setSoftskills(nextvalue), 300)
        , []
    );

    const onFinish = values => {
        console.log('Received values of form:', values);
    };

    const onValuesChange = (val, all) => {

        if (softskills.softskills.length != all.softskills.length) {
            setSoftskills(all);
        } else {
            debounceSave(all);
        }

    };

    useEffect(() => {

        if (resume.softskills && resume.softskills.length > 0) {
            setSoftskills({ softskills: resume.softskills });
            form.setFieldsValue(softskills);
        } else {
            form.setFieldsValue(softskills);
        }

        form.setFieldsValue(softskills);
        const timer = setTimeout(() => {
            setLoading(false);
        }, 300);
        return () => clearTimeout(timer);

    }, []);

    useEffect(() => {
        setLoadings(true)
        const items = softskills.softskills.filter(item => {
            if (item.skill !== undefined)
                return item;
        })
        softskills['softskills'] = items;

        form.setFieldsValue(softskills)
        setResumeStatus({ ...resume, ...softskills });


        const timer = setTimeout(() => {
            setLoadings(false)
        }, 100);

    }, [softskills]);



    const onDragEnd = (result) => {

        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const items = reorder(
            softskills.softskills,
            result.source.index,
            result.destination.index
        );

        softskills['softskills'] = items;
        setSoftskills({ ...softskills })

    }

    const reorder = (list, startIndex, endIndex) => {

        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    return (
        <div className="small-forms">
            <Form
                onFinish={onFinish}
                onValuesChange={onValuesChange}
                form={form}
                layout={"vertical"}
                wrapperCol={{
                    span: 24
                }}
                size={"middle"}
            >
                <div className="spin-loader" align="center">
                    {loadings && <Spin size="small" />}
                </div>

                <Form.List name="softskills">
                    {(fields, { add, remove }) => (
                        <>

                            <DragDropContext onDragEnd={onDragEnd}>

                                <Droppable droppableId="item" >
                                    {
                                        (provided) => (
                                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                                {fields.map((field, index, arr) => (

                                                    <>
                                                        <Draggable key={softskills.softskills[index] ? softskills.softskills[index].objid : 'temp'} draggableId={softskills.softskills[index] ? softskills.softskills[index].objid : 'temp'} index={index}>

                                                            {provided => (
                                                                <div ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}>


                                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="skill-section">
                                                                        <Col xl={20} lg={20} md={20} sm={24} xs={24} className="gutter-row">
                                                                            <Form.Item
                                                                                {...field}

                                                                                name={[field.name, 'skill']}
                                                                                fieldKey={[field.fieldKey, 'skill']}
                                                                                rules={[
                                                                                    {
                                                                                        type: "string",
                                                                                        message: 'Please input Skill!',
                                                                                    },
                                                                                    { max: 100, message: 'Skill must be maximum 100 characters.' }

                                                                                ]}
                                                                            >
                                                                                <AutoComplete placeholder="Leadership" dataSource={skills}
                                                                                    filterOption={(inputValue, option) =>
                                                                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                                                    }
                                                                                >

                                                                                </AutoComplete>
                                                                            </Form.Item>

                                                                        </Col>

                                                                        <Col xl={2} lg={2} md={2} sm={12} xs={12} className="gutter-row ant-pr-0" align="right">
                                                                            {loadings === false && <DeleteTwoTone
                                                                                className="remove-btn ant-mt-1"
                                                                                twoToneColor="#f5222d"
                                                                                onClick={() => remove(field.name)}
                                                                            />}
                                                                        </Col>
                                                                        <Col xl={2} lg={2} md={2} sm={12} xs={12} className="gutter-row" align="left">
                                                                            <DragOutlined className="drag-btn ant-mt-1" />
                                                                        </Col>
                                                                    </Row>

                                                                </div>

                                                            )}

                                                        </Draggable>
                                                    </>


                                                ))}
                                                <div className="spin-loader" align="center">
                                                    {loadings && <Spin size="small" />}
                                                </div>
                                                {provided.placeholder}
                                            </div>
                                        )
                                    }
                                </Droppable>
                                <Form.Item className="add-btn add-btn-align-skill">
                                    <Button type="primary" loading={loadings} onClick={() => add({ skill: "", objid: shortid.generate() })} icon={<PlusOutlined />}>
                                        Add Skill
                                                </Button>
                                </Form.Item>
                            </DragDropContext>


                        </>
                    )}
                </Form.List>

            </Form>
        </div>
    )

}

export default SoftSkills;