import React, { useEffect, useContext } from 'react';
import { message as msg } from 'antd';

import { MessageContext } from '../../contexts/MessageContext';

const AppMessages = () => {


    const { message, setMessage } = useContext(MessageContext);


    const info = () => {
        msg.info('This is a normal message');
    };

    const onClose = () => {

    }

    useEffect(() => {
       
        if(message && message.status === 200) {
            msg.success(message.data.message, 2, onClose);

        } else if (message && message.status === 422) {
            msg.error(message.data.errorMessage, 5, onClose);
            
        } else if (message && message.status === 10001) {
            msg.loading(message.data.message, 1, onClose)

        } else if (message && message.status === 10002) {

            msg.success(message.data.message, 5, onClose);
        }
    }, [message]);

    return (<></>)

}



export default AppMessages;

