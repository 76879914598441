import React, { useContext, useState, useEffect } from 'react'
import { Row, Col, Form, Select, Input, Button } from 'antd';
// import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { SpinLoderContext } from '../../../../contexts/SpinLoderContext';
import { CoverletterContext } from '../../../../contexts/CoverLetterContext';
import BodyForm from './BodyForm';
import CoverletterBtn from '../../../actions/CoverletterBtn';

const { Option } = Select;

const Finalize = () => {


    const { spinloading, setSpinloading } = useContext(SpinLoderContext);
    const [form] = Form.useForm();
    const { coverletter, setCoverletterStatus } = useContext(CoverletterContext);
    const [applying, setApplying] = useState({ companyName: '', title: "", firstName: '', lastName: '', profession: '', address: '' });

    useEffect(() => {

        if (coverletter.applying) {
            setApplying(coverletter.applying);
            form.setFieldsValue(coverletter.applying);
        } else {
            form.setFieldsValue(applying);
        }
        const timer = setTimeout(() => {
            setSpinloading(false);
        }, 300);
        return () => clearTimeout(timer);
    }, []);


    useEffect(() => {
        setSpinloading(true)
        form.setFieldsValue(applying)
        setCoverletterStatus({ ...coverletter, applying });
        const timer = setTimeout(() => {
            setSpinloading(false)
        }, 200);

        return () => clearTimeout(timer);
    }, [applying]);

    const onValuesChange = (val, all) => {
        setApplying(all);
        onFinish();
    }

    const onFinish = values => {

    };



    return (
        <div className="ant-mb-6 cover-finalize">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gutter-row" align="left">
                    <BodyForm></BodyForm>
                
                    <CoverletterBtn action={"setPersonal"} text={"Back"} type="primary"></CoverletterBtn>
                </Col>
            </Row>
        </div>
    )
}

export default Finalize;