import React, { useContext } from 'react'
import moment from 'moment';
import cx from 'classnames'
import { ResumeContext } from '../../../../contexts/ResumeContext';
import { TemplateContext } from '../../../../contexts/TemplateContext';
import bootstrap from '../../../../css/bootstrap.module.css'
import style from './diamond.module.css'
import ReactHtmlParser from 'react-html-parser';
const styles = { ...bootstrap, ...style }


// import { SideNavContext } from '../../../contexts/SideNavContext';
// import ReactHtmlParser from 'react-html-parser';

const Diamond = () => {
    const Resume = useContext(ResumeContext);
    const ResumeData = (Resume.resume);
    // const highlight = useContext(SideNavContext);

    const TemplateCol = useContext(TemplateContext);
    const Color = (TemplateCol.template.resumeColor);


    // const tempCol = 'red';

    return (
        <div className={cx(styles['default'], styles[Color])}>
            <div id="header">
                <div className={styles.header}>
                    {ResumeData.personal ?
                        <>
                            <div className={cx(styles['d-flex'], styles['justify-content-between'])}>
                                <div className={cx(styles['name-profession'])}>
                                    <h1 className={cx(styles['full-name'], styles['mb-0'])}>{ResumeData.personal.firstName} {ResumeData.personal.lastName}</h1>
                                    <p className={cx(styles['profession'], styles['mb-2'], styles['font-weight-normal'], styles['text-muted'])}>{ResumeData.personal.profession}</p>
                                </div>
                                <div className={cx(styles['contact'], styles['d-flex'], styles['justify-content-end'])}>
                                    <div className={cx(styles['text-right'])}>

                                        <p className={cx(styles['w-100'], styles['mb-1'], styles['pl-1'])}>{ResumeData.personal.address}</p>
                                        <p className={cx(styles['w-100'], styles['mb-1'], styles['pl-1'])}>{ResumeData.personal.phone}</p>
                                        <p className={cx(styles['w-100'], styles['mb-1'], styles['pl-1'])}>{ResumeData.personal.email}</p>
                                    </div>
                                </div>
                            </div>
                            {(ResumeData.personal.profileLinks && ResumeData.personal.profileLinks.length) ?
                                <div className={cx(styles['text-right'])}>
                                    {ResumeData.personal.profileLinks.map((ResumeData, index) => (
                                        
                                        <p className={cx(styles['w-100'], styles['mb-1'], styles['pl-1'])} key={index} >
                                            <span className={cx(styles['font-weight-bold'])} key={'website' + ResumeData.website}>{ResumeData.website}</span>
                                            {ResumeData.websiteLink && ResumeData.websiteLink ? <span className={cx(styles['mx-1'])}>-</span> : ""}
                                            <a href={ResumeData.websiteLink} rel="noopener noreferrer" target="_blank">
                                                <span key={'websiteLink' + ResumeData.websiteLink}>{ResumeData.websiteLink}</span>
                                            </a>
                                        </p>
                                    ))}
                                </div>
                                : ""}
                        </>
                        : ""}
                </div>
            </div>

            {(ResumeData.summary == `<p>&nbsp;</p>` || ResumeData.summary == `<p><br></p>` || ResumeData.summary.length === 0) ? <></> :
                <div id="summary">
                    <div className={cx(styles['description'], styles['summary'], styles['text-justify'], styles['summary'])}>
                        {ReactHtmlParser(ResumeData.summary)}
                    </div>
                </div>}
            <div className={styles.space}></div>
            <div className={styles.clear}></div>

            {(ResumeData.hardskills && ResumeData.hardskills.length && ResumeData.hardskills[0].skill.length) ?
                <div id="skills">
                    <div className={cx(styles["skills"])}>
                        <h4 className={cx(styles['heading'], styles['font-weight-bold'])}>Technical Skills</h4>
                        <div className={cx(styles['flex-col'], styles['flex-wrap'], styles['d-flex'])}>
                            {ResumeData.hardskills.map((ResumeData, index) => (
                                <div key={index}>
                                    {ResumeData.skill.length ? <p className={cx(styles['mb-0'], styles['mr-3'], styles['skill-section'])} key={'skill' + ResumeData.hardskills}>{ResumeData.skill}</p> : ""}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={styles.space}></div>
                    <div className={cx(styles['clear'])}></div>
                </div> : ""}


            {(ResumeData.experiences && ResumeData.experiences.length && (ResumeData.experiences[0].jobTitle || ResumeData.experiences[0].employer || ResumeData.experiences[0].city)) ?
                <div id="experience">
                    <div className={styles.experience}>
                        <h4 className={cx(styles['heading'], styles['font-weight-bold'])}>Work Experience</h4>
                        {ResumeData.experiences.map((ResumeData, index) => (
                            <div className={styles.section} key={index}>
                                <div className={cx(styles['d-flex'], styles['flex-col'], styles['flex-wrap'], styles['justify-content-between'])}>
                                    <p className={cx(styles['font-weight-bold'], styles['mb-0'], styles['flex-wrap'], styles['text-capitalize'])} key={'jobTitle' + ResumeData.jobTitle}>{ResumeData.jobTitle}</p>
                                    <em>
                                        <p className={cx(styles['mb-1'], styles['text-muted'], styles['font-weight-normal'], styles['small'])}>
                                            {ResumeData.startDate === null ? "" : <span key={'startDate' + ResumeData.startDate}>{moment(ResumeData.startDate).format('MMM YYYY')}</span>}
                                            {ResumeData.startDate !== null ? <span className={cx(styles['mx-1'])}>-</span> : ""}
                                            <span>{ResumeData.currentlyWorking !== true ? (ResumeData.endDate === null ? "" : <span key={'endDate' + ResumeData.endDate}>{moment(ResumeData.endDate).format('MMM YYYY')}</span>) : "Present"}</span>
                                        </p>
                                    </em>
                                </div>
                                <div className={cx(styles['d-flex'], styles['flex-col'], styles['flex-wrap'], styles['justify-content-between'])}>
                                    <a href={ResumeData.website} rel="noopener noreferrer" target="_blank"><p className={cx(styles['mb-0'])} key={'employer' + ResumeData.employer}>{ResumeData.employer}</p></a>
                                    <em>
                                        <p className={cx(styles['mb-2'], styles['text-muted'], styles['font-weight-normal'], styles['small'])} key={'city' + ResumeData.city}>{ResumeData.city}</p>
                                    </em>
                                </div>

                                {(ResumeData.summary == `<p>&nbsp;</p>` || ResumeData.summary == `<p><br></p>`) ? <></> :
                                    <div className={cx(styles['description'])} key={'summaryHtml' + ResumeData.summary}>
                                        {ReactHtmlParser(ResumeData.summary)}
                                    </div>}
                                <div className={cx(styles['space-end'])}></div>
                            </div>
                        ))}
                    </div>
                    <div className={cx(styles['space-end'])}></div>
                    <div className={cx(styles['clear'])}></div>
                </div>
                : ""}

            {(ResumeData.educations && ResumeData.educations.length && (ResumeData.educations[0].qualification || ResumeData.educations[0].field || ResumeData.educations[0].institute)) ?
                <div id="education">
                    <div className={styles.education}>
                        <h4 className={cx(styles['heading'], styles['font-weight-bold'])}>Education</h4>
                        {ResumeData.educations.map((ResumeData, index) => (
                            <div className={styles.section} key={index}>
                                <div className={cx(styles['d-flex'], styles['flex-col'], styles['flex-wrap'], styles['justify-content-between'])}>
                                    <div className={cx(styles['d-flex'], styles['flex-col'], styles['flex-wrap'])}>
                                        <p className={cx(styles['font-weight-bold'], styles['mb-0'])} key={'qualification' + ResumeData.qualification}>{ResumeData.qualification}</p>
                                        {ResumeData.field && ResumeData.qualification ? <span className={cx(styles['mx-1'], styles['font-weight-bold'])}>-</span> : ""}
                                        <p className={cx(styles['mb-0'], styles['font-weight-bold'])} key={'field' + ResumeData.field}>{ResumeData.field}</p>
                                    </div>

                                    <em>
                                        <p className={cx(styles['mb-1'], styles['text-muted'], styles['font-weight-normal'])}>
                                            {ResumeData.startDate === null ? "" : <span key={'startDate' + ResumeData.startDate}>{moment(ResumeData.startDate).format('MMM YYYY')}</span>}
                                            {ResumeData.startDate !== null ? <span className={cx(styles['mx-1'])}>-</span> : ""}
                                            <span>{ResumeData.stillStudying !== true ? (ResumeData.endDate === null ? "" : <span key={'endDate' + ResumeData.endDate}>{moment(ResumeData.endDate).format('MMM YYYY')}</span>) : "Present"}</span>
                                        </p>
                                    </em>
                                </div>

                                <div className={cx(styles['d-flex'], styles['flex-col'], styles['flex-wrap'], styles['justify-content-between'])}>
                                    <a href={ResumeData.website} rel="noopener noreferrer" target="_blank"><p className={cx(styles['mb-0'])} key={'institute' + ResumeData.institute}>{ResumeData.institute}</p></a>
                                    <em>
                                        <p className={cx(styles['mb-2'], styles['small'], styles['text-muted'], styles['font-weight-normal'])} key={'city' + ResumeData.city}>{ResumeData.city}</p>
                                    </em>
                                </div>
                                {(ResumeData.summary == `<p>&nbsp;</p>` || ResumeData.summary == `<p><br></p>`) ? <></> :
                                    <div className={cx(styles['description'])} key={'summaryHtml' + ResumeData.summary}>
                                        {ReactHtmlParser(ResumeData.summary)}
                                    </div>}
                                <div className={cx(styles['space-end'])}></div>
                            </div>
                        ))}
                    </div>
                    <div className={cx(styles['space-end'])}></div>
                    <div className={cx(styles['clear'])}></div>
                </div> : ""}

            {(ResumeData.softskills && ResumeData.softskills.length && ResumeData.softskills[0].skill.length) ?
                <div id="expertise-areas">
                    <div className={cx(styles['expertise-areas'], styles['expertise-areas'])}>
                        <h4 className={cx(styles['heading'], styles['font-weight-bold'])}>Soft Skills</h4>
                        <div className={cx(styles['flex-col'], styles['flex-wrap'], styles['d-flex'])}>
                            {ResumeData.softskills.map((ResumeData, index) => (
                                <div key={index}>
                                    {ResumeData.skill.length ? <p className={cx(styles['expert-section'], styles['mb-0'], styles['mr-3'])} key={'skill' + ResumeData.skill}>{ResumeData.skill}</p> : ""}
                                </div>
                            ))}
                        </div>
                        <div className={cx(styles['space'])}></div>
                        <div className={cx(styles['clear'])}></div>
                    </div>
                </div> : ""}


            {(ResumeData.certificates && ResumeData.certificates.length && ResumeData.certificates[0].certificateName.length) ?
                <div id="certificates">
                    <div className={styles.certificates}>
                        <h4 className={cx(styles['heading'], styles['font-weight-bold'])}>Certificates</h4>
                        <div className={cx(styles['flex-col'], styles['flex-wrap'], styles['d-flex'])}>
                            {ResumeData.certificates.map((ResumeData, index) => (
                                <div className={cx(styles['section'], styles['w-100'])} key={index}>
                                    <p className={cx(styles['font-weight-normal'], styles['mb-0'])} key={'certificateName' + ResumeData.certificateName}>{ResumeData.certificateName} {ResumeData.issueDate === null ? "" : <span key={'issueDate' + ResumeData.issueDate}>({moment(ResumeData.issueDate).format('DD MMM YYYY')})</span>}</p>
                                    <p className={cx(styles['mb-0'])}>
                                        <span key={'organization' + ResumeData.organization}> {ResumeData.organization}</span>
                                        {ResumeData.certificateNo && ResumeData.organization ? <span className={cx(styles['mx-1'])}>-</span> : ""}
                                        <span key={'certificateNo' + ResumeData.certificateNo}>{ResumeData.certificateNo}</span>
                                    </p>
                                    <a href={ResumeData.url} rel="noopener noreferrer" target="_blank">
                                        <p className={cx(styles['mb-0'], styles['text-muted'], styles['small'])} key={'url' + ResumeData.url}>{ResumeData.url}</p>
                                    </a>
                                    <div className={cx(styles['space-end'])}></div>
                                </div>
                            ))}
                        </div>

                    </div>
                    <div className={cx(styles['space-end'])}></div>
                    <div className={cx(styles['clear'])}></div>
                </div> : ""}

            {(ResumeData.languages && ResumeData.languages.length && ResumeData.languages[0].language.length) ?
                <div id="languages">
                    <div className={cx(styles['languages'], styles['languages'])}>
                        <h4 className={cx(styles['heading'], styles['font-weight-bold'])}>languages</h4>
                        <div className={cx(styles['flex-col'], styles['flex-wrap'], styles['d-flex'])}>
                            {ResumeData.languages.map((ResumeData, index) => (
                                <div className={cx(styles['lan-section'], styles['d-flex'])} key={index}>
                                    <p className={cx(styles['mb-0'], styles['font-weight-bold'], styles['text-capitalize'])} key={'language' + ResumeData.language}>{ResumeData.language}</p>
                                    { ResumeData.proficiencyLevel ? <span className={cx(styles['mx-1'])}>-</span> : ""}
                                    <p key={'rating' + ResumeData.rating} className={cx(styles['mb-0'])}>{ResumeData.proficiencyLevel}</p>
                                    <div className={cx(styles['space-end'])}></div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={cx(styles['space'])}></div>
                    <div className={cx(styles['clear'])}></div>
                </div> : ""}

            {(ResumeData.customs && ResumeData.customs.length && ResumeData.customs[0].sectionTitle.length) ?
                <div id="customsec">
                    {ResumeData.customs.map((ResumeData, index) => (
                        <>
                            <div className={cx(styles['customsec'])} key={index}>
                                <h4 className={cx(styles['heading'], styles['font-weight-bold'])} key={'sectionTitle' + ResumeData.sectionTitle}>{ResumeData.sectionTitle}</h4>
                                {(ResumeData.summary == `<p>&nbsp;</p>` || ResumeData.summary == `<p><br></p>`) ? <></> :
                                    <div className={cx(styles['description'])} key={'summaryHtml' + ResumeData.summary}>
                                        {ReactHtmlParser(ResumeData.summary)}
                                    </div>}
                            </div>
                            <div className={cx(styles['space'])}></div>
                        </>
                    ))}
                </div> : ""}

            {(ResumeData.references && ResumeData.references.length && ResumeData.references[0].fullName.length) ?
                <div id="references">
                    <div className={cx(styles['references'])}>
                        <h4 className={cx(styles['heading'], styles['font-weight-bold'])}>References</h4>
                        <div className={cx(styles['d-flex'])}>
                            {ResumeData.references.map((ResumeData, index) => (
                                <div className={cx(styles['section'], styles['w-50'])} key={index}>
                                    <p className={cx(styles['mb-0'], styles['font-weight-normal'])} key={"fullName" + ResumeData.fullName}>{ResumeData.fullName}</p>
                                    <p key={"position" + ResumeData.occupation} className={cx(styles['mb-0'])}>{ResumeData.occupation}</p>
                                    <p key={"company" + ResumeData.company} className={cx(styles['mb-0'])}>{ResumeData.company}</p>
                                    <p key={"email" + ResumeData.email} className={cx(styles['mb-0'])}>{ResumeData.email}</p>
                                    <p key={"phone" + ResumeData.phone} className={cx(styles['mb-0'])}>{ResumeData.phone}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div> : ""}
        </div >
    )
}

export default Diamond;