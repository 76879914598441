import React, { useContext, useState, useEffect, useCallback } from 'react'
import { Form, Input, Row, Col, DatePicker, Skeleton, Spin, Radio, Select } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import debounce from 'lodash.debounce';
import moment from 'moment';


import { getLetterBody, syncLetterData } from '../../../../api';
import { SessionContext } from '../../../../contexts/SessionContext';
import { CoverletterBodyContext } from '../../../../contexts/CoverLetterBodyContext'
import { CoverletterContext } from '../../../../contexts/CoverLetterContext';
import { SpinLoderContext } from '../../../../contexts/SpinLoderContext';
import { TemplateContext } from '../../../../contexts/TemplateContext';
const { Option } = Select;

const BodyForm = () => {


    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const { spinloading, setSpinloading } = useContext(SpinLoderContext);

    const { session } = useContext(SessionContext);
    const { template } = useContext(TemplateContext);
    const { coverletter, setCoverletterStatus } = useContext(CoverletterContext);



    const [letterBody, setLetterBody] = useState({
        body: '',
        applyingJob: '',
        dateFormat: 'MM/DD/YYYY',
        letterType: 'artistic',
        date: null,
        letterNew : true
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {


        setSpinloading(true);

        if (coverletter.letterBody) {

            if (coverletter.letterBody.date != null) {
                coverletter.letterBody.date = moment(coverletter.letterBody.date);
            }
            setLetterBody(coverletter.letterBody);
            form.setFieldsValue(coverletter.letterBody);

        } else {

            try {
                const bodyText = await getLetterBody(session);
                letterBody.letterNew = false;
                setLetterBody({ ...letterBody, body: bodyText.header + '<br /> <br />' + bodyText.body + '<br /> <br />' + bodyText.footer });
                form.setFieldsValue(letterBody);

            } catch (error) {

            }
        }


        const timer = setTimeout(() => {
            setLoading(false);
            setSpinloading(false);
        }, 300);
        return () => clearTimeout(timer);


    }, []);


    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        
        setSpinloading(true);
        if (letterBody?.letterType !== coverletter.letterBody?.letterType) {
            letterBody.letterNew = true;
        }
        await changeData();
        form.setFieldsValue(letterBody);

        const timer = setTimeout(() => {
            setLoading(false);
            setSpinloading(false);
        }, 200);
        return () => clearTimeout(timer);
    }, [letterBody]);



    useEffect(() => {

        setSpinloading(true);

        if (coverletter.letterBody && coverletter.letterBody.letterNew) {


            try {

                getNewLetter();

            } catch (error) {

                //         //setMessage(error.message);
                //         //setLoadings(false)

            }

            //     console.log('one');
        }

        const timer = setTimeout(() => {
            setLoading(false);
            setSpinloading(false);
        }, 200);
        return () => clearTimeout(timer);

    }, [coverletter]);

    const changeData = async () => {
        await setCoverletterStatus({ ...coverletter, letterBody });

    }

    const getNewLetter = async () => {

        const resobj = { ...coverletter, session, template };
        await syncLetterData(resobj);
        const bodyText = await getLetterBody(session);
        letterBody.letterNew = false;
        setLetterBody({ ...letterBody, body: bodyText.header + '<br /> <br />' + bodyText.body + '<br /> <br />' + bodyText.footer });
        form.setFieldsValue(letterBody);
  

    }

    const debounceSave = useCallback(
        debounce((nextvalue) => setLetterBody(nextvalue), 600)
        , []
    );


    const onValuesChange = (val, all) => {
        debounceSave(all);
        onFinish();
    }


    const onFinish = values => {

    };


    return (
        <>
            <Skeleton active={true} loading={loading}>

                <Form
                    onFinish={onFinish}
                    layout={"vertical"}
                    wrapperCol={{
                        span: 24
                    }}
                    size={"middle"}
                    form={form}
                    name=""
                    onValuesChange={onValuesChange}

                >
                    <div className="spin-loader ant-mt-2" align="center">
                        {spinloading && <Spin loading={true} size="small"  />}
                    </div>

                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="ant-mt-2">
                        <Col xl={16} lg={16} md={24} sm={24} xs={24} align="left">
                            <Form.Item
                                label="Cover Letter type"
                                name={'letterType'}
                                rules={[
                                    {
                                        type: "string",
                                        message: 'Please select cover letter type',
                                    },
                                ]}>

                                <Select defaultValue="artistic" style={{ width: '100%' }} >
                                    <Option value="artistic">Artistic</Option>
                                    <Option value="enterprising">Enterprising</Option>
                                    <Option value="investigative">Investigative</Option>
                                    <Option value="organized">Organized</Option>
                                    <Option value="practical">Practical</Option>
                                    <Option value="serviceOriented">Service Oriented</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xl={8} lg={8} md={24} sm={24} xs={24} align="left">


                            <Form.Item
                                label="Date format"
                                name={'dateFormat'}
                                rules={[
                                    {
                                        type: "string",
                                        message: 'Please select date format',
                                    },
                                ]}>

                                <Select defaultValue="MM/DD/YYYY"  style={{ width: '100%' }}  >
                                    <Option value="MM/DD/YYYY">MM/DD/YYYY</Option>
                                    <Option value="DD/MM/YYYY">DD/MM/YYYY</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                        <Col xl={16} lg={16} md={24} sm={24} xs={24} align="left">
                            <Form.Item
                                label="Applying for"
                                name="applyingJob"
                                rules={[
                                    {
                                        type: "string",
                                        required: true,
                                        message: 'Please input your applying job',
                                    },{ max: 100, message: 'Applying job must be maximum 100 characters.' },
                                ]}
                            >
                                <Input
                                    placeholder={"Web Developer"}
                                />
                            </Form.Item>
                        </Col>
                        <Col xl={8} lg={8} md={24} sm={24} xs={24} align="left">
                            <Form.Item
                                label="Date"
                                name="date"
                            >
                                <DatePicker format={letterBody.dateFormat} style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>

                        <Col xl={24} lg={24} md={24} sm={24} xs={24} align="left" className="ant-mt-3">
                            <Form.Item
                                name="body"
                                rules={[{ type: "string" },
                                { max: 5000, message: 'Summary must be maximum 5000 characters.' }]}
                            >

                                <ReactQuill
                                    theme="snow"

                                    modules={{
                                        toolbar: [
                                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                            [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                            { 'indent': '-1' }, { 'indent': '+1' }],
                                            ['link'],
                                            ['clean']
                                        ]
                                    }}
                                    placeholder={"Description"}
                                >

                                </ReactQuill>

                            </Form.Item>
                            <Form.Item
                               
                                name="letterNew"
                                rules={[
                                    {
                                        type: "string",
                                        required: true,
                                        message: 'Please input your applying job',
                                    },{ max: 100, message: 'Applying job must be maximum 100 characters.' },
                                ]}
                            >
                                <Input
                                    type="hidden"
                                    placeholder={"Web Developer"}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>


            </Skeleton>
        </>
    )
}

export default BodyForm;