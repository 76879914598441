import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Row, Col, Form, Input, Button, Checkbox, Space, AutoComplete, DatePicker, Skeleton, Collapse, Switch, Spin } from 'antd';
import { DeleteTwoTone, DragOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import shortid from 'shortid';
import moment from 'moment';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import debounce from 'lodash.debounce';

import { ResumeContext } from '../../../contexts/ResumeContext';


const { Panel } = Collapse;

const ExperienceForm = () => {

    const [value, setValue] = useState('');
    const { resume, setResumeStatus } = useContext(ResumeContext);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [loadings, setLoadings] = useState(false);
    const [expand, setExpand] = useState([0, 1]);
    const [count, setCount] = useState(0);


    //experiences
    const [experiences, setExperiences] = useState({
        experiences: [
            { jobTitle: '', employer: '', website: '', city: '', startDate: null, endDate: null, currentlyWorking: false, summary: '', objid: shortid.generate() }
        ]
    });

    const debounceSave = useCallback(
        debounce((nextvalue) => setExperiences(nextvalue), 600)
        , []
    );


    const debounceSaveDescription = useCallback(
        debounce((index, nextvalue) => setContext(index, nextvalue), 600)
        , []
    );

    const setContext = (index, nextvalue) => {
        if (experiences.experiences[index] && experiences.experiences[index].summary !== undefined) {
            experiences.experiences[index].summary = nextvalue;
            setResumeStatus({ ...resume, ...experiences });
        }

    }


    useEffect(() => {



        if (resume.experiences && resume.experiences.length > 0) {
            let i = 0
            resume.experiences.forEach(row => {
                if (row.startDate != null) {
                    row.startDate = moment(row.startDate);
                }

                if (row.endDate != null) {
                    row.endDate = moment(row.endDate);
                }
                if (!expand.includes(i)) {
                    expand.push(i)
                }
                i++;
            });
            if (i > 3) {
                setExpand([0]);
            }
            setExperiences({ experiences: resume.experiences });
            form.setFieldsValue(experiences);

        } else {
            form.setFieldsValue(experiences);
        }


        setCount(1);
        const timer = setTimeout(() => {
            setLoading(false);
        }, 100);
        setLoadings(false)

        return () => clearTimeout(timer);

    }, []);


    useEffect(() => {

        setLoadings(true)
        if (count == 1) {
            if (experiences.experiences && experiences.experiences.length > 0) {
                const items = experiences.experiences.filter(item => {
                    if (item.jobTitle !== undefined && item.employer !== undefined)
                        return item;
                })
                experiences['experiences'] = items;
            }
            form.setFieldsValue(experiences)
            setResumeStatus({ ...resume, ...experiences });

        }
        setLoadings(false)
    }, [experiences]);



    const onValuesChange = (val, all) => {

        setLoadings(true)
        onFinish();
        if (experiences.experiences && all.experiences) {
            if (experiences.experiences.length != all.experiences.length) {
                setExperiences(all);

                if (!expand.includes(all.experiences.length)) {
                    expand.push(all.experiences.length)
                }
                if (!expand.includes(experiences.experiences.length)) {
                    expand.push(experiences.experiences.length)
                }
            } else {
                debounceSave(all);
            }
        } else {
            debounceSave(all);
        }




    }

    const onDragEnd = (result) => {

        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const items = reorder(
            experiences.experiences,
            result.source.index,
            result.destination.index
        );

        experiences['experiences'] = items;
        setExperiences({ ...experiences })

    }

    const reorder = (list, startIndex, endIndex) => {

        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };



    const editorOnChnage = (val, index) => {

        if (experiences.experiences[index].summary !== undefined) {
            debounceSaveDescription(index, val);
        }


    }

    const onFinish = values => {

    };


    const genExtra = () => (
        <div>
            <DragOutlined
                className="drag-btn"
                onClick={event => {
                    // If you don't want click extra trigger collapse, you can prevent this:
                    event.stopPropagation();
                }}
            />

        </div>
    );

    return (
        <>

            <Skeleton active={true} loading={loading}>
                <Form
                    onFinish={onFinish}
                    layout={"vertical"}
                    wrapperCol={{
                        span: 24
                    }}
                    size={"middle"}
                    form={form}
                    name="header"
                    onValuesChange={onValuesChange}
                >
                    <div className="spin-loader" align="center">
                        {loadings && <Spin size="small" />}
                    </div>

                    <Row className="ant-mt-2" >
                        <Col xl={24} lg={24} md={24} sm={24} xs={24} align="left" >

                            <Form.List name="experiences">
                                {(fields, { add, remove }) => (
                                    <>

                                        <DragDropContext onDragEnd={onDragEnd}>

                                            <Droppable droppableId="item" >
                                                {
                                                    (provided) => (
                                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                                            {fields.map((field, index, arr) => (

                                                                <>
                                                                    {experiences.experiences && experiences.experiences.length > 0 &&
                                                                        <Draggable key={experiences.experiences[index] ? experiences.experiences[index].objid : 'temp'} draggableId={experiences.experiences[index] ? experiences.experiences[index].objid : 'temp'} index={index}>

                                                                            {provided => (
                                                                                <div ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}>


                                                                                    <Collapse defaultActiveKey={expand}
                                                                                        bordered={false}
                                                                                        className="form-panel"
                                                                                        expandIconPosition={"right"}
                                                                                    >
                                                                                        <Panel
                                                                                            className="inner-panel ant-mb-5"
                                                                                            header={(experiences.experiences[index].jobTitle ? experiences.experiences[index].jobTitle + ' - ' : 'Experience') + (experiences.experiences[index].employer ? experiences.experiences[index].employer : '')} key={index} extra={
                                                                                                <div className="section-top-btn">


                                                                                                    <DragOutlined
                                                                                                        className="drag-btn ant-ml-2 ant-mr-1"

                                                                                                        onClick={event => {
                                                                                                            event.stopPropagation();
                                                                                                        }}
                                                                                                    />
                                                                                                    <div>
                                                                                                        {loadings === false && <DeleteTwoTone
                                                                                                            className="remove-btn ant-mr-1"
                                                                                                            twoToneColor="#f5222d"

                                                                                                            onClick={() => remove(field.name)} />}
                                                                                                    </div>
                                                                                                    <div className="ant-mr-5 spin-loader"> {loadings && <Spin size="small" />}</div>
                                                                                                </div>


                                                                                            }


                                                                                        >
                                                                                            <div className="ant-mt-4">
                                                                                                {
                                                                                                    <div key={field.key} >

                                                                                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                                                                            <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gutter-row">
                                                                                                                <Form.Item
                                                                                                                    {...field}
                                                                                                                    label="Job Title"
                                                                                                                    name={[field.name, 'jobTitle']}
                                                                                                                    fieldKey={[field.fieldKey, 'jobTitle']}
                                                                                                                    rules={[{ type: "string", required: true, message: 'Job Title Required' },
                                                                                                                    { max: 100, message: 'Job Title must be maximum 100 characters.' }
                                                                                                                    ]}
                                                                                                                >
                                                                                                                    <Input placeholder="Front-End Web Developer" />
                                                                                                                </Form.Item>

                                                                                                            </Col>
                                                                                                            <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gutter-row" >
                                                                                                                <Form.Item
                                                                                                                    {...field}
                                                                                                                    label="Employer"
                                                                                                                    name={[field.name, 'employer']}
                                                                                                                    fieldKey={[field.fieldKey, 'employer']}
                                                                                                                    rules={[{ type: "string", required: true, message: 'Employer Required' },
                                                                                                                    { max: 100, message: 'Employer must be maximum 100 characters.' }
                                                                                                                    ]}
                                                                                                                >
                                                                                                                    <Input placeholder="ABC Company" />
                                                                                                                </Form.Item>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>

                                                                                                            <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gutter-row" >
                                                                                                                <Form.Item
                                                                                                                    {...field}
                                                                                                                    label="City"
                                                                                                                    name={[field.name, 'city']}
                                                                                                                    fieldKey={[field.fieldKey, 'city']}
                                                                                                                    rules={[{ type: "string" },
                                                                                                                    { max: 100, message: 'City must be maximum 100 characters.' }
                                                                                                                    ]}
                                                                                                                >
                                                                                                                    <Input placeholder="New York" />
                                                                                                                </Form.Item>
                                                                                                            </Col>

                                                                                                            <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gutter-row" >
                                                                                                                <Form.Item
                                                                                                                    {...field}
                                                                                                                    label="Website"
                                                                                                                    name={[field.name, 'website']}
                                                                                                                    fieldKey={[field.fieldKey, 'website']}
                                                                                                                    rules={[{ type: "string" },
                                                                                                                    { max: 100, message: 'Website must be maximum 100 characters.' }
                                                                                                                    ]}
                                                                                                                >
                                                                                                                    <Input placeholder="Website" />
                                                                                                                </Form.Item>
                                                                                                            </Col>

                                                                                                        </Row>


                                                                                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>

                                                                                                            <Col xl={8} lg={8} md={8} sm={24} xs={24} className="gutter-row" align="left">
                                                                                                                <Form.Item
                                                                                                                    {...field}
                                                                                                                    label="Start Date"
                                                                                                                    name={[field.name, 'startDate']}
                                                                                                                    fieldKey={[field.fieldKey, 'startDate']}
                                                                                                                    rules={[{ type: "object", message: 'Start Date' },

                                                                                                                    ]}
                                                                                                                >
                                                                                                                    <DatePicker picker="month" format={'MMM-YYYY'} />
                                                                                                                </Form.Item>

                                                                                                            </Col>
                                                                                                            {experiences.experiences[index].currentlyWorking != true &&
                                                                                                                <Col xl={8} lg={8} md={8} sm={24} xs={24} className="gutter-row" align="left">
                                                                                                                    <Form.Item
                                                                                                                        {...field}
                                                                                                                        label="End Date"
                                                                                                                        name={[field.name, 'endDate']}
                                                                                                                        fieldKey={[field.fieldKey, 'endDate']}
                                                                                                                        rules={[{ type: "object", message: 'End Date' }
                                                                                                                        ]}
                                                                                                                    >
                                                                                                                        <DatePicker picker="month" format={'MMM-YYYY'} />
                                                                                                                    </Form.Item>
                                                                                                                </Col>
                                                                                                            }

                                                                                                            <Col xl={8} lg={8} md={8} sm={24} xs={24} className="gutter-row" align="left">
                                                                                                                <Form.Item
                                                                                                                    {...field}
                                                                                                                    label="Currently Working"
                                                                                                                    name={[field.name, 'currentlyWorking']}
                                                                                                                    fieldKey={[field.fieldKey, 'currentlyWorking']}
                                                                                                                    rules={[{ type: "boolean", message: 'Currently Working' }]}


                                                                                                                >
                                                                                                                    <Switch checked={experiences.experiences[index].currentlyWorking} />
                                                                                                                </Form.Item>
                                                                                                            </Col>
                                                                                                        </Row>


                                                                                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>

                                                                                                            <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gutter-row" align="left">
                                                                                                                {
                                                                                                                    <Form.Item
                                                                                                                        {...field}
                                                                                                                        label="Description"
                                                                                                                        name={[field.name, 'summary']}
                                                                                                                        fieldKey={[field.fieldKey, 'summary']}

                                                                                                                    >

                                                                                                                        <ReactQuill
                                                                                                                            theme="snow"
                                                                                                                            value={experiences.experiences[index].summary}
                                                                                                                            onChange={(newValue, delta, source) => {

                                                                                                                                editorOnChnage(newValue, index)

                                                                                                                            }}
                                                                                                                            modules={{
                                                                                                                                toolbar: [
                                                                                                                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                                                                                                    [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                                                                                                                    { 'indent': '-1' }, { 'indent': '+1' }],
                                                                                                                                    ['link'],
                                                                                                                                    ['clean']
                                                                                                                                ]
                                                                                                                            }}
                                                                                                                            placeholder={"Description"}
                                                                                                                        >

                                                                                                                        </ReactQuill>

                                                                                                                    </Form.Item>
                                                                                                                }
                                                                                                            </Col>
                                                                                                        </Row>


                                                                                                    </div>

                                                                                                }
                                                                                            </div>
                                                                                        </Panel>
                                                                                    </Collapse>
                                                                                </div>

                                                                            )}
                                                                        </Draggable>
                                                                    }
                                                                </>


                                                            ))}
                                                            <div className="spin-loader" align="center">
                                                                {loadings && <Spin size="small" />}
                                                            </div>

                                                            {provided.placeholder}
                                                        </div>
                                                    )
                                                }
                                            </Droppable>
                                            <Form.Item className="add-btn add-btn-align">
                                                <Button type="primary" loading={loadings} onClick={() => add({ jobTitle: '', employer: '', website: '', city: '', startDate: null, endDate: null, currentlyWorking: false, summary: '', objid: shortid.generate() })} icon={<PlusOutlined />}>
                                                    Add Experience
                                                </Button>
                                            </Form.Item>
                                        </DragDropContext>


                                    </>
                                )}
                            </Form.List>
                        </Col>
                    </Row>

                </Form>
            </Skeleton>

        </>
    );
}

export default ExperienceForm;