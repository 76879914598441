import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Row, Col, Form, Input, Button, Checkbox, Space, AutoComplete, DatePicker, Skeleton, Collapse, Switch, Spin } from 'antd';
import { DeleteTwoTone, DragOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import shortid from 'shortid';
import moment from 'moment';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import debounce from 'lodash.debounce';

import { ResumeContext } from '../../../contexts/ResumeContext';


const { Panel } = Collapse;

const CertificatesForm = () => {

    const [value, setValue] = useState('');
    const { resume, setResumeStatus } = useContext(ResumeContext);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [loadings, setLoadings] = useState(false);
    const [expand, setExpand] = useState([0, 1]);


    //experiences
    const [certificates, setCertificates] = useState({
        certificates: [
            { certificateName: '', certificateNo: '', organization: '', url: '', issueDate: null, objid: shortid.generate() }
        ]
    });

    const debounceSave = useCallback(
        debounce((nextvalue) => setCertificates(nextvalue), 300)
        , []
    );


    const debounceSaveDescription = useCallback(
        debounce((index, nextvalue) => setContext(index, nextvalue), 300)
        , []
    );

    const setContext = (index, nextvalue) => {
        if (certificates.certificates[index] && certificates.certificates[index].summary !== undefined) {
            certificates.certificates[index].summary = nextvalue;
            setResumeStatus({ ...resume, ...certificates });
        }

    }


    useEffect(() => {
        if (resume.certificates && resume.certificates.length > 0) {

            resume.certificates.forEach(row => {
                if (row.issueDate != null) {
                    row.issueDate = moment(row.issueDate);
                }
            });

            let i = 0
            i = resume.certificates.length;
            if (i > 3) {
                setExpand([0]);
            }
            setCertificates({ certificates: resume.certificates });
            form.setFieldsValue(certificates);

        } else {
            form.setFieldsValue(certificates);
        }


        const timer = setTimeout(() => {
            setLoading(false);
        }, 300);

        setLoadings(false)

        return () => clearTimeout(timer);

    }, []);


    useEffect(() => {
        setLoadings(true)
        const items = certificates.certificates.filter(item => {
            if (item.certificateName !== undefined)
                return item;
        })
        certificates['certificates'] = items;

        form.setFieldsValue(certificates)
        setResumeStatus({ ...resume, ...certificates });


        const timer = setTimeout(() => {
            setLoadings(false)
        }, 100);

    }, [certificates]);



    const onValuesChange = (val, all) => {

        setLoadings(true)
        onFinish();

        console.log(certificates.certificates.length, all.certificates.length)
        if (certificates.certificates.length != all.certificates.length) {

            setCertificates(all);
            if (!expand.includes(all.certificates.length)) {
                expand.push(all.certificates.length)
            }
            if (!expand.includes(certificates.certificates.length)) {
                expand.push(certificates.certificates.length)
            }

        } else {
            debounceSave(all);
        }



    }

    const onDragEnd = (result) => {

        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const items = reorder(
            certificates.certificates,
            result.source.index,
            result.destination.index
        );

        certificates['certificates'] = items;
        setCertificates({ ...certificates })

    }

    const reorder = (list, startIndex, endIndex) => {

        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };



    const editorOnChnage = (val, index) => {

        if (certificates.certificates[index].summary !== undefined) {
            debounceSaveDescription(index, val);
        }

    }

    const onFinish = values => {
        console.log('Received values of form:', values);
    };


    const genExtra = () => (
        <div>
            <DragOutlined
                className="drag-btn"
                onClick={event => {
                    // If you don't want click extra trigger collapse, you can prevent this:
                    event.stopPropagation();
                }}
            />

        </div>
    );

    return (
        <>

            <Skeleton active={true} loading={loading}>
                <Form
                    onFinish={onFinish}
                    layout={"vertical"}
                    wrapperCol={{
                        span: 24
                    }}
                    size={"middle"}
                    form={form}
                    name="header"
                    onValuesChange={onValuesChange}
                >
                    <div className="spin-loader" align="center">
                        {loadings && <Spin size="small" />}
                    </div>

                    <Row className="ant-mt-6" >
                        <Col xl={24} lg={24} md={24} sm={24} xs={24} align="left" >

                            <Form.List name="certificates">
                                {(fields, { add, remove }) => (
                                    <>

                                        <DragDropContext onDragEnd={onDragEnd}>

                                            <Droppable droppableId="item" >
                                                {
                                                    (provided) => (
                                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                                            {fields.map((field, index, arr) => (

                                                                <>
                                                                    <Draggable key={certificates.certificates[index] ? certificates.certificates[index].objid : 'temp'} draggableId={certificates.certificates[index] ? certificates.certificates[index].objid : 'temp'} index={index}>

                                                                        {provided => (
                                                                            <div ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}>


                                                                                <Collapse defaultActiveKey={expand}
                                                                                    className="form-panel"
                                                                                    expandIconPosition={"right"}
                                                                                >
                                                                                    <Panel

                                                                                        header={certificates.certificates[index].certificateName ? certificates.certificates[index].certificateName : "Certificate"} key={index} extra={
                                                                                            <div className="section-top-btn">

                                                                                                <DragOutlined
                                                                                                    className="drag-btn ant-ml-2 ant-mr-1"

                                                                                                    onClick={event => {
                                                                                                        event.stopPropagation();
                                                                                                    }}
                                                                                                />
                                                                                                <div>
                                                                                                    {loadings === false && <DeleteTwoTone
                                                                                                        className="remove-btn ant-mr-1"
                                                                                                        twoToneColor="#f5222d"

                                                                                                        onClick={() => remove(field.name)} />}
                                                                                                </div>
                                                                                                <div className="ant-mr-5 spin-loader"> {loadings && <Spin size="small" />}</div>
                                                                                            </div>


                                                                                        }


                                                                                        className="ant-mb-5 ">
                                                                                        <div >
                                                                                            {
                                                                                                <div key={field.key} >

                                                                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>


                                                                                                        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gutter-row">
                                                                                                            <Form.Item
                                                                                                                {...field}
                                                                                                                label="Certificate Name"
                                                                                                                name={[field.name, 'certificateName']}
                                                                                                                fieldKey={[field.fieldKey, 'certificateName']}
                                                                                                                rules={[{ type: "string", required: true, message: 'Certificate Name Required' },
                                                                                                                { max: 100, message: 'Certificate Name must be maximum 100 characters.' }]}
                                                                                                            >
                                                                                                                <Input placeholder="SEO Certification" />
                                                                                                            </Form.Item>
                                                                                                        </Col>

                                                                                                        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gutter-row">
                                                                                                            <Form.Item
                                                                                                                {...field}
                                                                                                                label="Organization"
                                                                                                                name={[field.name, 'organization']}
                                                                                                                fieldKey={[field.fieldKey, 'organization']}
                                                                                                                rules={[{ type: "string", message: 'organization Required' },
                                                                                                                { max: 100, message: 'Organization must be maximum 100 characters.' }]}
                                                                                                            >
                                                                                                                <Input placeholder="Google" />
                                                                                                            </Form.Item>
                                                                                                        </Col>

                                                                                                        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gutter-row">
                                                                                                            <Form.Item
                                                                                                                {...field}
                                                                                                                label="Certificate Url"
                                                                                                                name={[field.name, 'url']}
                                                                                                                fieldKey={[field.fieldKey, 'url']}
                                                                                                                rules={[{ type: "string", message: 'url Required' },
                                                                                                                { max: 100, message: 'Certificate Url must be maximum 100 characters.' }]}
                                                                                                            >
                                                                                                                <Input placeholder="" />
                                                                                                            </Form.Item>
                                                                                                        </Col>

                                                                                                        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gutter-row">
                                                                                                            <Form.Item
                                                                                                                {...field}
                                                                                                                label="Certificate No"
                                                                                                                name={[field.name, 'certificateNo']}
                                                                                                                fieldKey={[field.fieldKey, 'certificateNo']}
                                                                                                                rules={[{ type: "string", message: 'certificateNo Required'}   ,
                                                                                                                { max: 100, message: 'Certificate No must be maximum 100 characters.' }]}
                                                                                                            >
                                                                                                                <Input placeholder="JHNJ568565" />
                                                                                                            </Form.Item>
                                                                                                        </Col>

                                                                                                        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gutter-row">
                                                                                                            <Form.Item
                                                                                                                {...field}
                                                                                                                label="Issuied Date"
                                                                                                                name={[field.name, 'issueDate']}
                                                                                                                fieldKey={[field.fieldKey, 'issueDate']}
                                                                                                                rules={[{ type: "object", message: 'Issuied Date Required' }
                                                                                                               ]}
                                                                                                            >
                                                                                                                <DatePicker  format={'YYYY-MM-DD'} />
                                                                                                            </Form.Item>
                                                                                                        </Col>

                                                                                                    </Row>



                                                                                                </div>

                                                                                            }
                                                                                        </div>
                                                                                    </Panel>
                                                                                </Collapse>
                                                                            </div>

                                                                        )}
                                                                    </Draggable>

                                                                </>


                                                            ))}
                                                               <div className="spin-loader" align="center">
                                                                {loadings && <Spin size="small" />}
                                                            </div>
                                                            {provided.placeholder}
                                                        </div>
                                                    )
                                                }
                                            </Droppable>
                                            <Form.Item className=" add-btn add-btn-align">
                                                <Button type="primary" loading={loadings} onClick={() => add({ certificateName: '', certificateNo: '', organization: '', url: '', issueDate: null, objid: shortid.generate() })} icon={<PlusOutlined />}>
                                                    Add Certificate
                                                </Button>
                                            </Form.Item>
                                        </DragDropContext>


                                    </>
                                )}
                            </Form.List>
                        </Col>
                    </Row>


                    <Row>
                        {/* <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Experience
                            </Button>
                            </Form.Item> */}
                    </Row>

                </Form>
            </Skeleton>

        </>
    );
}

export default CertificatesForm;