import React, { useState, useEffect, useContext } from 'react';

import { Row, Col, Typography, Button, Form, Select, Switch, Image, Spin } from 'antd';
import { SpinLoderContext } from '../../../../contexts/SpinLoderContext';
import { CoverletterContext } from '../../../../contexts/CoverLetterContext';
import CoverletterBtn from '../../../actions/CoverletterBtn';

const { Title } = Typography;

const { Option } = Select;

const WorkGaps = () => {


    const [form] = Form.useForm();
    const { spinloading, setSpinloading } = useContext(SpinLoderContext);
    const { coverletter, setCoverletterStatus } = useContext(CoverletterContext);



    const [workGap, setWorkGap] = useState({
        "status": false,
        "workGapDescription": ""
    });


    useEffect(() => {

        setSpinloading(true);

        if (coverletter.workGap) {
            setWorkGap(coverletter.workGap);
            form.setFieldsValue(workGap);

        } else {
            form.setFieldsValue(workGap);
        }

        const timer = setTimeout(() => {
            setSpinloading(false);
        }, 300);

        return () => clearTimeout(timer);
    }, []);


    useEffect(() => {
        setSpinloading(true);
        setCoverletterStatus({ ...coverletter, workGap });
        form.setFieldsValue(workGap);
        const timer = setTimeout(() => {
            setSpinloading(false);
        }, 200);
        return () => clearTimeout(timer);
    }, [workGap]);





    const onValuesChange = (val, all) => {
        console.log(all)
        setWorkGap(all)
        onFinish();
    }

    const onFinish = values => {
        console.log('Received values of form:', values);
    };

    return (
        <div>
            <Row>
                <Col xl={22} lg={24} md={24} sm={24} xs={24} offset={1} align="middle">
                    <div className="spin-loader" align="center">
                        {spinloading && <Spin loading={true} size="small"  />}
                    </div>
                </Col>
            </Row>
            <Row className="ant-pr-1 ant-pl-1">
                <Col xl={23} lg={24} md={24} sm={24} xs={24} align="middle">
                    <div className="form-mt"></div>
                    <Title level={2} className="ant-mb-0">Do you want to explain any work gaps?</Title>
                    {/* <Text className="text-muted">(includes volunteer work, summer jobs, and unofficial jobs)</Text> */}
                    <div className="ant-mt-10 cover-step-wrapper">
                        <Form
                            onFinish={onFinish}
                            layout={"vertical"}
                            wrapperCol={{
                                span: 24
                            }}
                            size={"middle"}
                            form={form}
                            name="workGapsForm"
                            onValuesChange={onValuesChange}
                        >
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                                <Col xl={16} lg={12} md={24} sm={24} xs={24} offset={4}>
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                                        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gutter-row ant-mr-auto ant-ml-auto cover-switch" align="middle">
                                            <Form.Item
                                                label=""
                                                name={'status'}
                                                rules={[{ type: "boolean" }]}
                                            >
                                                <Switch checked={workGap.status} />
                                            </Form.Item>

                                        </Col>

                                        {workGap.status &&
                                            <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gutter-row ant-mr-auto ant-ml-auto" align="left">



                                                <Form.Item
                                                    label=""
                                                    name={'workGapDescription'}
                                                    rules={[
                                                        {
                                                            type: "string",
                                                            required: true,
                                                            message: 'Please select reason',
                                                        },
                                                        { max: 100, message: 'Maximum 100 characters allowed' },
                                                    ]}
                                                >
                                                    <Select
                                                        defaultValue={workGap.workGapDescription}
                                                        style={{ width: "100%" }}
                                                        placeholder="Select reason"
                                                    >
                                                        <Option value="family illness">Family illness</Option>
                                                        <Option value="family accident">Family accident</Option>
                                                        <Option value="personal illness">Personal illness</Option>
                                                        <Option value="personal accident">Personal accident</Option>
                                                        <Option value="traveling">Traveling</Option>
                                                        <Option value="taking care of child">Taking care of child</Option>
                                                        <Option value="laid off-lengthly job search">Laid off-lengthly job search</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        }

                                    </Row>
                                </Col>


                                <Col xl={24} lg={24} md={24} sm={24} xs={24} className="" align="middle">
                                    <div className="cover-buttons-center">
                                        <CoverletterBtn action={"setWorkGaps"} text={"Continue"} />
                                    </div>
                                </Col>

                            </Row>
                        </Form>


                    </div>
                </Col>

            </Row>
        </div>
    )
}

export default WorkGaps;