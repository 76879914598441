import * as React from "react";
import resumeDataHandler from "../handler/ResumeDataHandler";
import { getStoredResumeData } from '../utils/ResumeHelper';

export const ResumeContext = React.createContext();

export const ResumeProvider = props => {
  
    const { resume, setResumeStatus, setUnsetResumeStatus } = resumeDataHandler(
        getStoredResumeData()
    );

    return (
        <ResumeContext.Provider value={{ resume, setResumeStatus, setUnsetResumeStatus }}>
             {props.children}
        </ResumeContext.Provider>
    )

}