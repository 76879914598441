import React, { useContext } from 'react';
import { useHistory } from "react-router-dom";
import MainNav from '../../navigation/MainNav';
import { Row, Col, Button, Typography } from 'antd';
import CoverProgress from '../../navigation/cover-letter/CoverProgress';
import CoverProgressMobile from '../../navigation/cover-letter/CoverProgressMobile';
import EduInfo from '../../cover-letter/cover-letter-form/education/EduInfo';
import EducationQualification from '../../cover-letter/cover-letter-form/education/EducationQualification';
import { CoverletterContext } from '../../../contexts/CoverLetterContext';
import AppMessages from '../../actions/AppMessages';

const { Title } = Typography;



const EducationCover = () => {

    const { coverletter } = useContext(CoverletterContext);


    return (
        <div>
            <MainNav />
            <AppMessages />
            <Row>
                <Col xl={6} lg={6} md={24} sm={24} xs={24} >
                    <CoverProgressMobile />
                    <CoverProgress />
                </Col>
                <Col xl={18} lg={18} md={24} sm={24} xs={24} className="ant-pt-10">

                    {coverletter.progress === "edu-qualification" &&
                        <EducationQualification />
                    }
                    {coverletter.progress === "education-true" &&
                        <EduInfo />
                    }         

                </Col>
            </Row>
        </div>
    )
}

export default EducationCover;