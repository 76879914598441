import React, { useContext } from 'react';
import moment from 'moment';
import cx from 'classnames'
import { ResumeContext } from '../../../../contexts/ResumeContext';
import { TemplateContext } from '../../../../contexts/TemplateContext';
import bootstrap from '../../../../css/bootstrap.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMapMarker, faMobile, } from '@fortawesome/free-solid-svg-icons'
import style from './Jasper.module.css'
import ReactHtmlParser from 'react-html-parser';
const styles = { ...bootstrap, ...style }

const Jasper = () => {
    const Resume = useContext(ResumeContext);
    const ResumeData = (Resume.resume);
    // const highlight = useContext(SideNavContext);

    const TemplateCol = useContext(TemplateContext);
    const Color = (TemplateCol.template.resumeColor);

    return (
        <div className={cx(styles['jasper'], styles[Color])}>
            <div id="header">
                <div className={cx(styles['header'])}>
                    <div className={cx(styles['name-profession'])}>
                        <h2 className={cx(styles['full-name'], styles['mb-0'])}> {ResumeData.personal.firstName} {ResumeData.personal.lastName}</h2>
                        <h3 className={cx(styles['profession'])}>{ResumeData.personal.profession}</h3>
                    </div>

                    <div className={cx(styles['summary'], styles['description'])}>
                        {ReactHtmlParser(ResumeData.summary)}
                    </div>
                    {(ResumeData.personal.email && ResumeData.personal.email.length) ||
                        (ResumeData.personal.phone && ResumeData.personal.phone.length) ||
                        (ResumeData.personal.address && ResumeData.personal.address.length) ?
                        <div className={cx(styles['contact'])}>
                            <div className={cx(styles['justify-content-center'], styles['d-flex'], styles['flex-wrap'], styles['mb-2'])}>

                                {ResumeData.personal.email && ResumeData.personal.email.length ?
                                    <p className={cx(styles['mr-4'], styles['mb-2'])}>
                                        <FontAwesomeIcon icon={faEnvelope} className={cx(styles['mr-2'])} />
                                        {ResumeData.personal.email}
                                    </p> : ""
                                }
                                {ResumeData.personal.phone && ResumeData.personal.phone.length ?
                                    <p className={cx(styles['mr-4'], styles['mb-2'])}>
                                        <FontAwesomeIcon icon={faMobile} className={cx(styles['mr-2'])} />
                                        {ResumeData.personal.phone}
                                    </p>
                                    : ""
                                }
                                {ResumeData.personal.address && ResumeData.personal.address.length ?
                                    <p className={cx(styles['mr-4'], styles['mb-2'])}>
                                        <FontAwesomeIcon icon={faMapMarker} className={cx(styles['mr-2'])} />
                                        {ResumeData.personal.address}
                                    </p>
                                    : ""
                                }

                                {ResumeData.personal.profileLinks ?
                                    <>
                                        {ResumeData.personal.profileLinks.map((ResumeData, index) => (
                                            <p className={cx(styles['mr-3'], styles['mb-1'])} key={index}>
                                                <span className={cx(styles['font-weight-bold'])} key={'website' + ResumeData.website}>{ResumeData.website}</span>
                                                {ResumeData.website && ResumeData.websiteLink ? <span className={cx(styles['mx-1'])}>-</span> : ""}
                                                <a href={ResumeData.websiteLink} rel="noopener noreferrer" target="_blank">
                                                    <span key={'websiteLink' + ResumeData.websiteLink}>{ResumeData.websiteLink}</span>
                                                </a>
                                            </p>

                                        ))}
                                    </>
                                    : ""
                                }

                            </div>
                        </div>
                        : ""
                    }
                </div>
                <div className={cx(styles['header-space'])}></div>
                <div className={cx(styles['clear'])}></div>
            </div>
{/* {console.log( ResumeData.softskills[0].skill,"skills")} */}
            { (ResumeData.hardskills || ResumeData.softskills) ?
                <>
                    { (ResumeData.softskills.length && ResumeData.softskills[0].skill.length) ||( ResumeData.hardskills.length &&   ResumeData.hardskills[0].skill.length) ?
                        <div id="skills">
                            <div className={cx(styles['skills'])}>
                                <h4 className={cx(styles['heading'])}>Skills</h4>
                                <div className={cx(styles['d-flex'], styles['flex-wrap'], styles['flex-col'])}>
                                    {(ResumeData.hardskills && ResumeData.hardskills.length && ResumeData.hardskills[0].skill.length) ?
                                        <>
                                            {ResumeData.hardskills.map((ResumeData, index) => (
                                                <div key={index}>
                                                    <p className={cx(styles['skill-unit'], styles['mr-2'], styles['mb-1'])} key={'skill' + ResumeData.skill}>{ResumeData.skill}</p>
                                                </div>
                                            ))}
                                        </> : ""
                                    }
                                    {(ResumeData.softskills && ResumeData.softskills.length && ResumeData.softskills[0].skill.length) ?
                                        <>
                                            {ResumeData.softskills.map((ResumeData, index) => (
                                                <div key={index}>
                                                    <p className={cx(styles['skill-unit'], styles['mr-2'], styles['mb-1'])} key={'skill' + ResumeData.skill}>{ResumeData.skill}</p>
                                                </div>
                                            ))}
                                        </> : ""
                                    }
                                </div>
                            </div>
                            <div className={cx(styles['space'])}></div>
                            <div className={cx(styles['clear'])}></div>
                        </div>
                        : ""}
                </> : ""
            }

            {(ResumeData.experiences && ResumeData.experiences.length && (ResumeData.experiences[0].jobTitle || ResumeData.experiences[0].employer || ResumeData.experiences[0].city)) ?
                <div id="experience">
                    <div className={cx(styles['experience'])}>
                        <h4 className={cx(styles['heading'])}>Experience</h4>
                        {ResumeData.experiences.map((ResumeData, index) => (
                            <div className={cx(styles['section'])} key={index}>
                                <p className={cx(styles['font-weight-bold'], styles['mb-0'])} key={'jobTitle' + ResumeData.jobTitle}>{ResumeData.jobTitle}</p>
                                <a href={ResumeData.website} rel="noopener noreferrer" target="_blank">
                                    <p className={cx(styles['mb-0'])} key={'employer' + ResumeData.employer}>{ResumeData.employer}</p>
                                </a>
                                <div className={cx(styles['d-flex'], styles['justify-content-between'])}>
                                    <p className={cx(styles['mb-2'])}>
                                        {ResumeData.startDate === null ? "" : <span key={'startDate' + ResumeData.startDate}>{moment(ResumeData.startDate).format('MMM YYYY')}</span>}
                                        {ResumeData.startDate && (ResumeData.endDate || ResumeData.currentlyWorking === true) ? <span className={cx(styles['mx-1'])}>-</span> : ""}
                                        {ResumeData.currentlyWorking !== true ? (ResumeData.endDate === null ? "" : <span key={'endDate' + ResumeData.endDate}>{moment(ResumeData.endDate).format('MMM YYYY')}</span>) : "Present"}
                                    </p>
                                    <p className={cx(styles['mb-2'])} key={'city' + ResumeData.city}>{ResumeData.city}</p>
                                </div>
                                {(ResumeData.summary == `<p>&nbsp;</p>` || ResumeData.summary == `<p><br></p>`) ? <></> :
                                    <div className={cx(styles['description'])} key={'summaryHtml' + ResumeData.summary}>
                                        {ReactHtmlParser(ResumeData.summary)}
                                    </div>
                                }
                                <div className={cx(styles['space-end'])}></div>
                            </div>
                        ))}
                    </div>
                    <div className={cx(styles['space-end'])}></div>
                    <div className={cx(styles['clear'])}></div>
                </div> : ""
            }

            {(ResumeData.educations && ResumeData.educations.length && (ResumeData.educations[0].qualification || ResumeData.educations[0].field || ResumeData.educations[0].city)) ?
                <div id="education">
                    <div className={cx(styles['education'])}>
                        <h4 className={cx(styles['heading'])}>Education</h4>
                        {ResumeData.educations.map((ResumeData, index) => (
                            <div className={cx(styles['section'])} key={index}>
                                <p className={cx(styles['font-weight-bold'], styles['mb-0'])}>Msc in Businesss Management </p>
                                <a href={ResumeData.website} rel="noopener noreferrer" target="_blank">
                                    <p className={cx(styles['mb-0'])} key={'insttitute' + ResumeData.institute}>{ResumeData.institute}</p>
                                </a>
                                <div className={cx(styles['d-flex'], styles['justify-content-between'])}>
                                    <p className={cx(styles['mb-2'])}>
                                        {ResumeData.startDate === null ? "" : <span key={'startDate' + ResumeData.startDate}>{moment(ResumeData.startDate).format('MMM YYYY')}</span>}
                                        {ResumeData.startDate && (ResumeData.endDate || ResumeData.stillStudying === true) ? <span className={cx(styles['mx-1'])}>-</span> : ""}
                                        {ResumeData.stillStudying !== true ? (ResumeData.endDate === null ? "" : <span key={'endDate' + ResumeData.endDate}>{moment(ResumeData.endDate).format('MMM YYYY')}</span>) : "Present"}
                                    </p>
                                    <p className={cx(styles['mb-2'])} key={'city' + ResumeData.city}>{ResumeData.city}</p>
                                </div>
                                {(ResumeData.summary == `<p>&nbsp;</p>` || ResumeData.summary == `<p><br></p>`) ? <></> :
                                    <div className={cx(styles['description'])} key={'summaryHtml' + ResumeData.summary}>
                                        {ReactHtmlParser(ResumeData.summary)}
                                    </div>
                                }
                                <div className={cx(styles['space-end'])}></div>
                            </div>))}
                    </div>
                    <div className={cx(styles['space-end'])}></div>
                    <div className={cx(styles['clear'])}></div>
                </div> : ""
            }

            {
                (ResumeData.languages && ResumeData.languages.length && ResumeData.languages[0].language.length) ?
                    <div id="languages">
                        <div className={cx(styles["languages"])}>
                            <h4 className={cx(styles['heading'])}>Languages</h4>
                            <div className={cx(styles['d-flex'], styles['flex-wrap'], styles['flex-col'])}>
                                {ResumeData.languages.map((ResumeData, index) => (
                                    <div className={cx(styles["lan-unit"])}>
                                        <p className={cx(styles['font-weight-bold'], styles['mb-0'])} key={'language' + ResumeData.language}>{ResumeData.language}</p>
                                        <p className={cx(styles['small-p'], styles['mb-0'], styles['text-dark'])} key={'language' + ResumeData.proficiencyLevel}>{ResumeData.proficiencyLevel}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={cx(styles['space'])}></div>
                        <div className={cx(styles['clear'])}></div>
                    </div> : ""
            }

            {(ResumeData.certificates && ResumeData.certificates.length && ResumeData.certificates[0].certificateName.length) ?
                <div id="certificates">
                    <div className={cx(styles["languages"])}>
                        <h4 className={cx(styles['heading'])}>Certificates</h4>
                        {ResumeData.certificates.map((ResumeData, index) => (
                            <div className={cx(styles["section"])}>
                                <p className={cx(styles['font-weight-bold'], styles['mb-0'])} key={'certificateName' + ResumeData.certificateName}>{ResumeData.certificateName}</p>
                                <p className={cx(styles["mb-0"])}>

                                    <span className={cx(styles["font-weight-bold"])} key={'organization' + ResumeData.organization}>{ResumeData.organization}</span>
                                    {ResumeData.organization && ResumeData.certificateNo ? <span className={cx(styles["mx-1"])}>-</span> : ""}
                                    <span key={'certificateNo' + ResumeData.certificateNo}>{ResumeData.certificateNo}</span>
                                    {ResumeData.issueDate != null ?
                                        <>
                                            {(ResumeData.organization || ResumeData.certificateNo) && ResumeData.issueDate ? <span className={cx(styles["mx-1"])}>/</span> : ""}
                                            <span key={'issueDate' + ResumeData.issueDate}>{moment(ResumeData.issueDate).format('DD MMM YYYY')}</span>
                                        </> : ""
                                    }

                                </p>
                                <a href={ResumeData.url} rel="noopener noreferrer" target="_blank">
                                    <p className={cx(styles["mb-0"], styles["text-muted"])} key={'url' + ResumeData.url}>{ResumeData.url}</p>
                                </a>
                                <div className={cx(styles['space-end'])}></div>
                            </div>
                        ))}
                    </div>
                    <div className={cx(styles['space-end'])}></div>
                    <div className={cx(styles['clear'])}></div>
                </div> : ""
            }

            {(ResumeData.customs && ResumeData.customs.length && ResumeData.customs[0].sectionTitle.length) ?
                <div id="custom-section">
                    {ResumeData.customs.map((ResumeData, index) => (
                        <div className={cx(styles['custom-section'])}>
                            <h4 className={cx(styles['heading'])} key={'sectionTitle' + ResumeData.sectionTitle}>{ResumeData.sectionTitle}</h4>
                            <div className={cx(styles['description'])}>
                                {(ResumeData.summary == `<p>&nbsp;</p>` || ResumeData.summary == `<p><br></p>`) ? <></> :
                                    <div className={cx(styles['description'], styles['mt-2'])} key={'summaryHtml' + ResumeData.summary}>
                                        {ReactHtmlParser(ResumeData.summary)}
                                    </div>}
                            </div>
                            <div className={cx(styles['space'])}></div>
                            <div className={cx(styles['clear'])}></div>
                        </div>
                    ))}

                </div> : ""
            }

            {(ResumeData.references && ResumeData.references.length && ResumeData.references[0].fullName.length) ?
                <div id="references">
                    <div className={cx(styles['references'])}>
                        <h4 className={cx(styles['heading'])}>References</h4>
                        <div className={cx(styles['d-flex'])}>
                            {ResumeData.references.map((ResumeData, index) => (
                                <div className={cx(styles['section'], styles['w-50'])}>
                                    <p className={cx(styles['mb-0'], styles['font-weight-bold'])} key={"fullName" + ResumeData.fullName}>{ResumeData.fullName}</p>
                                    <p key={"position" + ResumeData.occupation} className={cx(styles['mb-0'])}>{ResumeData.occupation}</p>
                                    <p key={"company" + ResumeData.company} className={cx(styles['mb-0'])}>{ResumeData.company}</p>
                                    <p key={"email" + ResumeData.email} className={cx(styles['mb-0'])}>{ResumeData.email}</p>
                                    <p key={"phone" + ResumeData.phone} className={cx(styles['mb-0'])}>{ResumeData.phone}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                : ""
            }



        </div >
    )

}

export default Jasper;