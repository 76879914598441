import * as React from "react";

import sessionDataHandler from "../handler/SessionDataHandler";


import { getStoredSessionData } from '../utils/SessionHelper';


export const SessionContext = React.createContext();


export const SessionProvider = props => {

    const { session, setSessionStatus, setUnsetSessionStatus } = sessionDataHandler(
        getStoredSessionData()
    );

    return (
        <SessionContext.Provider value={{ session, setSessionStatus, setUnsetSessionStatus }}>
             {props.children}
        </SessionContext.Provider>
    )
}