import React, { useState } from "react";

export const SpinLoderContext = React.createContext();

export const SpinLoderContextProvider = (props) => {

    const [spinloading, setSpinloading] = useState();


    return (
        <SpinLoderContext.Provider value={{ spinloading, setSpinloading }}>
            {props.children}
        </SpinLoderContext.Provider>
    );
}

