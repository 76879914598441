export const JobTitles = () => {
    return [
        'eCommerce Marketing Specialist',
        'Marketing Specialist',
        'Marketing Manager',
        'Marketing Director',
        'Graphic Designer',
        'Marketing Research Analyst',
        'Marketing Communications Manager',
        'Marketing Consultant',
    ];
};