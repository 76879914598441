import axios from 'axios';
import RCError from '../utils/exception/RCError';

const url = 'https://api.resumoz.com/api/v1';
// const url = 'http://localhost:5000/api/v1';


export const syncResumeData = async (resuemData) => {

    try {
        return await axios.post(`${url}/resume`, resuemData);
    } catch (error) {
        throw new RCError(error.response);
    }
}

export const getPDF = async (session) => {
    try {
        const res = await axios.post(`${url}/resume/generator/pdf`, { 'session': session }, { responseType: 'blob' });
        return new Blob([res.data], { type: 'application/pdf' });
    } catch (error) {
        throw new RCError(error.response);
    }
}


/**
 * Cover letter
 */

export const getLetterData = async (sesstion) => {
    try {
        return await axios.get(`${url}/cover-letter` , { headers: { 'session': JSON.stringify(sesstion) } } );
    } catch (error) {
        throw new RCError(error.response);
    }
}

export const syncLetterData = async (letterData) => {
    try {
        return await axios.put(`${url}/cover-letter/query`, letterData);
    } catch (error) {
        throw new RCError(error.response);
    }
}

export const getLetterBody = async (sesstion) => {
    try {
        const body = await axios.get(`${url}/cover-letter/body` , { headers: { 'sessionID': sesstion.id } } );
        return body.data;
    } catch (error) {
        throw new RCError(error.response);
    }
}

export const getPDcoverLetter = async (session) => {
    try {
        const res = await axios.post(`${url}/cover-letter/generator/pdf`, { 'session': session }, { responseType: 'blob' });
        return new Blob([res.data], { type: 'application/pdf' });
    } catch (error) {
        throw new RCError(error.response);
    }
}