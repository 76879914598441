import React, { useState } from "react";

export const MessageContext = React.createContext();

export const MessageContextProvider = (props) => {

    const [message, setMessage] = useState();


    return (
        <MessageContext.Provider value={{ message, setMessage }}>
            {props.children}
        </MessageContext.Provider>
    );
}

