import React, { useState, useContext, useEffect } from 'react';

import { Row, Col, Typography, Button, Form, Select, Switch } from 'antd';
import { SpinLoderContext } from '../../../../contexts/SpinLoderContext';
import { CoverletterContext } from '../../../../contexts/CoverLetterContext';
import CoverletterBtn from '../../../actions/CoverletterBtn';
const { Title, Text } = Typography;
const { Option } = Select;

const Describe = () => {

    const [form] = Form.useForm();
    const { spinloading, setSpinloading } = useContext(SpinLoderContext);
    const [describe, setDescribe] = useState({ describeYou: '', describeOthers: '' });
    const { coverletter, setCoverletterStatus } = useContext(CoverletterContext);

    useEffect(() => {

        if (coverletter.describe) {
            setDescribe(coverletter.describe);
            form.setFieldsValue(coverletter.describe);
        } else {
            form.setFieldsValue(describe);
        }
        const timer = setTimeout(() => {
            setSpinloading(false);
        }, 300);
        return () => clearTimeout(timer);
    }, []);


    useEffect(() => {


        setSpinloading(true)
        form.setFieldsValue(describe)
        setCoverletterStatus({ ...coverletter, describe });
        const timer = setTimeout(() => {
            setSpinloading(false)
        }, 200);

        return () => clearTimeout(timer);
    }, [describe]);


    const onValuesChange = (val, all) => {
        setDescribe(all);
        onFinish();
    }

    const onFinish = values => {

    };


    return (
        <div>
            <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24} align="middle">
                    <div className="form-mt"></div>
                    <Title level={2} className="ant-mb-0">Which word describes you?</Title>
                    <Text className="text-muted">Choose the word that describes you best.</Text>
                </Col>
            </Row>

            <Form
                onFinish={onFinish}
                layout={"vertical"}
                wrapperCol={{
                    span: 24
                }}
                size={"middle"}
                form={form}
                name="describe"
                onValuesChange={onValuesChange}
            >
                <Row>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24} align="middle">
                        <div className="ant-mt-6 cover-step-wrapper">

                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >

                                <Col xl={{ span: 12, offset: 6 }} lg={24} md={24} sm={24} xs={24} className="gutter-row ant-ml-auto ant-mr-auto" align="left">
                                    <Form.Item
                                        label=""
                                        name="describeYou"
                                        defaultValue={describe.describeYou}
                                        rules={[
                                            {
                                                type: "string",
                                                message: 'Please select your reason',
                                            },{ max: 100, message: 'Reason must be maximum 100 characters.' },
                                        ]}
                                    >
                                        <Select
                                            style={{ width: '100%' }}
                                            placeholder="Select"
                                        >
                                            <Option value="adaptable">Adaptable</Option>
                                            <Option value="detailed-oriented">Detailed-Oriented</Option>
                                            <Option value="diligent">Diligent</Option>
                                            <Option value="efficient">Efficient</Option>
                                            <Option value="forcused">Forcused</Option>
                                            <Option value="hard-working">Hard-Working</Option>
                                            <Option value="proactive">Proactive</Option>
                                            <Option value="quality-oriented">Quality-Oriented</Option>
                                            <Option value="results-oriented">Results-Oriented</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>



                            </Row>

                        </div>
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24} align="middle" className="ant-mt-10">
                        <Title level={2} className=" ant-mb-0">How would others describes you?</Title>
                        <Text className="text-muted">Choose a word others would use to describe you.</Text>
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24} align="left" >
                        <div className="ant-mt-6 cover-step-wrapper">

                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >

                                <Col xl={{ span: 12, offset: 6 }} lg={24} md={24} sm={24} xs={24} align="left" className="ant-ml-auto ant-mr-auto">
                                    <Form.Item
                                        label=""
                                        name="describeOthers"
                                        defaultValue={describe.describeOthers}
                                        rules={[
                                            {
                                                type: "string",
                                                message: 'Please select your reason',
                                            },{ max: 100, message: 'Reason must be maximum 100 characters.' },
                                        ]}
                                    >
                                        <Select
                                            style={{ width: "100%" }}
                                            placeholder="Select"
                                        >
                                            <Option value="adaptable">Adaptable</Option>
                                            <Option value="detailed-oriented">Detailed-Oriented</Option>
                                            <Option value="diligent">Diligent</Option>
                                            <Option value="efficient">Efficient</Option>
                                            <Option value="forcused">Forcused</Option>
                                            <Option value="hard-working">Hard-Working</Option>
                                            <Option value="proactive">Proactive</Option>
                                            <Option value="quality-oriented">Quality-Oriented</Option>
                                            <Option value="results-oriented">Results-Oriented</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                            </Row>



                        </div>
                    </Col>
                </Row>
            </Form>
            <Row>
                <Col xl={{ span: 16, offset: 4 }} lg={{ span: 16, offset: 4 }} md={{ span: 16, offset: 4 }} sm={24} xs={24} className="gutter-row" align="middle">
                    <div className="cover-buttons-center">
                        <CoverletterBtn action={"setDescribe"} text={"Continue"}></CoverletterBtn>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Describe;