import { Button } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useHistory } from "react-router-dom";
/* API */
import { syncLetterData } from '../../api';
import { CoverletterContext } from '../../contexts/CoverLetterContext';
import { MessageContext } from '../../contexts/MessageContext';
import { SessionContext } from '../../contexts/SessionContext';
import { SpinLoderContext } from '../../contexts/SpinLoderContext';
import { TemplateContext } from '../../contexts/TemplateContext';





const CoverletterBtn = (props) => {

    const { coverletter, setCoverletterStatus } = useContext(CoverletterContext);
    const { setSpinloading } = useContext(SpinLoderContext);
    const { session } = useContext(SessionContext);
    const { template } = useContext(TemplateContext);
    const { setMessage } = useContext(MessageContext);


    const history = useHistory();

    useEffect(() => {



    }, [])


    const action = async () => {

        const coverobj = { ...coverletter, session, template };

        if (props.action === 'setExperience') {

            setSpinloading(true);

            try {

                await syncLetterData(coverobj);
                setTimeout(() => {
                    setSpinloading(false);
                    const progress = { progress: "work-experience" };
                    const experience = { experience: true };
                    setCoverletterStatus({ ...coverletter, ...progress, ...experience });
                }, 200);


            } catch (error) {
                //setMessage(error.message);
                setMessage({ data: { errorMessage: "Please try again later." }, status: 422 });
                setTimeout(() => {
                    setSpinloading(false);
                }, 200);

            }

        }

        if (props.action === 'setNoExperience') {
            setSpinloading(true);

            try {
                await syncLetterData(coverobj);
                setCoverletterStatus({ ...coverletter, "experience": false, progress: "edu-qualification", step: 'education' });
                setTimeout(() => {
                    setSpinloading(false);
                }, 200);
                history.push(`/cover-letter/education`);
            } catch (error) {
                setMessage({ data: { errorMessage: "Please try again later." }, status: 422 });
                setTimeout(() => {
                    setSpinloading(false);
                }, 200);
            }
        }

        if (props.action === 'expContinue') {

            setSpinloading(true);

            try {
                await syncLetterData(coverobj);
                const progress = { progress: "job" };
                setTimeout(() => {
                    setSpinloading(false);
                }, 200);
                setCoverletterStatus({ ...coverletter, ...progress });
            } catch (error) {
                setMessage({ data: { errorMessage: "Please try again later." }, status: 422 });
                setTimeout(() => {
                    setSpinloading(false);
                }, 200);
            }
        }

        if (props.action === 'jobContinue') {

            setSpinloading(true);

            try {
                await syncLetterData(coverobj);
                const progress = { progress: "skills" };
                setTimeout(() => {
                    setSpinloading(false);
                }, 200);
                setCoverletterStatus({ ...coverletter, ...progress });
            } catch (error) {
                setMessage({ data: { errorMessage: "Please try again later." }, status: 422 });
                setTimeout(() => {
                    setSpinloading(false);
                }, 200);
            }
        }


        if (props.action === 'setSkills') {

            setSpinloading(true);

            try {
                await syncLetterData(coverobj);
                const progress = { progress: "work-gaps" };
                setTimeout(() => {
                    setSpinloading(false);
                }, 200);
                setCoverletterStatus({ ...coverletter, ...progress });
            } catch (error) {
                setMessage({ data: { errorMessage: "Please try again later." }, status: 422 });
                setTimeout(() => {
                    setSpinloading(false);
                }, 200);
            }
        }


        if (props.action === 'setWorkGaps') {

            setSpinloading(true);
            try {
                await syncLetterData(coverobj);
                const progress = { progress: "edu-qualification" };
                const step = { step: 'education' }
                setCoverletterStatus({ ...coverletter, ...progress, ...step });

                setTimeout(() => {
                    setSpinloading(false);
                    history.push(`/cover-letter/education`);
                }, 200);


            } catch (error) {
                setMessage({ data: { errorMessage: "Please try again later." }, status: 422 });
                setTimeout(() => {
                    setSpinloading(false);
                }, 200);
            }
        }


        /**
         *  Education Steps
         */

        if (props.action === 'setHaveEdu') {

            setSpinloading(true);

            try {

                await syncLetterData(coverobj);
                setTimeout(() => {
                    setSpinloading(false);
                    const progress = { progress: "education-true" };
                    const education = { education: 'yes' };
                    setCoverletterStatus({ ...coverletter, ...progress, ...education });
                }, 200);


            } catch (error) {
                //setMessage(error.message);
                setMessage({ data: { errorMessage: "Please try again later." }, status: 422 });
                setTimeout(() => {
                    setSpinloading(false);
                }, 200);

            }

        }

        if (props.action === 'setStillEnu') {

            setSpinloading(true);

            try {

                await syncLetterData(coverobj);
                setTimeout(() => {
                    setSpinloading(false);
                    const progress = { progress: "education-true" };
                    const education = { education: 'still' };
                    setCoverletterStatus({ ...coverletter, ...progress, ...education });
                }, 200);


            } catch (error) {
                //setMessage(error.message);
                setMessage({ data: { errorMessage: "Please try again later." }, status: 422 });
                setTimeout(() => {
                    setSpinloading(false);
                }, 200);

            }

        }

        if (props.action === 'setDoNotHaveEdu') {

            setSpinloading(true);

            try {

                await syncLetterData(coverobj);
                setTimeout(() => {
                    setSpinloading(false);
                    const progress = { progress: "soft-skills" };
                    const step = { step: 'skills' }
                    const education = { education: 'no' };
                    setCoverletterStatus({ ...coverletter, ...progress, ...step, ...education });
                    history.push(`/cover-letter/skills`);
                }, 200);


            } catch (error) {
                //setMessage(error.message);
                setMessage({ data: { errorMessage: "Please try again later." }, status: 422 });
                setTimeout(() => {
                    setSpinloading(false);
                }, 200);

            }

        }


        if (props.action === 'eduInfo') {

            setSpinloading(true);

            try {

                await syncLetterData(coverobj);
                setTimeout(() => {
                    setSpinloading(false);
                    const progress = { progress: "soft-skills" };
                    const step = { step: 'skills' }
                    setCoverletterStatus({ ...coverletter, ...progress, ...step });
                    history.push(`/cover-letter/skills`);
                }, 200);


            } catch (error) {
                //setMessage(error.message);
                setMessage({ data: { errorMessage: "Please try again later." }, status: 422 });
                setTimeout(() => {
                    setSpinloading(false);
                }, 200);

            }

        }



        if (props.action === 'setSoftSkills') {

            setSpinloading(true);

            try {

                await syncLetterData(coverobj);
                setTimeout(() => {
                    setSpinloading(false);
                    const progress = { progress: "describe" };
                    const step = { step: 'skills' }
                    setCoverletterStatus({ ...coverletter, ...progress, ...step });
                    history.push(`/cover-letter/skills`);
                }, 200);


            } catch (error) {
                //setMessage(error.message);
                setMessage({ data: { errorMessage: "Please try again later." }, status: 422 });
                setTimeout(() => {
                    setSpinloading(false);
                }, 200);

            }

        }


        if (props.action === 'setDescribe') {

            setSpinloading(true);

            try {

                await syncLetterData(coverobj);
                setTimeout(() => {
                    setSpinloading(false);
                    const progress = { progress: "personal" };
                    const step = { step: 'personal' }
                    setCoverletterStatus({ ...coverletter, ...progress, ...step });
                    history.push(`/cover-letter/personal`);
                }, 200);


            } catch (error) {
                //setMessage(error.message);
                setMessage({ data: { errorMessage: "Please try again later." }, status: 422 });
                setTimeout(() => {
                    setSpinloading(false);
                }, 200);

            }

        }


        if (props.action === 'setPersonal') {

            setSpinloading(true);
            try {
                await syncLetterData(coverobj);
                setTimeout(() => {
                    setSpinloading(false);
                    const progress = { progress: "apply" };
                    const step = { step: 'personal' }
                    setCoverletterStatus({ ...coverletter, ...progress, ...step });
                }, 200);

                history.push(`/cover-letter/personal`);

            } catch (error) {
            
                setMessage({ data: { errorMessage: "Please try again later." }, status: 422 });
                setTimeout(() => {
                    setSpinloading(false);
                }, 200);

            }

        }

        if (props.action === 'setApply') {

            setSpinloading(true);
            try {
                await syncLetterData(coverobj);
                setTimeout(() => {
                    setSpinloading(false);
                    const progress = { progress: "finalize" };
                    const step = { step: 'finalize' }
                    setCoverletterStatus({ ...coverletter, ...progress, ...step });
                    history.push(`/cover-letter/finalize`);
                }, 200);

            } catch (error) {
                //setMessage(error.message);
                setMessage({ data: { errorMessage: "Please try again later." }, status: 422 });
                setTimeout(() => {
                    setSpinloading(false);
                }, 200);

            }

        }


        

    }



    return (
        <>
            <Button className="cover-app-btn ant-pt-1" type="primary" size="large" onClick={action}>{props.text}</Button>
        </>
    );

}

export default CoverletterBtn;