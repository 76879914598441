import React, { useState, useContext, useEffect } from 'react';
import { Form, Select, Col, Row } from 'antd';
// import { SpinLoderContext } from '../../contexts/SpinLoderContext';
import { TemplateContext } from '../../contexts/TemplateContext';

import { useLocation } from 'react-router-dom';

const TemplateChange = () => {
    const location = useLocation();
    const [selectedTemplate, setSelectedTemplate] = useState();
    // const { spinloading, setSpinloading } = useContext(SpinLoderContext);
    const [form] = Form.useForm();

    const { template, setTemplateStatus } = useContext(TemplateContext);

    const [templates] = useState([
        'diamond', 'sapphire', 'jasper'
    ]);


    const onValuesChange = (val, all) => {
        if (location.pathname == "/resume/finalize") {
            let resTemp = { "resumeTemplateName": all.selectedTemplate };
            setTemplateStatus({ ...template, ...resTemp });
            onFinish();
        } else if (location.pathname == "/cover-letter/finalize") {
            let coverTemp = { "coverLetterTemplateName": all.selectedTemplate };
            setTemplateStatus({ ...template, ...coverTemp });
            onFinish();
        }
    }

    const onFinish = values => {

    };



    return (
        <Form
            onFinish={onFinish}
            layout={"vertical"}
            wrapperCol={{
                span: 24
            }}
            size={"middle"}
            form={form}
            name="temp"
            onValuesChange={onValuesChange}

        >
            <Row className="ant-mt-2" align="middle" justify="center">
                <Col xl={10} lg={11} md={12} sm={24} xs={24} >
                    <span><strong>Change template</strong></span>
                </Col>
                <Col xl={11} lg={11} md={12} sm={24} xs={24} >
                    <Form.Item
                        className="ant-mb-0 d-flex template-select"
                        name={'selectedTemplate'}
                        rules={[
                            {
                                type: "string",
                                required: true,
                                message: 'Please select template',
                            },
                            { max: 100, message: 'Maximum 100 characters allowed' },
                        ]}
                    >

                        <Select
                            defaultValue={template.resumeTemplateName}
                            style={{ width: "100%" }}
                            placeholder="template"
                            // bordered={false}
                        >
                            {templates.map((option) => (
                                <Select.Option key={option} value={option} style={{ textTransform: "capitalize" }}>{option}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                </Col>
            </Row>
        </Form>


    )
}

export default TemplateChange;
