import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Typography, Button, Image, Input, InputNumber, Form, Select, Spin } from 'antd';
import CoverletterBtn from '../../../actions/CoverletterBtn';
import { SpinLoderContext } from '../../../../contexts/SpinLoderContext';
import { CoverletterContext } from '../../../../contexts/CoverLetterContext';


const { Title, Text } = Typography;
const { Option } = Select;
const EducationQualification = () => {

    const { spinloading } = useContext(SpinLoderContext);

    return (
        <div className="">
                <>
                    <Row>
                        <Col xl={22} lg={24} md={24} sm={24} xs={24} offset={1} align="middle">
                            <div className="spin-loader" align="center">
                                {spinloading && <Spin loading={true} size="small"  />}
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={22} lg={24} md={24} sm={24} xs={24} className="main-m-top" align="middle" offset={1}>
                            <Image
                                width={"40%"}
                                src="/assets/img/app/work-experience.png"
                                className="dash-img ant-mb-7"
                            />
                            <Title level={2} className=" ant-mb-6">Do you have an education qualification?</Title>


                        </Col>
                        <Col xl={22} lg={24} md={24} sm={24} xs={24} className="main-m-top" align="middle" offset={1}>
                            <div className="cover-buttons">
                                <CoverletterBtn action={"setHaveEdu"} text={"Yes, I have"}></CoverletterBtn>
                                <CoverletterBtn action={"setStillEnu"} text={"Still Entroll"}></CoverletterBtn>
                                <CoverletterBtn action={"setDoNotHaveEdu"} text={"No, I haven't"}></CoverletterBtn> 
                            </div>
                        </Col>

                    </Row>
                </>
            
        </div>
    )
}

export default EducationQualification;