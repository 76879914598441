import React, {useContext} from 'react';
import MainNav from '../../navigation/MainNav';
import { Row, Col, Button, Typography } from 'antd';
import CoverProgress from '../../navigation/cover-letter/CoverProgress';
import CoverProgressMobile from '../../navigation/cover-letter/CoverProgressMobile';
import Personal from '../../cover-letter/cover-letter-form/general/Personal';
import { CoverletterContext } from '../../../contexts/CoverLetterContext';
import PossitionApply from '../../cover-letter/cover-letter-form/general/PossitionApply';
import AppMessages from '../../actions/AppMessages';



const GeneralCover = () => {

    const { coverletter } = useContext(CoverletterContext);

    return (
        <div>
            <MainNav />
            <AppMessages />
            <Row>
                <Col xl={6} lg={6} md={24} sm={24} xs={24} >
                    <CoverProgressMobile />
                    <CoverProgress />
                </Col>
                <Col xl={18} lg={18} md={24} sm={24} xs={24} className="ant-mt-10">
                    {coverletter.progress === "personal" &&
                        <Personal />
                    }
                    {coverletter.progress === "apply" &&
                        <PossitionApply />
                    }

                </Col>
            </Row>
        </div>
    )
}

export default GeneralCover;