import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Row, Col, Form, Input, Button, Checkbox, Space, AutoComplete, DatePicker, Skeleton, Collapse, Switch, Spin } from 'antd';
import { DeleteTwoTone, DragOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import shortid from 'shortid';
import moment from 'moment';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import debounce from 'lodash.debounce';

import { ResumeContext } from '../../../contexts/ResumeContext';


const { Panel } = Collapse;

const CustomsForm = () => {

    const [value, setValue] = useState('');
    const { resume, setResumeStatus } = useContext(ResumeContext);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [loadings, setLoadings] = useState(false);
    const [expand, setExpand] = useState([0, 1]);


    //experiences
    const [customs, setCustoms] = useState({
        customs: [
            { sectionTitle: '', summary: '', objid: shortid.generate() }
        ]
    });

    const debounceSave = useCallback(
        debounce((nextvalue) => setCustoms(nextvalue), 300)
        , []
    );


    const debounceSaveDescription = useCallback(
        debounce((index, nextvalue) => setContext(index, nextvalue), 300)
        , []
    );

    const setContext = (index, nextvalue) => {
        if (customs.customs[index] && customs.customs[index].summary !== undefined) {
            customs.customs[index].summary = nextvalue;
            setResumeStatus({ ...resume, ...customs });
        }

    }


    useEffect(() => {
        if (resume.customs && resume.customs.length > 0) {
            let i = 0
            i = resume.customs.length;
            if (i > 3) {
                setExpand([0]);
            }
            setCustoms({ customs: resume.customs });
            form.setFieldsValue(customs);

        } else {
            form.setFieldsValue(customs);
        }


        const timer = setTimeout(() => {
            setLoading(false);
        }, 300);

        setLoadings(false)

        return () => clearTimeout(timer);

    }, []);


    useEffect(() => {
        setLoadings(true)
        const items = customs.customs.filter(item => {
            if (item.sectionTitle !== undefined)
                return item;
        })
        customs['customs'] = items;

        form.setFieldsValue(customs)
        setResumeStatus({ ...resume, ...customs });


        const timer = setTimeout(() => {
            setLoadings(false)
        }, 100);

    }, [customs]);



    const onValuesChange = (val, all) => {

        setLoadings(true)
        onFinish();

        console.log(customs.customs.length, all.customs.length)
        if (customs.customs.length != all.customs.length) {

            setCustoms(all);
            if (!expand.includes(all.customs.length)) {
                expand.push(all.customs.length)
            }
            if (!expand.includes(customs.customs.length)) {
                expand.push(customs.customs.length)
            }

        } else {
            debounceSave(all);
        }



    }

    const onDragEnd = (result) => {

        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const items = reorder(
            customs.customs,
            result.source.index,
            result.destination.index
        );

        customs['customs'] = items;
        setCustoms({ ...customs })

    }

    const reorder = (list, startIndex, endIndex) => {

        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };



    const editorOnChnage = (val, index) => {

        if (customs.customs[index].summary !== undefined) {
            debounceSaveDescription(index, val);
        }


    }

    const onFinish = values => {
        console.log('Received values of form:', values);
    };


    const genExtra = () => (
        <div>
            <DragOutlined
                className="drag-btn"
                onClick={event => {
                    // If you don't want click extra trigger collapse, you can prevent this:
                    event.stopPropagation();
                }}
            />

        </div>
    );

    return (
        <>

            <Skeleton active={true} loading={loading}>
                <Form
                    onFinish={onFinish}
                    layout={"vertical"}
                    wrapperCol={{
                        span: 24
                    }}
                    size={"middle"}
                    form={form}
                    name="header"
                    onValuesChange={onValuesChange}
                >
                    <div className="spin-loader" align="center">
                        {loadings && <Spin size="small" />}
                    </div>

                    <Row className="ant-mt-6" >
                        <Col xl={24} lg={24} md={24} sm={24} xs={24} align="left" >

                            <Form.List name="customs">
                                {(fields, { add, remove }) => (
                                    <>

                                        <DragDropContext onDragEnd={onDragEnd}>

                                            <Droppable droppableId="item" >
                                                {
                                                    (provided) => (
                                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                                            {fields.map((field, index, arr) => (

                                                                <>
                                                                    <Draggable key={customs.customs[index] ? customs.customs[index].objid : 'temp'} draggableId={customs.customs[index] ? customs.customs[index].objid : 'temp'} index={index}>

                                                                        {provided => (
                                                                            <div ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}>


                                                                                <Collapse defaultActiveKey={expand}
                                                                                    className="form-panel"
                                                                                    expandIconPosition={"right"}
                                                                                >
                                                                                    <Panel

                                                                                        header={(customs.customs[index].sectionTitle ? customs.customs[index].sectionTitle : 'Custom Section')} key={index} extra={
                                                                                            <div className="section-top-btn">


                                                                                                <DragOutlined
                                                                                                    className="drag-btn ant-ml-2 ant-mr-1"

                                                                                                    onClick={event => {
                                                                                                        event.stopPropagation();
                                                                                                    }}
                                                                                                />
                                                                                                <div>
                                                                                                    {loadings === false && <DeleteTwoTone
                                                                                                        className="remove-btn ant-mr-1"
                                                                                                        twoToneColor="#f5222d"

                                                                                                        onClick={() => remove(field.name)} />}
                                                                                                </div>
                                                                                                {/* <div className="ant-mr-5 ant-mt-1 spin-loader"> {loadings && <Spin size="small" />}</div> */}
                                                                                            </div>


                                                                                        }


                                                                                        className="ant-mb-5 ">
                                                                                        <div >
                                                                                            {
                                                                                                <div key={field.key} >

                                                                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                                                                        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gutter-row">
                                                                                                            <Form.Item
                                                                                                                {...field}
                                                                                                                label="Custom Section Title"
                                                                                                                name={[field.name, 'sectionTitle']}
                                                                                                                fieldKey={[field.fieldKey, 'sectionTitle']}
                                                                                                                rules={[{ type: "string",  message: 'Custom Section Title ' },
                                                                                                                { max: 100, message: 'Custom Section Title  must be maximum 100 characters.' }]}
                                                                                                            >
                                                                                                                <Input placeholder="Internships" />
                                                                                                            </Form.Item>

                                                                                                        </Col>

                                                                                                    </Row>



                                                                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>

                                                                                                        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gutter-row" align="left">
                                                                                                            {
                                                                                                                <Form.Item
                                                                                                                    {...field}
                                                                                                                    label="Description"
                                                                                                                    name={[field.name, 'summary']}
                                                                                                                    fieldKey={[field.fieldKey, 'summary']}
                    
                                                                                                                >

                                                                                                                    <ReactQuill
                                                                                                                        theme="snow"
                                                                                                                        value={customs.customs[index].summary}
                                                                                                                        onChange={(newValue, delta, source) => {

                                                                                                                            editorOnChnage(newValue, index)

                                                                                                                        }}
                                                                                                                        modules={{
                                                                                                                            toolbar: [
                                                                                                                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                                                                                                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                                                                                                                { 'indent': '-1' }, { 'indent': '+1' }],
                                                                                                                                ['link'],
                                                                                                                                ['clean']
                                                                                                                            ]
                                                                                                                        }}
                                                                                                                        placeholder={"Description"}
                                                                                                                    >

                                                                                                                    </ReactQuill>

                                                                                                                </Form.Item>
                                                                                                            }
                                                                                                        </Col>
                                                                                                    </Row>


                                                                                                </div>

                                                                                            }
                                                                                        </div>
                                                                                    </Panel>
                                                                                </Collapse>
                                                                            </div>

                                                                        )}
                                                                    </Draggable>

                                                                </>


                                                            ))}
                                                            <div className="spin-loader" align="center">
                                                                {loadings && <Spin size="small" />}
                                                            </div>
                                                            {provided.placeholder}
                                                        </div>
                                                    )
                                                }
                                            </Droppable>
                                            <Form.Item className="add-btn-align add-btn">
                                                <Button type="primary" loading={loadings} onClick={() => add({ sectionTitle: '', summary: '', objid: shortid.generate() })} icon={<PlusOutlined />}>
                                                    Add Custom Section
                                                </Button>
                                            </Form.Item>
                                        </DragDropContext>


                                    </>
                                )}
                            </Form.List>
                        </Col>
                    </Row>

                </Form>
            </Skeleton>

        </>
    );
}

export default CustomsForm;