import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Row, Col, Form, Input, Button, Checkbox, Space, AutoComplete, DatePicker, Skeleton, Collapse, Switch, Spin } from 'antd';
import { DeleteTwoTone, DragOutlined, PlusOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import shortid from 'shortid';
import moment from 'moment';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import debounce from 'lodash.debounce';

import { ResumeContext } from '../../../contexts/ResumeContext';


const { Panel } = Collapse;

const EducationForm = () => {

    const [value, setValue] = useState('');
    const { resume, setResumeStatus } = useContext(ResumeContext);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [loadings, setLoadings] = useState(false);
    const [expand, setExpand] = useState([0, 1]);
    const [count, setCount] = useState(0);


    //educations
    const [educations, setEducations] = useState({
        educations: [
            { institute: '', qualification: '', field: "", website: '', city: '', startDate: null, endDate: null, stillStudying: false, summary: '', objid: shortid.generate() }
        ]
    });

    const debounceSave = useCallback(
        debounce((nextvalue) => setEducations(nextvalue), 600)
        , []
    );


    const debounceSaveDescription = useCallback(
        debounce((index, nextvalue) => setContext(index, nextvalue), 600)
        , []
    );

    const setContext = (index, nextvalue) => {
        if (educations.educations[index] && educations.educations[index].summary !== undefined) {
            educations.educations[index].summary = nextvalue;
            setResumeStatus({ ...resume, ...educations });
        }

    }


    useEffect(() => {
        if (resume.educations && resume.educations.length > 0) {
            let i = 0
            resume.educations.forEach(row => {
                if (row.startDate != null) {
                    row.startDate = moment(row.startDate);
                }

                if (row.endDate != null) {
                    row.endDate = moment(row.endDate);
                }
                if (!expand.includes(i)) {
                    expand.push(i)
                }
                i++;
            });
            if (i > 3) {
                setExpand([0]);
            }
            setEducations({ educations: resume.educations });
            form.setFieldsValue(educations);

        } else {
            form.setFieldsValue(educations);
        }

        setCount(1);
        const timer = setTimeout(() => {
            setLoading(false);
        }, 100);

        setLoadings(false)

        return () => clearTimeout(timer);

    }, []);

    useEffect(() => {
        setLoadings(true)
        if (count == 1) {
            if (educations.educations && educations.educations.length > 0) {
                const items = educations.educations.filter(item => {
                    if (item.qualification !== undefined && item.institute !== undefined)
                        return item;
                })
                educations['educations'] = items;
            }

            form.setFieldsValue(educations)
            setResumeStatus({ ...resume, ...educations });

        }
        setLoadings(false)

    }, [educations]);

    const onValuesChange = (val, all) => {

        setLoadings(true)
        onFinish();

        if (educations.educations && all.educations) {
            if (educations.educations.length != all.educations.length) {

                setEducations(all);

                if (!expand.includes(all.educations.length)) {
                    expand.push(all.educations.length)
                }
                if (!expand.includes(educations.educations.length)) {
                    expand.push(educations.educations.length)
                }
            } else {
                debounceSave(all);
            }

        } else {
            debounceSave(all);
        }



    }

    const onDragEnd = (result) => {

        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const items = reorder(
            educations.educations,
            result.source.index,
            result.destination.index
        );

        educations['educations'] = items;
        setEducations({ ...educations })

    }

    const reorder = (list, startIndex, endIndex) => {

        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const editorOnChnage = (val, index) => {

        if (educations.educations[index].summary !== undefined) {
            debounceSaveDescription(index, val);
        }


    }

    const onFinish = values => {

    };


    const genExtra = () => (
        <div>
            <DragOutlined
                className="drag-btn"
                onClick={event => {
                    // If you don't want click extra trigger collapse, you can prevent this:
                    event.stopPropagation();
                }}
            />

        </div>
    );

    return (
        <>

            <Skeleton active={true} loading={loading}>
                <Form
                    onFinish={onFinish}
                    layout={"vertical"}
                    wrapperCol={{
                        span: 24
                    }}
                    size={"middle"}
                    form={form}
                    name="header"
                    onValuesChange={onValuesChange}
                >
                    <div className="spin-loader" align="center">
                        {loadings && <Spin size="small" />}
                    </div>

                    <Row className="ant-mt-2">
                        <Col xl={24} lg={24} md={24} sm={24} xs={24} align="left">

                            <Form.List name="educations">
                                {(fields, { add, remove }) => (
                                    <>

                                        <DragDropContext onDragEnd={onDragEnd}>

                                            <Droppable droppableId="item" >
                                                {
                                                    (provided) => (
                                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                                            {fields.map((field, index, arr) => (

                                                                <>

                                                                    <Draggable key={educations.educations[index] ? educations.educations[index].objid : 'temp'} draggableId={educations.educations[index] ? educations.educations[index].objid : 'temp'} index={index}>

                                                                        {provided => (
                                                                            <div ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}>


                                                                                <Collapse defaultActiveKey={expand}
                                                                                    className="form-panel"
                                                                                    expandIconPosition={"right"}
                                                                                    bordered={false}
                                                                                >
                                                                                    <Panel
                                                                                        className="inner-panel ant-mb-5"
                                                                                        header={(educations.educations[index].qualification ? educations.educations[index].qualification + ' - ' : '') + (educations.educations[index].field ? educations.educations[index].field : '')}
                                                                                         key={index} extra={
                                                                                            <div className="section-top-btn">


                                                                                                <DragOutlined
                                                                                                    className="drag-btn ant-ml-2 ant-mr-1"

                                                                                                    onClick={event => {
                                                                                                        event.stopPropagation();
                                                                                                    }}
                                                                                                />
                                                                                                <div>
                                                                                                    {loadings === false && <DeleteTwoTone
                                                                                                        className="remove-btn ant-mr-1"
                                                                                                        twoToneColor="#f5222d"

                                                                                                        onClick={() => remove(field.name)} />}
                                                                                                </div>
                                                                                                <div className="ant-mr-5 spin-loader"> {loadings && <Spin size="small" />}</div>
                                                                                            </div>


                                                                                        }


                                                                                        >
                                                                                        <div className="ant-mt-4" >
                                                                                            {
                                                                                                <div key={field.key} >

                                                                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                                                                        <Col xl={16} lg={16} md={16} sm={24} xs={24} className="gutter-row">
                                                                                                            <Form.Item
                                                                                                                {...field}
                                                                                                                label="Field of study"
                                                                                                                name={[field.name, 'field']}
                                                                                                                fieldKey={[field.fieldKey, 'field']}
                                                                                                                rules={[{ type: "string", message: 'Field of Study Required' },
                                                                                                                { max: 100, message: 'Field of study must be maximum 100 characters.' }
                                                                                                                ]}
                                                                                                            >
                                                                                                                <Input placeholder="Information Technology" />
                                                                                                            </Form.Item>


                                                                                                        </Col>
                                                                                                        <Col xl={8} lg={8} md={8} sm={24} xs={24} className="gutter-row" >
                                                                                                            <Form.Item
                                                                                                                {...field}
                                                                                                                label="Qualification"
                                                                                                                name={[field.name, 'qualification']}
                                                                                                                fieldKey={[field.fieldKey, 'qualification']}
                                                                                                                rules={[{ type: "string", required: true, message: 'Qualification  Required' },
                                                                                                                { max: 100, message: 'Qualification must be maximum 100 characters.' }
                                                                                                                ]}
                                                                                                            >
                                                                                                                <AutoComplete
                                                                                                                    dataSource={[
                                                                                                                        "High School Diploma",
                                                                                                                        "GED",
                                                                                                                        "Associate of Arts",
                                                                                                                        "Associate of Science",
                                                                                                                        "Associate of Applied Science",
                                                                                                                        "Bachelor of Arts",
                                                                                                                        "Bachelor of Science",
                                                                                                                        "BBA",
                                                                                                                        "Master of Arts",
                                                                                                                        "Master of Science",
                                                                                                                        "MBA",
                                                                                                                        "J.D",
                                                                                                                        "M.D",
                                                                                                                        "Ph.D",
                                                                                                                    ]}
                                                                                                                    placeholder="Bachelor's Degree"
                                                                                                                />

                                                                                                            </Form.Item>

                                                                                                        </Col>
                                                                                                    </Row>

                                                                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                                                                        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gutter-row" >
                                                                                                            <Form.Item
                                                                                                                {...field}
                                                                                                                label="Institute"
                                                                                                                name={[field.name, 'institute']}
                                                                                                                fieldKey={[field.fieldKey, 'institute']}
                                                                                                                rules={[{ type: "string", message: 'Institute' },
                                                                                                                { max: 100, message: 'Institute must be maximum 100 characters.' }

                                                                                                                ]}
                                                                                                            >
                                                                                                                <Input placeholder="Stanford University" />
                                                                                                            </Form.Item>
                                                                                                        </Col>
                                                                                                        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gutter-row" >
                                                                                                            <Form.Item
                                                                                                                {...field}
                                                                                                                label="Institute Website (url)"
                                                                                                                name={[field.name, 'website']}
                                                                                                                fieldKey={[field.fieldKey, 'website']}
                                                                                                                rules={[{ type: "string", message: 'Institute Website' },
                                                                                                                { max: 100, message: 'Institute Website must be maximum 100 characters.' }
                                                                                                                ]}
                                                                                                            >
                                                                                                                <Input placeholder="Institute Website" />
                                                                                                            </Form.Item>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>

                                                                                                        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gutter-row" >
                                                                                                            <Form.Item
                                                                                                                {...field}
                                                                                                                label="City"
                                                                                                                name={[field.name, 'city']}
                                                                                                                fieldKey={[field.fieldKey, 'city']}
                                                                                                                rules={[{ type: "string" },
                                                                                                                { max: 100, message: 'City must be maximum 100 characters.' }
                                                                                                                ]}
                                                                                                            >
                                                                                                                <Input placeholder="New York" />
                                                                                                            </Form.Item>
                                                                                                        </Col>
                                                                                                    </Row>

                                                                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                                                                        <Col xl={8} lg={8} md={8} sm={24} xs={24} className="gutter-row" align="left">
                                                                                                            <Form.Item
                                                                                                                {...field}
                                                                                                                label="Start Date"
                                                                                                                name={[field.name, 'startDate']}
                                                                                                                fieldKey={[field.fieldKey, 'startDate']}
                                                                                                                rules={[{ type: "object", message: 'Start Date' }

                                                                                                                ]}
                                                                                                            >
                                                                                                                <DatePicker picker="month" format={'MMM-YYYY'} />
                                                                                                            </Form.Item>

                                                                                                        </Col>
                                                                                                        {educations.educations[index].stillStudying != true &&
                                                                                                            <Col xl={8} lg={8} md={8} sm={24} xs={24} className="gutter-row" align="left">
                                                                                                                <Form.Item
                                                                                                                    {...field}
                                                                                                                    label="End Date"
                                                                                                                    name={[field.name, 'endDate']}
                                                                                                                    fieldKey={[field.fieldKey, 'endDate']}
                                                                                                                    rules={[{ type: "object", message: 'End Date' }]}
                                                                                                                >
                                                                                                                    <DatePicker picker="month" format={'MMM-YYYY'} />
                                                                                                                </Form.Item>
                                                                                                            </Col>
                                                                                                        }

                                                                                                        <Col xl={8} lg={8} md={8} sm={24} xs={24} className="gutter-row" align="left">
                                                                                                            <Form.Item
                                                                                                                {...field}
                                                                                                                label="Currently Studying"
                                                                                                                name={[field.name, 'stillStudying']}
                                                                                                                fieldKey={[field.fieldKey, 'stillStudying']}
                                                                                                                rules={[{ type: "boolean", message: 'Currently Working' }]}
                                                                                                            >
                                                                                                                <Switch checked={educations.educations[index].stillStudying} />
                                                                                                            </Form.Item>
                                                                                                        </Col>
                                                                                                    </Row>


                                                                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>

                                                                                                        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gutter-row" align="left">
                                                                                                            {
                                                                                                                <Form.Item
                                                                                                                    {...field}
                                                                                                                    label="Description"
                                                                                                                    name={[field.name, 'summary']}
                                                                                                                    fieldKey={[field.fieldKey, 'summary']}

                                                                                                                >

                                                                                                                    <ReactQuill
                                                                                                                        theme="snow"
                                                                                                                        value={educations.educations[index].summary}
                                                                                                                        onChange={(newValue, delta, source) => {

                                                                                                                            editorOnChnage(newValue, index)

                                                                                                                        }}
                                                                                                                        modules={{
                                                                                                                            toolbar: [
                                                                                                                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                                                                                                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                                                                                                                { 'indent': '-1' }, { 'indent': '+1' }],
                                                                                                                                ['link'],
                                                                                                                                ['clean']
                                                                                                                            ]
                                                                                                                        }}
                                                                                                                        placeholder={"Description"}
                                                                                                                    >

                                                                                                                    </ReactQuill>

                                                                                                                </Form.Item>
                                                                                                            }
                                                                                                        </Col>
                                                                                                    </Row>


                                                                                                </div>

                                                                                            }
                                                                                        </div>
                                                                                    </Panel>
                                                                                </Collapse>
                                                                            </div>

                                                                        )}
                                                                    </Draggable>

                                                                </>


                                                            ))}
                                                            <div className="spin-loader" align="center">
                                                                {loadings && <Spin size="small" />}
                                                            </div>
                                                            {provided.placeholder}
                                                        </div>
                                                    )
                                                }
                                            </Droppable>
                                            <Form.Item className="add-btn add-btn-align">
                                                <Button type="primary" loading={loadings} onClick={() => add({ institute: '', qualification: '', field: "", website: '', city: '', startDate: null, endDate: null, stillStudying: false, summary: '', objid: shortid.generate() })} icon={<PlusOutlined />}>
                                                    Add Education
                                                </Button>
                                            </Form.Item>
                                        </DragDropContext>


                                    </>
                                )}
                            </Form.List>
                        </Col>
                    </Row>


                    <Row>
                        {/* <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Experience
                            </Button>
                            </Form.Item> */}
                    </Row>

                </Form>
            </Skeleton>

        </>
    );
}

export default EducationForm;