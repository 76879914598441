import { Button, Col, Image, Row, Typography } from 'antd';
import React from 'react';
import { useHistory } from "react-router-dom";
import MainNav from '../navigation/MainNav';
const { Text, Title } = Typography;

const Dashboard = () => {

    const history = useHistory();

    const ResumeBuilder = () => {
        history.push(`https://builder.resumoz.com/`);
    }

    const CoverLetterBuilder = () => {
        history.push(`/cover-letter/experience`);
    }


    return (
        <div className="dashboard">
            <MainNav />
            <Row gutter={[24, 0]} className="margin-top">
                <Col xl={12} lg={24} md={24} sm={24} xs={24} align="middle" className="ant-mb-7">
                    <Image
                        width={"35%"}
                        src="/assets/img/app/resume-dash.png"
                        className="dash-img"
                        onClick={()=> window.open("https://builder.resumoz.com/")}
                    />
                    <Title level={3} className="text-primary-rez ant-mb-1 dashboard-main">Build your resume with simple steps in minutes</Title>
                    <Text type="secondary">Make sure your content is ready and get started</Text>

                    <div className="ant-mt-8">

                        <Button className="resume-btn" type="primary" onClick={()=> window.open("https://builder.resumoz.com/")} >Resume Builder</Button>

                    </div>

                    {/* <div className="ant-mt-7 list">
                        <Card className="ant-p-2 ant-m-3  card ">
                            <div className="card-body">
                                <div>
                                    <Text strong className="heading">Resume 1</Text>
                                </div>
                                <div className="icons">
                                    <DeleteTwoTone className="ant-mr-1" twoToneColor="#f5222d" />
                                    <EyeTwoTone className="ant-mr-1 ant-ml-1" twoToneColor="#1890ff" />
                                    <EditTwoTone className="ant-mr-1 ant-ml-1" twoToneColor="#52c41a" />
                                    <DownloadOutlined className="ant-ml-1" />
                                </div>
                            </div>
                        </Card>
                        <Card className="ant-p-2 ant-m-3  card ">
                            <div className="card-body">
                                <div>
                                    <Text strong className="heading">Resume 2</Text>
                                </div>
                                <div className="icons">
                                    <DeleteTwoTone className="ant-mr-1" twoToneColor="#f5222d" />
                                    <EyeTwoTone className="ant-mr-1 ant-ml-1" twoToneColor="#1890ff" />
                                    <EditTwoTone className="ant-mr-1 ant-ml-1" twoToneColor="#52c41a" />
                                    <DownloadOutlined className="ant-ml-1" />
                                </div>
                            </div>
                        </Card>
                        
                        <div className="expand-btn">
                            <Text className="ant-mr-1 ant-pb-1">more</Text>
                            <PlusSquareTwoTone twoToneColor="#1890ff" />
                        </div>
                    </div> */}

                </Col>
                <Col xl={12} lg={24} md={24} sm={24} xs={24} align="middle" className="main-col-right" >
                    <Image
                        width={"35%"}
                        src="/assets/img/app/cover-dash.png"
                        className="dash-img"
                        onClick={CoverLetterBuilder}
                    />
                    <Title level={3} className="text-second-rez ant-mb-1 dashboard-main">Generate a tailored cover letter in 5 easy steps</Title>
                    <Text type="secondary">Answer a few simple questions and get a personalized cover letter</Text>

                    <div className="ant-mt-8">
                        <Button className="cover-letter-btn" onClick={CoverLetterBuilder}>Cover Letter Builder</Button>
                    </div>

                    {/* <div className="ant-mt-7 list">
                        <Card className="ant-p-2 ant-m-3  card ">
                            <div className="card-body">
                                <div>
                                    <Text strong className="heading">Cover Letter 1</Text>
                                </div>
                                <div className="icons">
                                    <DeleteTwoTone className="ant-mr-1" twoToneColor="#f5222d" />
                                    <EyeTwoTone className="ant-mr-1 ant-ml-1" twoToneColor="#1890ff" />
                                    <EditTwoTone className="ant-mr-1 ant-ml-1" twoToneColor="#52c41a" />
                                    <DownloadOutlined className="ant-ml-1" />
                                </div>
                            </div>
                        </Card>
                        <Card className="ant-p-2 ant-m-3  card ">
                            <div className="card-body">
                                <div>
                                    <Text strong className="heading">Cover Letter 2</Text>
                                </div>
                                <div className="icons">
                                    <DeleteTwoTone className="ant-mr-1" twoToneColor="#f5222d" />
                                    <EyeTwoTone className="ant-mr-1 ant-ml-1" twoToneColor="#1890ff" />
                                    <EditTwoTone className="ant-mr-1 ant-ml-1" twoToneColor="#52c41a" />
                                    <DownloadOutlined className="ant-ml-1" />
                                </div>
                            </div>
                        </Card>
                     
                        <div className="expand-btn">
                            <Text className="ant-mr-1 ant-pb-1">more</Text>
                            <PlusSquareTwoTone twoToneColor="#1890ff" />
                        </div>
                    </div> */}

                </Col>
            </Row>
        </div>
    )
}

export default Dashboard; 