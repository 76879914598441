import React from 'react'
// import { ToolTip } from '../../../tooltip/ToolTip';
import { Row, Col, Typography } from 'antd'
const { Text, Title } = Typography;


export const HeaderTopic = () => {
    return (
        <div className="form-headers"> 
            <Row>
                <Col span={24} className="main-title" align="center">
                    <Title level={2} className="ant-mb-1">What's your contact information?</Title>
                </Col>
                <Col span={24} className="description">
                    <Text className="ant-mr-1">Recruiters need this information to get in touch with you</Text>
                    {/* <ToolTip 
                    title={"Header Tips"}
                    tip1={"tilsdfhb afiahg fsfweff"}
                    tip2={"tilsdfhb afiahgf fwefwef"}
                    /> */}
                </Col>
            </Row>

        </div>
    )
}

export const ExperienceTopic = () => {
    return (
        <div className="form-headers">
            <Row>
                <Col span={24} className="main-title" align="center">
                    <Title level={2} className="ant-mb-1">Do you have any work experience?</Title>
                </Col>
                <Col span={24} className="description">
                    <Text className="ant-mr-1">Start with your most recent work experience</Text>
                    {/* <ToolTip 
                    title={"Header Tips"}
                    tip1={"tilsdfhb afiahg fsfweff"}
                    tip2={"tilsdfhb afiahgf fwefwef"}
                    /> */}
                </Col>
            </Row>

        </div>
    )
}


export const EducationTopic = () => {
    return (
        <div className="form-headers">
            <Row>
                <Col span={24} className="main-title" align="center">
                    <Title level={2} className="ant-mb-1">Tell us about your education</Title>
                </Col>
                <Col span={24} className="description">
                    <Text className="ant-mr-1">Start with your highest and most recent educational qualification</Text>
                    {/* <ToolTip 
                    title={"Header Tips"}
                    tip1={"tilsdfhb afiahg fsfweff"}
                    tip2={"tilsdfhb afiahgf fwefwef"}
                    /> */}
                </Col>
            </Row>

        </div>
    )
}

export const SkillsTopic = () => {
    return (
        <div className="form-headers">
            <Row>
                <Col span={24} className="main-title" align="center">
                    <Title level={2} className="ant-mb-1">Do you have relevant skills?</Title>
                </Col>
                <Col span={24} className="description">
                    <Text className="ant-mr-1">Add both relevant soft skills and hard skills</Text>
                    {/* <ToolTip 
                    title={"Header Tips"}
                    tip1={"tilsdfhb afiahg fsfweff"}
                    tip2={"tilsdfhb afiahgf fwefwef"}
                    /> */}
                </Col>
            </Row>

        </div>
    )
}

export const SummaryTopic = () => {
    return (
        <div className="form-headers">
            <Row>
                <Col span={24} className="main-title" align="center">
                    <Title level={2} className="ant-mb-1">Tell us about your greatest achievements</Title>
                </Col>
                <Col span={24} className="description">
                    <Text className="ant-mr-1">Write your career summary with 3-5 sentences</Text>
                    {/* <ToolTip 
                    title={"Header Tips"}
                    tip1={"tilsdfhb afiahg fsfweff"}
                    tip2={"tilsdfhb afiahgf fwefwef"}
                    /> */}
                </Col>
            </Row>

        </div>
    )
}